import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse/page',
    method: 'post',
    data,
  })
}

export function add(data) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse',
    method: 'put',
    data,
  })
}

export function update(data) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse/update',
    method: 'post',
    data,
  })
}

export function detail(params) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse/itemList',
    method: 'get',
    params,
  })
}

export function del(data) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse',
    method: 'delete',
    data,
  })
}

export function updateStatus(data) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse/status',
    method: 'put',
    data,
  })
}

export function physicalWarehouseList(data) {
  return request({
    url: '/mg/daohang-stock-api/centralWarehouse/list',
    method: 'post',
    data,
  })
}
