import request from '@/utils/request'

// 客户分页列表
export function getCustomerList(params) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/page',
    method: 'get',
    params,
  })
}

// 客户分页列表POST
export function getCustomerPage(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/page2',
    method: 'post',
    data,
  })
}
// 客户分页列表POST
export function exportCustomerPage(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 客户保存前置校验
export function customerInfoValidate(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/customerInfoValidate',
    method: 'post',
    data,
  })
}

// 客户保存
export function saveCustomerInfo(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo',
    method: 'put',
    data,
  })
}

// 客户启用-禁用
export function customerChangeStatus(data) {
  return request({
    url: `/mg/daohang-basic-api/jcCustomerInfo/status`,
    method: 'put',
    data,
  })
}

// 客户查看
export function getCustomerDetail(id) {
  return request({
    url: `/mg/daohang-basic-api/jcCustomerInfo/itemList/${id}`,
    method: 'get',
  })
}
// 地区获取
export function getAreaList() {
  return request({
    url: `/mg/daohang-basic-api/dhComponentGeo/list/all`,
    method: 'get',
  })
}

// 获取客户 list 不分页
export function getCustomerListAll(params) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/list',
    method: 'get',
    params,
  })
}
// 获取批发价格政策 list 不分页
export function getSkuPricePolicyPage(data) {
  return request({
    url: `/mg/daohang-promotion-api/skuPricePolicy/list`,
    method: 'post',
    data,
  })
}

// 获取登陆人所属往来单位+上级所属往来单位+ 晨光总部
export function getOnlinePartner() {
  return request({
    url: `/mg/daohang-basic-api/onlinePartner`,
    method: 'get',
  })
}

/**
 * 客户综合应收查询
 */
export function customerReceivables(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/listAccount',
    method: 'post',
    data,
  })
}

/**
 * 客户信息查询（轻量级）
 * @param {*} data
 */
export function customerInfoLight(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/quickSearch',
    method: 'post',
    data,
  })
}

/**
 * 客户信息查询（全量）
 * @param {*} data
 * @returns
 */
export function customerInfoFull(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/listByQuery',
    method: 'post',
    data,
  })
}

export function getAccountClearPlan() {
  return request({
    url: '/mg/daohang-pay-api/accountClearPlan/list',
    method: 'get',
  })
}
// 清理账款
export function accountCleanPlan(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/accountCleanPlan',
    method: 'put',
    data,
  })
}
// 校验编码唯一性
export function validateUnique(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/validateUnique',
    method: 'post',
    data,
  })
}
