
import Big, { BigSource, RoundingMode } from 'big.js'

export interface BigNumberImp {
  /**
   * 高精度加法
   * @param nums 支持多个数相加
   * @return `Big` 对象，调用 `toNumber()` 方法转成数字 
   */
  plus(...nums: BigSource[]): Big;
  /**
   * 高精度减法
   * @param nums 支持多个数相减
   * @return `Big` 对象，调用 `toNumber()` 方法转成数字 
   */
  minus(...nums: BigSource[]): Big;
  /**
   * 高精度乘法
   * @param nums 支持多个数相乘
   * @return `Big` 对象，调用 `toNumber()` 方法转成数字 
   */
  times(...nums: BigSource[]): Big;
  /**
   * 高精度除法
   * @param nums 支持多个数相除
   * @return `Big` 对象，调用 `toNumber()` 方法转成数字 
   */
  div(...nums: BigSource[]): Big;
  /**
   * 保留小数位
   * @param value 数值
   * @param dp 小数位长度
   * @param rm 四舍五入模式 0 (down), 1 (half-up), 2 (half-even) or 3 (up)
   */
  toFixed(value: BigSource, dp?: number | undefined, rm?: RoundingMode | undefined): string
}

class BigNumber implements BigNumberImp {

  constructor() {}

  private iteratorOperation(arr: BigSource[], operation: (...args: BigSource[]) => Big) {
    const [num1, num2, ...others] = arr;
    let res = operation(num1, num2);
    others.forEach((num) => {
      res = operation(res, num);
    });
    return res;
  }

  plus(...nums: BigSource[]): Big {
    if (nums.length > 2) {
      return this.iteratorOperation(nums, this.plus);
    }
    const [num1, num2] = nums;
    return Big(num1).plus(Big(num2 || 0))
  }
  
  minus(...nums: BigSource[]): Big {
    if (nums.length > 2) {
      return this.iteratorOperation(nums, this.minus);
    }
    const [num1, num2] = nums;
    return Big(num1).minus(Big(num2|| 0))
  }

  times(...nums: BigSource[]): Big {
    if (nums.length > 2) {
      return this.iteratorOperation(nums, this.times);
    }
    const [num1, num2] = nums;
    return Big(num1).times(Big(num2))
  }

  div(...nums: BigSource[]): Big {
    if (nums.length > 2) {
      return this.iteratorOperation(nums, this.div);
    }
    const [num1, num2] = nums;
    return Big(num1).div(Big(num2))
  }

  toFixed(value: BigSource, dp?: number | undefined, rm?: RoundingMode | undefined): string {
    return Big(value).toFixed(dp, rm)
  }
}
const BN = new BigNumber()
export default BN