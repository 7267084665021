<template>
  <el-drawer :title="$t(`快捷键`)" direction="rtl" :visible.sync="visible">
    <ul class="p-10">
      <li class="flex items-center justify-between mb-10" v-for="item in KeyboardActions" :key="item.value">
        <el-tag>{{ item.value }}</el-tag>
        <div>{{ item.label }}</div>
      </li>
    </ul>
  </el-drawer>
</template>
<script>
  import { GlobalEventType } from '@/constants'
  import { KeyboardActions } from '@/plugins/keyboard'
  export default {
    name: 'KeyboardShortcuts',
    data() {
      return {
        visible: false,
        KeyboardActions,
      }
    },
    created() {
      this.$root.$on(GlobalEventType.KEYBOARD_SHORTCUTS, () => {
        this.visible = true
      })
    },
  }
</script>
