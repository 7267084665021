/**
 * 移库单相关打印变量
 */
const warehouseTransferOrderTempOptions = [
  {
    label: '移库单',
    value: 'all',
    children: [{ label: '移库单(调入单)', value: 1 }],
  },
]
const warehouseTransferOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 280,
          width: 210,
          paperHeader: 43.5,
          paperFooter: 777,
          printElements: [
            {
              options: {
                left: 229.5,
                top: 15,
                height: 17,
                width: 154.5,
                right: 385.5,
                title: '商品移库单(调入单)',
                bottom: 32,
                hCenter: 23.5,
                vCenter: 308.25,
                fontSize: 16.5,
                testData: '',
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
              },
              printElementType: {
                title: '标题',
                type: 'text',
              },
            },
            {
              options: {
                left: 375,
                top: 31.5,
                height: 9.75,
                width: 136.5,
                field: 'printTime',
                right: 510.75,
                title: '打印日期',
                bottom: 41.25,
                hCenter: 36.375,
                vCenter: 442.5,
                testData: '2023-04-20 23:59:59',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 15,
                top: 54,
                height: 9.75,
                width: 280.5,
                field: 'billNo',
                right: 166.5,
                title: '单据号',
                bottom: 63,
                hCenter: 58.125,
                vCenter: 90,
                testData: 'YC0C0000230418000003',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 312,
                top: 69,
                height: 9.75,
                width: 270,
                field: 'inWarehouseName',
                right: 430.5,
                title: '调入组织',
                bottom: 93,
                hCenter: 88.125,
                vCenter: 370.5,
                testData: 'C11001-义乌配送中心',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 15,
                top: 72,
                height: 9.75,
                width: 285,
                field: 'outWarehouseName',
                right: 177,
                title: '调出组织',
                bottom: 81.75,
                hCenter: 76.875,
                vCenter: 117,
                testData: 'C1105-中转仓',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 87,
                height: 60,
                width: 567,
                field: 'printLists',
                right: 563.5,
                bottom: 138,
                hCenter: 120,
                vCenter: 288.5,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                columns: [
                  [
                    {
                      width: 90.832525,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 254.7192249999999,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 66.63550000000008,
                      title: '包装描述',
                      field: 'packageConversion',
                      checked: true,
                      columnId: 'packageConversion',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 53.56224999999981,
                      title: '含税进价',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 65.57050000000012,
                      title: '数量',
                      field: 'transferQty',
                      checked: true,
                      columnId: 'transferQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: 'sum',
                    },
                    {
                      width: 68.68,
                      title: '含税成本',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: 'sum',
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 267,
                top: 174,
                height: 9.75,
                width: 120,
                right: 386.25,
                title: '移交:',
                bottom: 183,
                hCenter: 178.125,
                vCenter: 326.25,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 427.5,
                top: 174,
                height: 9.75,
                width: 120,
                right: 544.5,
                title: '接收:',
                bottom: 165,
                hCenter: 160.125,
                vCenter: 484.5,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 16.5,
                top: 174,
                height: 9.75,
                width: 238.5,
                field: 'createUserName',
                right: 134.25,
                title: '制单',
                bottom: 165,
                hCenter: 160.125,
                vCenter: 74.25,
                testData: '凯哥',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
          ],
          paperNumberLeft: 522,
          paperNumberTop: 31.5,
          paperNumberFormat: '第${paperNo}页/共${paperCount}页',
        },
      ],
    },
  },
}

export default {
  // 移库单 -- 不同配送中心打印模板名
  warehouseTransferOrderTempOptions,
  // 移库单 -- 不同分公司打印模板名json
  warehouseTransferOrderTempJsonOptions,
}
