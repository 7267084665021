import Vue from 'vue'
import Keyboard from './keyboard'
import Global from './global'
import Directives from '@/directives'
import { storeState } from '@/mixins/store'
import { CloseToRefreshList, GlobalPermissionMixin } from '@/mixins'
Vue.use(Global)
Vue.use(Directives)
Vue.use(Keyboard)
Vue.mixin(storeState)
Vue.mixin(CloseToRefreshList)
Vue.mixin(GlobalPermissionMixin)
