import { countryTree } from '@/api/basic/address'

const state = () => ({
  worldAll: [],
})

const mutations = {
  setCountryTree(state, data) {
    state.worldAll = data
  },
}

const actions = {
  /**
   * 获取地区树状结构数据
   * */
  async getCountryTree({ commit, state }) {
    if (state.worldAll.length) {
      return { data: state.worldAll }
    }
    const { data } = await countryTree()
    commit('setCountryTree', getGeoData(data))
  },
}

const getGeoData = (data) => {
  data.forEach((item, index) => {
    data[index] = { ...item, label: item.name, value: item.code, geoId: item.geoId }
    if (item?.children && item?.children?.length) {
      getGeoData(item.children)
    }
  })
  return data
}

export default { state, mutations, actions }
