const scanFormTempOptions = [
    {
        label: '通用扫描单',
        value: 'all',
        children: [
            { label: '通用扫描单', value: 1 }
        ],
    },
]

const scanFormTempJsonOptions = {
    all: {
        1: {
            "panels": [
                {
                    "index": 0,
                    "name": 1,
                    "height": 141,
                    "width": 210,
                    "paperHeader": 65,
                    "paperFooter": 387.49821428571425,
                    "printElements": [
                        {
                            "options": {
                                "left": 227.5,
                                "top": 7.5,
                                "height": 16.5,
                                "width": 153,
                                "right": 393,
                                "title": "通用扫描单",
                                "bottom": 29,
                                "hCenter": 20.5,
                                "vCenter": 316.5,
                                "fontSize": 16.5,
                                "hideTitle": true,
                                "textAlign": "center",
                                "fontWeight": "700",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "标题",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 395,
                                "top": 42.5,
                                "height": 15,
                                "width": 189,
                                "field": "printTime",
                                "right": 583.9921875,
                                "title": "打印时间",
                                "bottom": 57.4921875,
                                "hCenter": 49.9921875,
                                "vCenter": 489.4921875,
                                "testData": "2023-04-06",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 16.5,
                                "top": 43,
                                "height": 15,
                                "width": 210,
                                "field": "billNo",
                                "right": 135,
                                "title": "单据编号",
                                "bottom": 42,
                                "hCenter": 34.5,
                                "vCenter": 75,
                                "testData": "10000000000000000",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 17.5,
                                "top": 75,
                                "height": 57,
                                "width": 565.5,
                                "field": "printLists",
                                "right": 581.25,
                                "bottom": 117.24609375,
                                "hCenter": 96.24609375,
                                "vCenter": 298.5,
                                "textAlign": "center",
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "tableFooterRepeat": "page",
                                "tableFooterCellBorder": "noBorder",
                                "columns": [
                                    [
                                        {
                                            "width": 39.55680238264303,
                                            "title": "行号",
                                            "field": "index",
                                            "checked": true,
                                            "columnId": "index",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "align": "left",
                                            "halign": "center",
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": true,
                                            "tableSummary": ""
                                        },
                                        {
                                            "width": 79.80392136370996,
                                            "title": "货号",
                                            "field": "articleNo商品编码",
                                            "checked": true,
                                            "columnId": "articleNo商品编码",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "halign": "center",
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": true,
                                            "tableSummary": ""
                                        },
                                        {
                                            "width": 126.46140671679602,
                                            "title": "商品名称",
                                            "field": "skuName",
                                            "checked": true,
                                            "columnId": "skuName",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": true,
                                            "tableSummary": ""
                                        },
                                        {
                                            "width": 97.70356435990132,
                                            "title": "商品条码",
                                            "field": "skuBarcode",
                                            "checked": true,
                                            "columnId": "skuBarcode",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": true,
                                            "tableSummary": ""
                                        },
                                        {
                                            "width": 78.56822480254505,
                                            "title": "扫描数量",
                                            "field": "scanQty",
                                            "checked": true,
                                            "columnId": "scanQty",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": false,
                                            "tableSummary": "sum",
                                            "tableSummaryNumFormat": "0"
                                        },
                                        {
                                            "width": 58.43237639243621,
                                            "title": "售价",
                                            "field": "retailPrice",
                                            "checked": true,
                                            "columnId": "retailPrice",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": 30,
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0
                                        },
                                        {
                                            "width": 84.97370398196846,
                                            "title": "售价金额",
                                            "field": "scanAmount",
                                            "checked": true,
                                            "columnId": "scanAmount",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "renderFormatter": "function(value,row,index,options){\n    if (row?.retailPrice && row?.scanQty) row['scanAmount'] = $MGBN.times(row?.retailPrice || 0, row?.scanQty || 0)?.toNumber()\n    else row['scanAmount'] = 0\n    return row?.retailPrice && row?.scanQty ? $MGBN.times(row?.retailPrice || 0, row?.scanQty || 0)?.toNumber() : 0\n}",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": false,
                                            "tableSummary": "sum"
                                        }
                                    ]
                                ]
                            },
                            "printElementType": {
                                "title": "表格",
                                "type": "table",
                                "editable": true,
                                "columnDisplayEditable": true,
                                "columnDisplayIndexEditable": true,
                                "columnTitleEditable": true,
                                "columnResizable": true,
                                "columnAlignEditable": true,
                                "isEnableEditField": true,
                                "isEnableContextMenu": true,
                                "isEnableInsertRow": true,
                                "isEnableDeleteRow": true,
                                "isEnableInsertColumn": true,
                                "isEnableDeleteColumn": true,
                                "isEnableMergeCell": true
                            }
                        },
                        {
                            "options": {
                                "left": 17.5,
                                "top": 152.5,
                                "height": 15,
                                "width": 105,
                                "right": 122.49609375,
                                "title": "录单人：",
                                "bottom": 166.9921875,
                                "hCenter": 159.4921875,
                                "vCenter": 69.99609375,
                                "testData": "张三",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 397.5,
                                "top": 152.5,
                                "height": 15,
                                "width": 184.5,
                                "field": "createUserName",
                                "right": 580.2421875,
                                "title": "创建人",
                                "bottom": 166.74609375,
                                "hCenter": 159.24609375,
                                "vCenter": 487.9921875,
                                "testData": "张三",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        }
                    ],
                    "paperNumberLeft": 553.5,
                    "paperNumberTop": 18,
                    "paperNumberContinue": true,
                    "watermarkOptions": {}
                }
            ]
        }
    }
}

export default {
    scanFormTempOptions,
    scanFormTempJsonOptions
}