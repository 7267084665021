import request from '@/utils/request'

// 收款单列表
export function getReceiptVoucherList(data) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/page',
    method: 'post',
    data,
  })
} // 收款单列表合计
export function getReceiptVoucherListSum(data) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/sum',
    method: 'post',
    data,
  })
}

// 判断是否存在草稿状态的单据
export function existsDocument(params) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/existsDocument',
    method: 'get',
    params,
  })
}
// 判断是否存在草稿状态的单据，并返回对应单据id
export function getExistsOrderReturnId(params) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/existsOrder',
    method: 'get',
    params,
  })
}

// 收款单保存、编辑
export function saveReceiptVoucher(data) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/saveOrUpdate',
    method: 'put',
    loading: true,
    data,
  })
}

// 收款单删除
export function removeReceiptVoucher(data) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder',
    method: 'delete',
    data,
  })
}

// 收款单删除子表
export function removeReceiptVoucherItem(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrderItem',
    method: 'delete',
    data,
  })
}

// 收款单审核 - 反审核 -入账
export function auditReceiptVoucher(data) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}

// 收款单查看
export function getReceiptVoucherDetail(id) {
  return request({
    url: `/mg/daohang-order-api/settlementOrder/${id}`,
    method: 'get',
  })
}

// 收款单导出
export function exportReceiptVoucherExcel(data) {
  return request({
    url: `/mg/daohang-order-api/settlementOrder/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 收款单单据明细导出
export function exportReceiptVoucherBillItemExcel(data) {
  return request({
    url: `/mg/daohang-order-api/settlementOrderBillItem/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 获取是否勾单结算
export function getWhetherToCheckTheBill(data) {
  return request({
    url: `/mg/daohang-basic-api/sysBusinessConfig/findByQuery`,
    method: 'post',
    data,
  })
}

// 支付类型查询
export function getPaymentType() {
  return request({
    url: `/mg/daohang-order-api/paymentType/list`,
    method: 'get',
  })
}

// 获取客户台账
export function getTheCustomerLedger(data) {
  return request({
    url: `/mg/daohang-order-api/customerBillSummary/list`,
    method: 'post',
    data,
  })
}
// 获取应收流水合计
export function getTheCustomerTotal(data) {
  return request({
    url: `/mg/daohang-order-api/customerOrderLog/total`,
    method: 'post',
    data,
  })
}

/**
 * 单据入账生成收款单
 * @param {*} data
 * @returns
 */
export function receiptGeneratePayee(data, loading = false) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/completeAdd',
    method: 'post',
    data,
    loading,
  })
}

export function receivableRecordsList(data) {
  return request({
    url: '/mg/daohang-order-api/customerOrderLog/list',
    method: 'post',
    data,
  })
}

/**
 * 根据单号，类型，获取对应单据的id
 * @param {} params
 * @returns
 */
export function getAssociationId(params) {
  return request({
    url: '/mg/daohang-order-api/billNo/associationId',
    method: 'get',
    params,
  })
}

// 批量入账
export function setBulkCrediting(data) {
  return request({
    url: '/mg/daohang-order-api/settlementOrder/batchComplete',
    method: 'post',
    data,
  })
}
