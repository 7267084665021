import i18n from '@/locale'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import store from '@/store'
import { maxTabs } from '@/config'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/home/index.vue'),
        meta: { title: i18n.t('首页'), affix: true },
      },
    ],

    meta: { title: i18n.t('首页'), hidden: true },
  },
  {
    path: '/noticeList',
    hidden: true,
    meta: { hidden: true },
    component: Layout,
    children: [
      {
        path: '/noticeList/:type',
        name: 'noticeList',
        component: () => import('@/views/noticeContainer/noticeList'),
        hidden: true,
        meta: {
          name: 'noticeList',
          title: '通知列表',
        },
      },
      {
        path: '/notice/noticeDetails/:id',
        name: 'noticeDetails',
        component: () => import('@/views/noticeContainer/noticeDetails'),
        hidden: true,
        meta: {
          name: 'noticeDetails',
          title: '通知详情',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: i18n.t('登录'), hidden: true },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue'),
    meta: { title: '401', hidden: true },
  },
]

// 开发环境下，添加一些本地用于模拟测试的路由
// if (process.env.NODE_ENV === 'development') {
//   const mockRoutes = require('../../test/routes.js').default
//   constantRoutes.push(...mockRoutes)
// }

function createRouter() {
  return new VueRouter({
    routes: constantRoutes,
  })
}
const router = createRouter()

export function resetRouter() {
  router.matcher = createRouter().matcher
}

const originalPush = router.push
router.push = function (...args) {
  const location = this.match(args[0])
  const visiteRoutes = store.getters['tabsBar/visiteRoutes']
  const target = visiteRoutes.find((item) => item.path === location.path)
  if (!target && visiteRoutes?.length > maxTabs) {
    const message = i18n.t('最多只能打开{slot1}个页面，请先关闭部分页面。是否关闭前五个页面？', { slot1: maxTabs + 1 })
    Vue.prototype
      .$mgAlert(message, i18n.t('提示'), { confirmButtonText: i18n.t('关闭并打开新页面') })
      .then(() => {
        const changePages = store.getters['tabsBar/changePages']
        if (visiteRoutes.some((item) => changePages.includes(item?.name))) {
          return Vue.prototype.$mgConfirm(i18n.t('当前页面正在编辑，确认关闭?'))
        }
      })
      .then(() => {
        visiteRoutes.slice(1, 5).forEach((route) => store.dispatch('tabsBar/removeTab', route))
        originalPush.call(this, ...args)?.catch(() => {})
      })
    return
  }
  return originalPush.call(this, ...args)?.catch(() => {})
}

export default router
