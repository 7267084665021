import { getPaymentType } from '@/api/receivable/receiptVoucherApi'
const state = () => ({
  paymentTypeList: [],
})

const mutations = {
  SET_PAYMENT_TYPE_LIST(state, { list }) {
    state.paymentTypeList = list
  },
}

const actions = {
  /**
   * 当前登录人所属往来单位下所有启用状态的员工列表
   * @param {*} payload
   * @returns
   */
  async FETCH_PAYMENT_TYPE_LIST({ commit, state }) {
    // 如果存在则不再发起请求
    if (state.paymentTypeList.length > 0) return { data: state.employeeList }
    // 发起 http 请求
    const { data: list } = await getPaymentType()
    // mutation
    commit(`SET_PAYMENT_TYPE_LIST`, { list })
    return { data: list }
  },
}

export default { state, mutations, actions }
