const verbalOrderTempOptions = [
  {
    label: '批发订单',
    value: 'all',
    children: [{ label: '批发订单', value: 1 }],
  },
]
const verbalOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 120,
          paperFooter: 387.49821428571425,
          printElements: [
            {
              options: {
                left: 10.5,
                top: 6,
                height: 36,
                width: 36,
                right: 48,
                bottom: 41.25,
                vCenter: 30,
                hCenter: 23.25,
                src: 'https://sh-oss-mg.oss-cn-shanghai.aliyuncs.com/img/logo.jpg',
                fit: '',
              },
              printElementType: { title: '图片', type: 'image' },
            },
            {
              options: {
                left: 238.5,
                top: 9,
                height: 17,
                width: 120,
                right: 357.75,
                title: '晨光文具要货单',
                bottom: 25.25,
                hCenter: 16.75,
                vCenter: 297.75,
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 10.5,
                top: 46.5,
                height: 30,
                width: 170,
                field: 'barcode',
                right: 382.75,
                title: '文本',
                bottom: 301.5,
                hCenter: 286.5,
                vCenter: 242.75,
                testData: 'VO0C0000230419000003',
                textType: 'barcode',
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 334.5,
                top: 52.5,
                height: 22.5,
                width: 61.5,
                field: 'totalGiftQty',
                right: 577.5,
                title: '文本',
                bottom: 54.75,
                hCenter: 43.5,
                vCenter: 546.75,
                fontSize: 12,
                testData: '123',
                formatter: "function(value, options) { return options > 0?'本单含赠品':''}",
                hideTitle: true,
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 15,
                top: 82.5,
                height: 15,
                width: 210,
                field: 'billNo',
                right: 135,
                title: '单号',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 75,
                testData: '10000000000000000',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 279,
                top: 82.5,
                height: 15,
                width: 183,
                field: 'createTime',
                right: 371.25,
                title: '录单日期',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 279.75,
                testData: '2023-04-19',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: { left: 13.5, top: 102, height: 9, width: 570 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 279,
                top: 105,
                height: 15,
                width: 114,
                field: 'submitManPhone',
                right: 468,
                title: '电话',
                bottom: 103.5,
                hCenter: 96,
                vCenter: 376.5,
                testData: '13112331233',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 15,
                top: 105,
                height: 15,
                width: 232.5,
                field: 'customerName',
                right: 184.5,
                title: '客户',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 99,
                testData: '123-客户名称',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 403.5,
                top: 105,
                height: 15,
                width: 189,
                field: 'printTime',
                right: 616.5,
                title: '打印时间',
                bottom: 102,
                hCenter: 94.5,
                vCenter: 522,
                testData: '2023-04-06',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 133.5,
                height: 45,
                width: 567,
                field: 'printLists',
                right: 583.5,
                bottom: 171,
                hCenter: 148.5,
                vCenter: 300,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 100,
                      title: '行号',
                      field: 'skuCode',
                      checked: false,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '商品名称',
                      field: 'skuName',
                      checked: false,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品条码',
                      field: 'skuBarcode',
                      checked: false,
                      columnId: 'skuBarcode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '含税进价',
                      field: 'costAmt',
                      checked: false,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '数量',
                      field: 'stockQty',
                      checked: false,
                      columnId: 'stockQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '含税成本',
                      field: 'costPrice',
                      checked: false,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 15,
                top: 135,
                height: 42,
                width: 564,
                field: 'printLists',
                textAlign: 'center',
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                columns: [
                  [
                    {
                      width: 53.03095,
                      title: '行号',
                      field: 'index',
                      checked: true,
                      columnId: 'index',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      halign: 'center',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 91.844275,
                      title: '货号',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      halign: 'center',
                      styler2:
                        "function(value,row,index,options){ return { 'max-width': '50pt', 'white-space': row.articleNo && row.articleNo.length <= 5 ? 'nowrap' : 'pre-wrap', 'transform': 'scale(0.9)' } }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 305.46977499999997,
                      title: '品名',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      styler2:
                        "function(value,row,index,options){ return { 'max-width': '150pt', 'white-space': row.skuName && row.skuName.length <= 30 ? 'nowrap' : 'pre-wrap', 'transform': 'scale(0.9)', 'overflow': 'visible' } }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 85.76874999999995,
                      title: '规格',
                      field: 'specsDesp',
                      checked: true,
                      columnId: 'specsDesp',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 59.32675000000016,
                      title: '包装',
                      field: 'packageConversion',
                      checked: true,
                      columnId: 'packageConversion',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 78.92274999999987,
                      title: '数量',
                      field: 'saleQty',
                      checked: true,
                      columnId: 'saleQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 90.85225000000008,
                      title: '单价',
                      field: 'salePrice',
                      checked: true,
                      columnId: 'salePrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 83.97249999999991,
                      title: '金额',
                      field: 'saleAmt',
                      checked: true,
                      columnId: 'saleAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 50.81200000000004,
                      title: '赠送',
                      field: 'ifGift',
                      checked: true,
                      columnId: 'ifGift',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      formatter2: "function(value, row){return row.ifGift ? '是' :''}",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 254.637025,
                      title: '品名',
                      field: 'skuCode',
                      checked: false,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 60.830500000000114,
                      title: '包装',
                      checked: false,
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 88.69974999999995,
                      title: '数量',
                      checked: false,
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 76.60974999999999,
                      title: '单价',
                      checked: false,
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 92.5584999999999,
                      title: '金额',
                      checked: false,
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 142.5,
                top: 178.5,
                height: 15,
                width: 127.5,
                field: 'totalAskAmt',
                right: 273,
                title: '原金额',
                bottom: 169.4999885559082,
                hCenter: 161.9999885559082,
                vCenter: 215.25,
                testData: '100',
                formatter: "function(value, options){return (options == 0 ? '0' : options)}",
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 295.5,
                top: 178.5,
                height: 15,
                width: 120,
                field: 'finalZkzrAmt',
                right: 414,
                title: '优惠金额',
                bottom: 226.5,
                hCenter: 219,
                vCenter: 354,
                testData: '0',
                formatter: "function(value, options){return (options == 0 ? '0' : options)}",
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 178.5,
                height: 15,
                width: 114,
                field: 'totalQty',
                right: 112.5,
                title: '总数量',
                bottom: 169.4999885559082,
                hCenter: 161.9999885559082,
                vCenter: 63,
                testData: '100',
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
  },
}

export default {
  verbalOrderTempOptions,
  verbalOrderTempJsonOptions,
}
