import i18n from '@/locale'
import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/page',
    method: 'post',
    data,
  })
}

export function save(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder',
    method: 'put',
    data,
    loading: true,
  })
}

export function detail(id) {
  return request({
    url: `/mg/daohang-order-api/verbalOrder/${id}`,
    method: 'get',
    loading: true,
  })
}

export function statusUpdate(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/examineAndApprove',
    method: 'post',
    loading: true,
    data,
  })
}

export function pageExport(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function pageSummary(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/suctionTotal',
    method: 'post',
    data,
  })
}

export function pageDel(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder',
    method: 'delete',
    data,
  })
}

/**
 * 单品结案
 * @param {*} data
 * @returns
 */
export function itemCompleteClose(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/windUp',
    method: 'post',
    loading: true,
    data,
  })
}

/**
 * 整单结案
 * @param {*} data
 * @returns
 */
export function completeOrderClosure(data) {
  return request({
    url: `/mg/daohang-order-api/verbalOrder/allInWindUpCase`,
    method: 'post',
    data,
    loading: true,
  })
}

export function itemDel(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrderItem',
    method: 'delete',
    data,
  })
}

export function itemExport(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrderItem/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function downloadErrorFile(params) {
  return request({
    url: `/mg/daohang-order-api/genericExcelFile/error/exportExcel`,
    method: 'get',
    responseType: 'blob',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 通过 skuId 查询多包装信息
 * @param {*} params
 * @returns
 */
export function packageUnitList(data) {
  return request({
    url: '/mg/daohang-product-api/skuPackage/list',
    method: 'post',
    data,
  })
}

/**
 * 转天下
 * @param {*} data
 * @returns
 */
export function transferDrp(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/changeDrp',
    method: 'post',
    data,
    loading: true,
    loadingText: i18n.t('转天下'),
  })
}

export function partTransferDrp(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/partChangeDrp',
    method: 'post',
    data,
    loading: true,
    loadingText: i18n.t('部分转天下'),
  })
}

export function editAbstract(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/summary',
    method: 'post',
    data,
  })
}

export function exportOfflineDelivery(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/offlineShipmentExportExcel',
    method: 'post',
    data,
    responseType: 'blob',
    loading: true,
  })
}

// 备注修改
export function alterRemark(data) {
  return request({
    url: '/mg/daohang-order-api/verbalOrder/editRemark',
    method: 'post',
    data,
    loading: true,
  })
}
