import request from '@/utils/request'

// 退货申请单列表
export function getReturnApplicationList(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrder/page',
    method: 'post',
    data,
  })
}

// 退货申请单列表合计
export function getReturnApplicationSum(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrder/listTotal',
    method: 'post',
    data,
  })
}

// 退货申请单保存
export function saveReturnApplication(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrder',
    method: 'put',
    loading: true,
    data,
  })
}

// 退货申请单删除
export function removeReturnApplication(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrder',
    method: 'delete',
    data,
  })
}

// 退货申请单删除子表
export function removeReturnApplicationItem(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrderItem',
    method: 'delete',
    data,
  })
}

// 退货申请单审核 - 反审核 -入账
export function auditReturnApplication(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReturnApplyOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}

// 退货申请单查看
export function getReturnApplicationDetail(id) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnApplyOrder/${id}`,
    method: 'get',
  })
}

// 退货申请单导出
export function exportReturnApplicationExcel(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnApplyOrder/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 退货申请单明细导出
export function exportReturnApplicationItemsExcel(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnApplyOrderItem/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}
