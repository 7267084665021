/**
 * 领用单相关打印变量
 */
const receiveOrderTempOptions = [
  {
    label: '领用单',
    value: 'all',
    children: [{ label: '领用单(领用单)', value: 1 }],
  },
]
const receiveOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 73.5,
          paperFooter: 391.275,
          printElements: [
            {
              options: {
                left: 187.5,
                top: 18,
                height: 17,
                width: 207,
                right: 396.75,
                title: '义务配送中心内部领用单',
                bottom: 34.25,
                hCenter: 25.75,
                vCenter: 293.25,
                fontSize: 16.5,
                testData: '',
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 45,
                height: 15,
                width: 175.5,
                field: 'billNo',
                right: 184.5,
                title: '单据编号',
                bottom: 60,
                hCenter: 52.5,
                vCenter: 99,
                fontSize: 10,
                testData: 'LYC15050230419000001',
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 226.5,
                top: 45,
                height: 15,
                width: 160.5,
                field: 'createTime',
                right: 369,
                title: '录入日期',
                bottom: 60,
                hCenter: 52.5,
                vCenter: 297.75,
                fontSize: 10,
                testData: '2023-12-12 19:58:58',
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 444,
                top: 45,
                height: 15,
                width: 120,
                field: 'createUserName',
                right: 564,
                title: '制单人',
                bottom: 60,
                hCenter: 52.5,
                vCenter: 504,
                fontSize: 10,
                testData: '李小姐',
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 9,
                top: 64.5,
                height: 9,
                width: 555,
                right: 563.25,
                bottom: 72.75,
                hCenter: 68.25,
                vCenter: 285.75,
                borderWidth: '1.5',
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 13.5,
                top: 85.5,
                height: 43.5,
                width: 550,
                field: 'printLists',
                right: 563.5,
                bottom: 129,
                hCenter: 107.25,
                vCenter: 288.5,
                fontSize: 10,
                textAlign: 'center',
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderFontWeight: 'bold',
                columns: [
                  [
                    {
                      width: 68.785,
                      title: '序号',
                      field: 'index',
                      checked: true,
                      columnId: 'index',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 131.215,
                      title: '商品货号',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 67.64124999999997,
                      title: '单位',
                      field: 'stockUnit',
                      checked: true,
                      columnId: 'stockUnit',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 78.31975000000003,
                      title: '数量',
                      field: 'receiveQty',
                      checked: true,
                      columnId: 'receiveQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 154.03900000000002,
                      title: '进价',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '金额',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 211.5,
                top: 129,
                height: 21,
                width: 144,
                field: 'totalReceiveQty',
                right: 354.75,
                title: '合计数量',
                bottom: 147.75,
                hCenter: 137.25,
                vCenter: 282.75,
                fontSize: 12,
                testData: '5896',
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 384,
                top: 129,
                height: 21,
                width: 150,
                field: 'totalCostAmt',
                right: 529.5,
                title: '合计金额',
                bottom: 156,
                hCenter: 145.5,
                vCenter: 457.5,
                fontSize: 12,
                testData: '859632.00',
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 10.5,
                top: 150,
                height: 9,
                width: 553.5,
                right: 555.75,
                bottom: 161.25,
                hCenter: 156.75,
                vCenter: 282,
                borderWidth: '1.5',
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 183,
                top: 159,
                height: 15,
                width: 120,
                field: 'reviewUserName',
                right: 306.75,
                title: '审核',
                bottom: 189,
                hCenter: 181.5,
                vCenter: 246.75,
                fontSize: 10,
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 10.5,
                top: 159,
                height: 15,
                width: 120,
                field: 'createUserName',
                right: 133.5,
                title: '领用人',
                bottom: 176.25,
                hCenter: 168.75,
                vCenter: 73.5,
                fontSize: 10,
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 556.5,
          paperNumberTop: 28.5,
        },
      ],
    },
  },
}

export default {
  // 领用单 -- 不同配送中心打印模板名
  receiveOrderTempOptions,
  // 领用单 -- 不同分公司打印模板名json
  receiveOrderTempJsonOptions,
}
