import axios from 'axios'
import { baseURL, contentType, requestTimeout } from '@/config'
import { loading, handleMd5Sign, addHeader, gzipRequestBody, addCache } from './interceptors/request'
import { handleResponseSuccess, handleResponseError, handleSpeechResponseError } from './interceptors/response'
import RequestManager from './RequestManager'

axios.defaults.timeout = requestTimeout
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': contentType,
  },
})

/**
 * 管理接口请求（拦截重复请求）
 * 通过 allowRepeat 字段配置是否允许重复请求，默认 false
 * @example
 * request({
 *  url: '/xxx',
 *  allowRepeat: true
 * })
 */
RequestManager.setRequest(instance)
// 请求拦截
instance.interceptors.request.use(gzipRequestBody)
instance.interceptors.request.use(addHeader)
instance.interceptors.request.use(handleMd5Sign)
instance.interceptors.request.use(loading)
instance.interceptors.request.use(addCache)


// 响应拦截
instance.interceptors.response.use(handleResponseSuccess, handleResponseError)
instance.interceptors.response.use(null, handleSpeechResponseError)

export default instance
