
/**
 * 价签相关打印变量
 */
const priceTagTempOptions = [
    {
        label: '价签',
        value: 'all',
        children: [
            { label: '价签--暗码', value: 1 },
            { label: '价签--条码', value: 2 },
        ],
    },
]
const priceTagTempJsonOptions = {
    all: {
        1: {
            "panels": [
                {
                    "index": 0,
                    "name": 1,
                    "height": 40,
                    "width": 60,
                    "paperHeader": 0,
                    "paperFooter": 111,
                    "printElements": [
                        {
                            "options": {
                                "left": 10,
                                "top": 12.5,
                                "height": 33,
                                "width": 145.5,
                                "field": "skuName",
                                "right": 117.75,
                                "title": "文本",
                                "bottom": 27.24375915527344,
                                "hCenter": 15.243759155273438,
                                "vCenter": 59.25,
                                "fontSize": 10,
                                "testData": "晨光直液式逸品签字笔ARP41801黑0.5",
                                "hideTitle": true,
                                "fontFamily": "SimSun",
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 12.5,
                                "top": 47.5,
                                "height": 13.5,
                                "width": 58.5,
                                "field": "articleNo",
                                "right": 70.99687957763672,
                                "title": "文本",
                                "bottom": 67.99687957763672,
                                "hCenter": 61.24687957763672,
                                "vCenter": 41.74687957763672,
                                "fontSize": 11,
                                "testData": "41801a",
                                "hideTitle": true,
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 12.5,
                                "top": 67.5,
                                "height": 13.5,
                                "width": 87,
                                "field": "specsDesp",
                                "right": 100.99687957763672,
                                "title": "文本",
                                "bottom": 81,
                                "hCenter": 74.25,
                                "vCenter": 57.49687957763672,
                                "fontSize": 11,
                                "testData": "1/12/144/1728",
                                "hideTitle": true,
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 112.5,
                                "top": 75,
                                "height": 22.5,
                                "width": 49.5,
                                "field": "retailPrice",
                                "right": 160.99687957763672,
                                "title": "文本",
                                "bottom": 98.74687957763672,
                                "hCenter": 87.49687957763672,
                                "vCenter": 136.24687957763672,
                                "fontSize": 18,
                                "testData": "3.555",
                                "hideTitle": true,
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 10,
                                "top": 87.5,
                                "height": 13.5,
                                "width": 87,
                                "field": "wholesalePrice",
                                "right": 96,
                                "title": "文本",
                                "bottom": 102,
                                "hCenter": 95.25,
                                "vCenter": 52.5,
                                "fontSize": 11,
                                "testData": "10",
                                "formatter": "function(title,value,options,templateData,target) {\n    return value && `80${ value && String(value)?.replace('.', 'A') || '0' }08` || `80${ value && String(value)?.replace('.', 'A') || '0' }08`\n}",
                                "hideTitle": true,
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        }
                    ],
                    "paperNumberLeft": 111,
                    "paperNumberTop": 15,
                    "paperNumberDisabled": true,
                    "paperNumberContinue": true,
                    "watermarkOptions": {}
                }
            ]
        },
        2: {
            "panels": [
                {
                    "index": 0,
                    "name": 1,
                    "height": 40,
                    "width": 60,
                    "paperHeader": 0,
                    "paperFooter": 111,
                    "printElements": [
                        {
                            "options": {
                                "left": 7.5,
                                "top": 5,
                                "height": 24,
                                "width": 157.5,
                                "field": "skuName",
                                "right": 155.49609375,
                                "title": "文本",
                                "bottom": 38.7421875,
                                "hCenter": 22.2421875,
                                "vCenter": 82.74609375,
                                "fontSize": 10,
                                "testData": "晨光直液式逸品签字笔ARP41801黑0.5",
                                "hideTitle": true,
                                "fontFamily": "SimSun",
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 7.5,
                                "top": 30,
                                "height": 13.5,
                                "width": 99,
                                "field": "articleNo",
                                "right": 67.74609375,
                                "title": "文本",
                                "bottom": 44.7421875,
                                "hCenter": 37.9921875,
                                "vCenter": 38.49609375,
                                "fontSize": 11,
                                "testData": "41801a",
                                "hideTitle": true,
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 112.5,
                                "top": 37.5,
                                "height": 22.5,
                                "width": 49.5,
                                "field": "retailPrice",
                                "right": 160.99687957763672,
                                "title": "文本",
                                "bottom": 98.74687957763672,
                                "hCenter": 87.49687957763672,
                                "vCenter": 136.24687957763672,
                                "fontSize": 18,
                                "testData": "3.555",
                                "hideTitle": true,
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 7.5,
                                "top": 47.5,
                                "height": 13.5,
                                "width": 97.5,
                                "field": "specsDesp",
                                "right": 92.7421875,
                                "title": "文本",
                                "bottom": 61.74609375,
                                "hCenter": 54.99609375,
                                "vCenter": 49.2421875,
                                "fontSize": 11,
                                "testData": "1/12/144/1728",
                                "hideTitle": true,
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 7.5,
                                "top": 67.5,
                                "height": 13.5,
                                "width": 100.5,
                                "field": "wholesalePrice",
                                "right": 76.5,
                                "title": "文本",
                                "bottom": 81,
                                "hCenter": 74.25,
                                "vCenter": 43.5,
                                "fontSize": 11,
                                "testData": "10",
                                "formatter": "function(title,value,options,templateData,target) {\n    return value && `80${ value && String(value)?.replace('.', 'A') || '0' }08` || `80${ value && String(value)?.replace('.', 'A') || '0' }08`\n}",
                                "hideTitle": true,
                                "fontWeight": "bolder",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 5,
                                "top": 82.5,
                                "height": 24,
                                "width": 160,
                                "field": "skuBarcode",
                                "right": 164.99687957763672,
                                "title": "条形码",
                                "bottom": 107.49375915527344,
                                "hCenter": 95.49375915527344,
                                "vCenter": 84.99687957763672,
                                "testData": "skuBarcode",
                                "hideTitle": true,
                                "barcodeType": "code128",
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "条形码",
                                "type": "barcode"
                            }
                        }
                    ],
                    "paperNumberLeft": 111,
                    "paperNumberTop": 15,
                    "paperNumberDisabled": true,
                    "paperNumberContinue": true,
                    "watermarkOptions": {}
                }
            ]
        }
    }
}

export default {
    // 价签 -- 不同配送中心打印模板名
    priceTagTempOptions,
    // 价签 -- 不同分公司打印模板名json
    priceTagTempJsonOptions
}