import request from '@/utils/request'

/** 采购订单-分页 */
export function getPurchaseOrderPage(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder/page',
    method: 'post',
    data,
  })
}

export function save(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder',
    method: 'put',
    loading: true,
    data,
  })
}

export function del(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder',
    method: 'delete',
    data,
  })
}

export function statusUpdate(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}

export function detail(params, loading = true) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder/itemList',
    method: 'get',
    loading,
    params,
  })
}

export function itemDel(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrderItem',
    method: 'delete',
    loading: true,
    data,
  })
}

export function pageExport(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function itemExport(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrderItem/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function sum(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseOrder/sum',
    method: 'post',
    data,
  })
}
