import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.108.68' : '/'
export function prePayOrderPage(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/page`,
    method: 'post',
    data,
  })
}
export function prePayOrderSaveOrUpdate(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/saveOrUpdate`,
    method: 'put',
    loading: true,
    data,
  })
}
export function getPrePayOrderById(id) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
export function prePayOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}
export function prePayOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
export function prePayOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
export function prePayOrderSum(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/sum`,
    method: 'post',
    data,
  })
}

//预收款单批量入账
export function prePayOrderBatchComplete(data) {
  return request({
    url: `/mg/daohang-order-api/prePayOrder/batchComplete`,
    method: 'post',
    data,
  })
}
