import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { LocaleStorage } from '@/utils/storage'
Vue.use(VueI18n)
const locale = LocaleStorage.get()
// 预装语言 zh-CN
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: locale,
  messages: {
    'zh-CN': require('./lang/zh-CN.json'),
    'en-US': require('./lang/en-US.json'),
    id: require('./lang/id.json'),
  },
})
export default i18n
