import request from '@/utils/request'

// 品牌列表
export function getBrandList(data) {
  return request({
    url: '/mg/daohang-product-api/brand/page',
    method: 'post',
    data,
  })
}

// 品牌列表
export function getBrandCommonList(data) {
  return request({
    url: '/mg/daohang-product-api/brand/list',
    method: 'post',
    data,
  })
}

// 税目列表
export function getTaxItemsList(data) {
  return request({
    url: '/mg/daohang-product-api/taxItems/list',
    method: 'post',
    data,
  })
}

// 品牌保存
export function saveBrand(data) {
  return request({
    url: '/mg/daohang-product-api/brand',
    method: 'put',
    data,
  })
}
// 品牌编辑
export function editBrand(data) {
  return request({
    url: '/mg/daohang-product-api/brand/edit',
    method: 'post',
    data,
  })
}
// 品牌禁用
export function disableBrand(data) {
  return request({
    url: '/mg/daohang-product-api/brand/disable',
    method: 'post',
    data,
  })
}
// 品牌启用
export function enableBrand(data) {
  return request({
    url: '/mg/daohang-product-api/brand/enable',
    method: 'post',
    data,
  })
}

/** ------------------品类------------------------------*/
// 品类树
export function getCategoryListTree(params) {
  return request({
    url: '/mg/daohang-product-api/category/tree',
    method: 'get',
    params,
  })
}
//品类列表
export function getCategoryList(data) {
  return request({
    url: '/mg/daohang-product-api/category/page',
    method: 'post',
    data,
  })
}

//品类列表
export function getCategoryListCommon(data) {
  return request({
    url: '/mg/daohang-product-api/category/list',
    method: 'post',
    data,
  })
}
//品类新增
export function saveCategory(data) {
  return request({
    url: '/mg/daohang-product-api/category',
    method: 'put',
    data,
  })
}
// 品类编辑
export function editCategory(data) {
  return request({
    url: '/mg/daohang-product-api/category/edit',
    method: 'post',
    data,
  })
}
// 禁用品类
export function disableCategory(data) {
  return request({
    url: '/mg/daohang-product-api/category/disable',
    method: 'post',
    data,
  })
}
// 启用品类
export function enableCategory(data) {
  return request({
    url: '/mg/daohang-product-api/category/enable',
    method: 'post',
    data,
  })
}
// 导出品类
export function exportCategory(data) {
  return request({
    url: '/mg/daohang-product-api/category/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

/** ----------- 商品 ----------*/
// 保存商品
export function saveGoods(data) {
  return request({
    url: '/mg/daohang-product-api/sku',
    method: 'put',
    data,
  })
}
// 删除商品
export function deleteGoods(data) {
  return request({
    url: '/mg/daohang-product-api/sku',
    method: 'delete',
    data,
  })
}

// 删除商品
export function removeGoodsRow(data) {
  return request({
    url: '/mg/daohang-product-api/skuPackage',
    method: 'delete',
    data,
  })
}
// 获取详情
export function getGoodsDetail(id) {
  return request({
    url: `/mg/daohang-product-api/sku/${id}`,
    method: 'get',
  })
}
// 禁用商品
export function disableGoods(data) {
  return request({
    url: '/mg/daohang-product-api/sku/disable',
    method: 'post',
    data,
  })
}
// 启用商品
export function enableGoods(data) {
  return request({
    url: '/mg/daohang-product-api/sku/enable',
    method: 'post',
    data,
  })
}
// 编辑商品
export function editGoods(data) {
  return request({
    url: '/mg/daohang-product-api/sku/edit',
    method: 'post',
    data,
  })
}
// 商品列表
export function getGoodsList(data) {
  return request({
    url: '/mg/daohang-product-api/sku/page',
    method: 'post',
    data,
  })
}
// 所有商品查询接口
export function getGoodsDialogList(data) {
  return request({
    url: '/mg/daohang-product-api/goodsCache/page',
    method: 'post',
    data,
  })
}

// 商品导出
export function exportGoodsExcel(data) {
  return request({
    url: '/mg/daohang-product-api/sku/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

// 商品导出
export function exportErrorGoodsExcel(params) {
  return request({
    url: '/mg/daohang-product-api/genericExcelFile/error/exportExcel',
    method: 'get',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
}

// 库存单位查询
export function getDictList(data) {
  return request({
    url: '/mg/daohang-basic-api/dictItem/list',
    method: 'post',
    data,
  })
}

// 地采商品导出
export function exportLocalGoodsExcel(data) {
  return request({
    url: '/mg/daohang-product-api/sku/list/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}
