<template>
  <el-menu :default-openeds="defaultOpeneds" :router="true" :default-active="defaultActive">
    <template v-for="menu in data">
      <SubMenu v-if="!isHidden(menu)" :menu="menu" :key="menu.path" />
    </template>
  </el-menu>
</template>

<script>
  import SubMenu from './submenu.vue'
  export default {
    name: 'Menu',
    components: { SubMenu },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      defaultOpeneds() {
        return this.data.map((item) => item.path)
      },
      defaultActive() {
        return this.$route.path
      },
    },
    methods: {
      isHidden(menu) {
        return menu?.meta?.hidden
      },
    },
  }
</script>

<style></style>
