/**
 * 批发退货相关打印变量
 */
const saleReturnOrderTempOptions = [
  {
    label: '批发退货单',
    value: 'all',
    children: [
      { label: '批发退货单(批发退货单)', value: 1 },
      { label: '价签', value: 2 },
      // { label: '价签-两列', value: 3 }
    ],
  },
]
const saleReturnOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 7.5,
          paperFooter: 373.5,
          printElements: [
            {
              options: {
                left: 186,
                top: 15,
                height: 17,
                width: 174,
                title: '南宁晨兴文具经营部',
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 204,
                top: 31.5,
                height: 12,
                width: 183,
                right: 379.5,
                title: '南宁市杭州路18号金和商贸城3楼',
                bottom: 59.9999885559082,
                hCenter: 47.9999885559082,
                vCenter: 288,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 93,
                top: 40.5,
                height: 15,
                width: 120,
                right: 189,
                title: '电话：（0771）2820393',
                bottom: 57.7499885559082,
                hCenter: 50.2499885559082,
                vCenter: 129,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 319.5,
                top: 42,
                height: 15,
                width: 120,
                right: 434.25,
                title: '传真：（0771）2861282',
                bottom: 54.7500114440918,
                hCenter: 47.2500114440918,
                vCenter: 374.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12,
                top: 57,
                height: 9,
                width: 555,
                right: 573,
                bottom: 67.4999885559082,
                hCenter: 62.9999885559082,
                vCenter: 295.5,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 184.5,
                top: 61.5,
                height: 17,
                width: 174,
                right: 358.5,
                title: '退        货        明        细        表',
                bottom: 81.4999885559082,
                hCenter: 72.9999885559082,
                vCenter: 271.5,
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 172.5,
                top: 81,
                height: 15,
                width: 168,
                field: 'customerName',
                right: 387,
                title: '客户',
                bottom: 98.2499885559082,
                hCenter: 90.7499885559082,
                vCenter: 303,
                testData: '8600-义务123519可适当放宽',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 343.5,
                top: 81,
                height: 15,
                width: 142.5,
                field: 'createTime',
                right: 502.5,
                title: '录单日期',
                bottom: 98.9999885559082,
                hCenter: 91.4999885559082,
                vCenter: 431.25,
                testData: '2023-12-12 19:19:19',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 10.5,
                top: 81,
                height: 15,
                width: 159,
                field: 'billNo',
                right: 192.75,
                title: '单据编号',
                bottom: 95.9999885559082,
                hCenter: 88.4999885559082,
                vCenter: 107.25,
                testData: 'PT0C0000230421000002',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 489,
                top: 82.5,
                height: 13.5,
                width: 76.5,
                field: 'remark',
                right: 293.25,
                title: '备注',
                bottom: 144.7499885559082,
                hCenter: 137.9999885559082,
                vCenter: 251.25,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '长文', type: 'longText' },
            },
            {
              options: {
                left: 12,
                top: 102,
                height: 42,
                width: 550,
                field: 'printLists',
                right: 559,
                bottom: 155.9999885559082,
                hCenter: 128.9999885559082,
                vCenter: 284,
                textAlign: 'center',
                coordinateSync: false,
                widthHeightSync: false,
                tableHeaderFontSize: 9.75,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderFontWeight: 'bold',
                columns: [
                  [
                    {
                      width: 149.078275,
                      title: '货号',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 84.25375000000004,
                      title: '品名',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 98.28100000000003,
                      title: '数量',
                      field: 'qty',
                      checked: true,
                      columnId: 'qty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 94.99674999999992,
                      title: '单位',
                      field: 'stockUnit',
                      checked: true,
                      columnId: 'stockUnit',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 109.35175000000008,
                      title: '单价',
                      field: 'returnPrice',
                      checked: true,
                      columnId: 'returnPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 113.11674999999994,
                      title: '金额',
                      field: 'returnableAmt',
                      checked: true,
                      columnId: 'returnableAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '备注',
                      field: 'remark',
                      checked: true,
                      columnId: 'remark',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 418.5,
                top: 145.5,
                height: 13.5,
                width: 144,
                field: 'actualReturnAmt',
                right: 565.74609375,
                title: '合计',
                bottom: 178.74609375,
                hCenter: 171.99609375,
                vCenter: 493.74609375,
                fontSize: 16,
                testData: '123123',
                formatter: "function(value,options){return '¥'+(options == 0 ? '0' : options)}",
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12,
                top: 147,
                height: 13.5,
                width: 144,
                field: 'totalReturnQty',
                right: 153,
                title: '总数量',
                bottom: 160.5000114440918,
                hCenter: 153.7500114440918,
                vCenter: 81,
                fontSize: 16,
                testData: '123123',
                formatter: "function(value,options){return (options == 0 ? '0' : options)}",
                fontWeight: 'bold',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 429,
                top: 163.5,
                height: 15,
                width: 151.5,
                right: 578.25,
                title: '复核：',
                bottom: 177.0000114440918,
                hCenter: 169.5000114440918,
                vCenter: 502.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 300,
                top: 165,
                height: 15,
                width: 121.5,
                right: 470.25,
                title: '配货',
                bottom: 182.9999885559082,
                hCenter: 175.4999885559082,
                vCenter: 394.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 159,
                top: 165,
                height: 15,
                width: 121.5,
                field: 'reviewUserName',
                right: 399,
                title: '审核',
                bottom: 198.7500114440918,
                hCenter: 191.2500114440918,
                vCenter: 323.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 10.5,
                top: 165,
                height: 15,
                width: 136.5,
                field: 'createUserName',
                right: 144,
                title: '制单人',
                bottom: 178.5000114440918,
                hCenter: 171.0000114440918,
                vCenter: 66,
                testData: '王大姐',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 298.5,
                top: 181.5,
                height: 15,
                width: 120,
                field: '123',
                right: 144,
                title: '客户签字',
                bottom: 200.25,
                hCenter: 195.375,
                vCenter: 84,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12,
                top: 184.5,
                height: 15,
                width: 138,
                right: 162.24375915527344,
                title: '经手人：',
                bottom: 206.49374771118164,
                hCenter: 198.99374771118164,
                vCenter: 86.49375915527344,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12,
                top: 204,
                height: 18,
                width: 540,
                right: 380.25,
                title: '温馨提示：对本单的商品单价、数量有异议者，请在三天内回电咨询，逾期自负！谢谢！',
                bottom: 214.4999885559082,
                hCenter: 209.6249885559082,
                vCenter: 195,
                fontSize: 10,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 391.5,
                top: 220.5,
                height: 15,
                width: 151.5,
                field: 'printTime',
                right: 542.25,
                title: '打印时间',
                bottom: 233.9999885559082,
                hCenter: 226.4999885559082,
                vCenter: 466.5,
                testData: '2023-04-55 19：23：23',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 541.5,
          paperNumberTop: 379.5,
        },
      ],
    },
    2: {
      "panels": [
        {
          "index": 0,
          "name": 1,
          "height": 40,
          "width": 60,
          "paperHeader": 2.5,
          "paperFooter": 105,
          "printElements": [
            {
              "options": {
                "left": 5,
                "top": 5,
                "height": 93,
                "width": 157.5,
                "field": "printLists",
                "right": 220.74609375,
                "bottom": 86.25,
                "hCenter": 46.5,
                "vCenter": 113.49609375,
                "tableBorder": "noBorder",
                "coordinateSync": false,
                "widthHeightSync": false,
                "tableFooterBorder": "noBorder",
                "tableHeaderBorder": "noBorder",
                "tableHeaderRepeat": "none",
                "tableBodyRowBorder": "noBorder",
                "tableBodyCellBorder": "noBorder",
                "groupFooterFormatter": "function(t,e){return\"这里自定义统计脚信息\";}",
                "tableFooterCellBorder": "noBorder",
                "tableHeaderCellBorder": "noBorder",
                "columns": [
                  [
                    {
                      "width": 157.5,
                      "title": "商品名称",
                      "field": "skuName",
                      "checked": true,
                      "columnId": "skuName",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "left",
                      "renderFormatter": "function(value,row,index,options){ return `<div><div style=\"display: flex; align-items: center;\"><span style=\"display: flex;align-items: center;max-width: 90%;height: 20pt;white-space: pre-wrap;margin: 8pt 0 0 38pt;\">${ row?.skuName || '' }</span></div><div style=\"display: flex;margin: 0 0 0 38pt;\"><div><div style=\"margin-top: 7pt;white-space: nowrap;font-size: 9pt;font-weight: bold;\"><span>${ row?.articleNo || '' }</span></div><div style=\"margin-top: 9pt;white-space: nowrap;font-size: 9pt;font-weight: bold;\"><span>${ row?.specsDesp || '' }</span></div><div style=\"margin-top: 9pt;white-space: nowrap;font-size: 9pt;font-weight: bold;\"><span>${ row?.wholesalePrice && `80${ String(row?.wholesalePrice).replaceAll('.', 'A') }08` || '' }</span></div></div><div style=\"display: flex;flex-direction: column;justify-content: space-between;margin: 40px 0 0 24px;font-size:20pt;white-space: nowrap;font-weight: bold;\"><div><span>${ row?.retailPrice || '' }</span></div></div></div></div>` }",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    }
                  ]
                ]
              },
              "printElementType": {
                "title": "表格",
                "type": "table",
                "editable": true,
                "columnDisplayEditable": true,
                "columnDisplayIndexEditable": true,
                "columnTitleEditable": true,
                "columnResizable": true,
                "columnAlignEditable": true,
                "isEnableEditField": true,
                "isEnableContextMenu": true,
                "isEnableInsertRow": true,
                "isEnableDeleteRow": true,
                "isEnableInsertColumn": true,
                "isEnableDeleteColumn": true,
                "isEnableMergeCell": true
              }
            }
          ],
          "paperNumberLeft": 145,
          "paperNumberTop": 17.5,
          "paperNumberDisabled": true,
          "paperNumberContinue": true,
          "watermarkOptions": {}
        }
      ]
    },
    // 3: {
    //   "panels": [
    //     {
    //       "index": 0,
    //       "name": 1,
    //       "height": 30,
    //       "width": 110,
    //       "paperHeader": 5,
    //       "paperFooter": 75,
    //       "printElements": [
    //         {
    //           "options": {
    //             "left": 5,
    //             "top": 7.5,
    //             "height": 57,
    //             "width": 294,
    //             "field": "printLists",
    //             "right": 274.74609375,
    //             "bottom": 72.24609375,
    //             "styler": "function(value, options, target,templateData){\n    $(target).find('.hiprint-printElement-table-content .hiprint-printElement-tableTarget tbody').css({ 'display': 'flex', 'flex-wrap': 'wrap' })\n}",
    //             "hCenter": 46.74609375,
    //             "vCenter": 140.49609375,
    //             "rowStyler": "function(value,options){ return { 'margin-bottom': '6pt' } }",
    //             "tableBorder": "noBorder",
    //             "coordinateSync": false,
    //             "widthHeightSync": false,
    //             "tableHeaderBorder": "noBorder",
    //             "tableHeaderRepeat": "none",
    //             "tableBodyRowBorder": "noBorder",
    //             "groupFooterFormatter": "function(t,e){return\"这里自定义统计脚信息\";}",
    //             "tableHeaderCellBorder": "noBorder",
    //             "columns": [
    //               [
    //                 {
    //                   "width": 343.5,
    //                   "title": "商品条码",
    //                   "field": "skuBarcode",
    //                   "checked": true,
    //                   "columnId": "skuBarcode",
    //                   "fixed": false,
    //                   "rowspan": 1,
    //                   "colspan": 1,
    //                   "align": "left",
    //                   "formatter2": "function(value,row,index,options){ return `<div style=\"width: 100%; margin: 0 auto; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;padding-left: 3px;\">${ row?.skuName || '' }</div>` }",
    //                   "styler2": "function(value,row,index,options){ return { 'max-width': '100pt' } }",
    //                   "stylerHeader": "function(options){ return { 'max-width': '100pt' } }",
    //                   "tableTextType": "barcode",
    //                   "tableBarcodeMode": "code128",
    //                   "tableQRCodeLevel": 0,
    //                   "tableSummaryTitle": true,
    //                   "tableSummary": ""
    //                 }
    //               ]
    //             ]
    //           },
    //           "printElementType": {
    //             "title": "表格",
    //             "type": "table",
    //             "editable": true,
    //             "columnDisplayEditable": true,
    //             "columnDisplayIndexEditable": true,
    //             "columnTitleEditable": true,
    //             "columnResizable": true,
    //             "columnAlignEditable": true,
    //             "isEnableEditField": true,
    //             "isEnableContextMenu": true,
    //             "isEnableInsertRow": true,
    //             "isEnableDeleteRow": true,
    //             "isEnableInsertColumn": true,
    //             "isEnableDeleteColumn": true,
    //             "isEnableMergeCell": true
    //           }
    //         }
    //       ],
    //       "paperNumberLeft": 253,
    //       "paperNumberTop": 18,
    //       "paperNumberDisabled": true,
    //       "paperNumberContinue": true,
    //       "panelPageRule": "none",
    //       "watermarkOptions": {}
    //     }
    //   ]
    // }
  },
}

export default {
  // 批发退货单 -- 不同配送中心打印模板名
  saleReturnOrderTempOptions,
  // 批发退货单 -- 不同分公司打印模板名json
  saleReturnOrderTempJsonOptions,
}
