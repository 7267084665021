import i18n from '@/locale'
import { getGlobalTableConfig, saveGlobalTableConfig, getAliyunToken, getOssHost } from '@/api/config'
import ElementUI, { Message, MessageBox } from 'element-ui'
import langCN from 'element-ui/lib/locale/lang/zh-CN'
import langEN from 'element-ui/lib/locale/lang/en'
import langID from 'element-ui/lib/locale/lang/id'
import locale from 'element-ui/lib/locale'
import { VXETable, Table, Column, Footer, Keyboard, Edit, Tooltip } from 'vxe-table'
import XEUtils from 'xe-utils'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'
import MGUI from '@mg/mg-ui'
import MGPRINT from '@mg/mg-print'
import MessageBoxContent from '@/components/message-box-content'
import EventBus from './event-bus'
import { tokenName } from '@/config'
import { salt } from '@/config'
import md5 from 'crypto-js/md5'
import bigjs from 'big.js'
import { getStorage, setStorage, LocaleStorage } from '@/utils/storage'
import Mousetrap from 'mousetrap'
import router from '@/router'

const ElementUILocale = {
  'zh-CN': langCN,
  'en-US': langEN,
  id: langID,
}
const VxeTableLocale = {
  'zh-CN': zhCN,
  'en-US': enUS,
  id: enUS,
}

const install = (Vue) => {
  const lang = LocaleStorage.get()
  locale.use(ElementUILocale[lang])

  /** ElementUI **/
  Vue.use(ElementUI, { size: 'small' })
  /** VXETable */
  VXETable.setup({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(VxeTableLocale[lang], key), args),
  })
  Vue.use(Column).use(Footer).use(Keyboard).use(Edit).use(Tooltip).use(Table)
  /** MgUI **/
  Vue.use(MGUI, {
    i18n,
    table: {
      getColumnApi: getGlobalTableConfig,
      setColumnApi: saveGlobalTableConfig,
    },
    utils: {
      getOssHost: getOssHost,
      getAliyunToken: getAliyunToken,
      tokenName: tokenName,
      salt: salt,
      md5: md5,
    },
    dropdownButton: {
      getStorage,
      setStorage(key, data) {
        setStorage(key, data.printTemplateId)
      },
    },
    // 通用本地缓存配置
    storage: {
      getStorage,
      setStorage,
    },
    BN: bigjs,
    Mousetrap,
    searchForm: {
      // 列表页面筛选项全局日期校验
      checkDateRange(formConfig, formParams) {
        // 根据路由 name 排除以下单据
        const excluding = [
          'warehouseLocation',
          'WarehousePropertiesList',
          'LocalCommodity',
          'skubom',
          'goodsOperatAttr',
          'ReturnPoint',
          'noRebateGoods',
          'noDiscountGoods',
          'CleanRecordQuery',
          'paymentSlipAdd',
          'paymentSlipEdit',
        ]

        return new Promise((resolve, reject) => {
          if (excluding.includes(router.currentRoute.name)) {
            resolve()
            return
          }

          // 过滤筛选项中的日期范围类型
          const dateRangeForm = formConfig
            .filter((item) => item.type === 'date' && item?.attrs?.type === 'daterange')
            .map((form) => form.model)

          if (dateRangeForm.length) {
            // 创建一个校验用的数组
            const checkArray = dateRangeForm.map((model) => formParams[model]?.toString())
            const valid = checkArray.some((item) => item)

            // 查询时判断不全为空即可
            if (!valid) {
              MessageBox.confirm(i18n.t('请至少选择一项时间范围'), i18n.t('提示'), {
                confirmButtonText: i18n.t('确定[ENTER]'),
                showCancelButton: false,
                type: 'warning',
              })
              reject()
              return
            }
          }

          resolve()
        })
      },
    },
  })
  /** MgPrint */
  Vue.use(MGPRINT)

  /* 全局消息弹框 */
  Vue.prototype.$mgMessage = function (message, type) {
    Message({
      offset: 60,
      showClose: true,
      message: message,
      type: type,
      dangerouslyUseHTMLString: true,
      duration: 3000,
    })
  }

  /* 全局 mgConfirm */
  Vue.prototype.$mgConfirm = function (content, title, options) {
    const h = this.$createElement ? this.$createElement : new Vue({}).$createElement
    return MessageBox.confirm(content, '-', {
      confirmButtonText: i18n.t('确定[ENTER]'),
      cancelButtonText: i18n.t('取消[ESC]'),
      showCancelButton: true,
      type: 'warning',
      customClass: 'el-message-box__no-title',
      closeOnClickModal: false,
      closeOnPressEscape: true,
      message: h(MessageBoxContent, {
        props: {
          title,
          content,
          dangerouslyUseHTMLString: options?.dangerouslyUseHTMLString,
        },
      }),
      ...options,
    })
  }

  /* 全局 mgAlert */
  Vue.prototype.$mgAlert = function (content, title, options) {
    return MessageBox.confirm(content, title, {
      confirmButtonText: i18n.t('确定[ENTER]'),
      cancelButtonText: i18n.t('取消[ESC]'),
      closeOnClickModal: false,
      ...options,
    })
  }

  /**
   * 编辑的页面确认关闭弹框
   * @param {String} routeName 路由名称
   */
  Vue.prototype.$mgCloseEditPageConfirm = function (routeName) {
    const changePages = this.$store.getters['tabsBar/changePages']
    if (changePages.includes(routeName)) {
      return this.$mgConfirm(i18n.t('当前页面正在编辑，确认关闭?'))
    }
  }

  /**
   * 全局格式化
   */
  Vue.prototype.$format = {
    /**
     * 千分法金额格式化
     * @param {number} value
     * @returns
     */
    amount: (value) => {
      if (!value) return value
      return value.toString().replace(/\d+/, (n) => {
        return n.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      })
    },
    /**
     * 【日期】格式化
     * @param {*} date 有效日期数值
     * @param {*} format 默认 YYYY-MM-DD
     * @returns
     */
    date(date, format = 'YYYY-MM-DD') {
      if (!date) return '--'
      return dayjs(date).format(format)
    },
    /**
     * 【日期+时间】格式化
     * @param {*} date 有效日期数值
     * @param {*} format 默认 YYYY-MM-DD HH:mm:ss
     * @returns
     */
    datetime(date, format = 'YYYY-MM-DD HH:mm:ss') {
      if (!date) return '--'
      return dayjs(date).format(format)
    },
    /**
     * 格式化表格【日期】列(YYYY-MM-DD)
     * @param {*} row
     * @param {*} column
     * @param {*} value
     * @returns
     */
    dateColumn(row, column, value) {
      if (!value) return '--'
      return dayjs(value).format('YYYY-MM-DD')
    },
    /**
     * 格式化表格【日期+时间】列(YYYY-MM-DD HH:mm:ss)
     * @param {} row
     * @param {*} column
     * @param {*} value
     * @returns
     */
    datetimeColumn(row, column, value) {
      if (!value) return '--'
      return dayjs(Number(value)).format('YYYY-MM-DD HH:mm:ss')
    },
  }

  // dayjs 通过 ProvidePlugin 全局引入
  Vue.prototype.dayjs = dayjs
  window.$dayjs = dayjs
  // BN 通过 ProvidePlugin 全局引入
  Vue.prototype.BN = BN
  // 全局 EventBus
  Vue.prototype.$GlobalEventBus = EventBus
}

if (typeof window !== void 0 && window.Vue) {
  install(window.Vue)
}

export default install
