import { customerReceivables, customerInfoFull } from '@/api/basic/customerApi'
import { accountCleanPlanLogList } from '@/api/receivable/cleanRecordQuery'
import i18n from '@/locale'
import Vue from 'vue'

const actions = {
  async ACT_CHECK_WHOLESALE_ORDER_TRANSFER(_, { customerCode, currencyCode, totalAskAmt, totalDeliveredAmt }) {
    let loading = null
    loading = Vue.prototype.$loading({ text: i18n.t('加急处理中') })

    const { data: customerInfo } = await customerInfoFull({ customerCode })

    const { data: customerReceivable } = await customerReceivables({ customerCode, currencyCode })

    // 台账
    const ledger = customerReceivable[0]?.billSummaryItems[0]
    // 授信
    const credit = customerReceivable[0]?.creditSummaryItems[0]

    // 授信额度 = “授信总额度” - “授信总锁定额度” - “新累计欠款金额（当前欠款）”
    const creditLimit = Vue.prototype.BN.minus(
      credit?.totalCreditAmt ?? 0,
      credit?.totalCreditLockAmt ?? 0,
      ledger?.sumNewBalanceAmt ?? 0
    ).toNumber()

     // 批发订单未发金额 = 批发订单要货金额 - 批发订单已发金额
    const underwayAmt = Vue.prototype.BN.minus(totalAskAmt, totalDeliveredAmt).toNumber()

    // 管控授信 且 可用额度 - 批发订单未发金额 <= 0
    if (customerInfo[0].ifCreditLimit && Vue.prototype.BN.minus(creditLimit, underwayAmt).toNumber() <= 0) {
      Vue.nextTick(() => {
        loading && loading.close()
        loading = null
      })
      await reverseTip({ key: '客户额度不足，剩余{amt}元，是否继续转单', values: { amt: creditLimit } })
    }

    if (loading === null) loading = Vue.prototype.$loading({ text: i18n.t('加急处理中') })

    const { data: customerReceiveAccounts } = await accountCleanPlanLogList({ customerCode })

    // 客户欠款结余状态 若为“已停货”，则弹窗确认
    if (customerReceiveAccounts[0]?.balanceStatus === 'STOP_SEND') {
      Vue.nextTick(() => {
        loading && loading.close()
        loading = null
      })
      await reverseTip('客户已停货，是否继续转单')
    }

    // 客户是否被禁用
    if (customerInfo[0]?.customerStatus !== 'NORMAL') {
      Vue.nextTick(() => {
        loading && loading.close()
        loading = null
      })
      await reverseTip('客户已禁用，请先启用！', {
        closeOnPressEscape: false,
        showClose: false,
        showCancelButton: false,
        confirmButtonText: i18n.t('确认[ENTER]'),
      })
    }

    Vue.nextTick(() => {
      loading && loading.close()
      loading = null
    })
  },
}

function reverseTip(msg, options = {}) {
  return new Promise((resolve, reject) => {
    Vue.prototype
      .$mgConfirm(typeof msg === 'string' ? i18n.t(msg) : i18n.t(msg?.key, msg?.values ?? {}), i18n.t('提示'), {
        ...{
          confirmButtonText: i18n.t('取消转单[ENTER]'),
          cancelButtonText: i18n.t('继续转单[ESC]'),
        },
        ...options,
      })
      .then(() => {
        reject('cancel')
      })
      .catch(() => {
        resolve()
      })
  })
}

export default { actions }
