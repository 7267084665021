export default {
  inserted(el) {
    const input = el.querySelector('input')
    if (input) {
      input.addEventListener('focus', () => {
        input.select()
      })
    }
  },
}
