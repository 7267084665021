import request from '@/utils/request'

// 供应商新增/编辑
export function supplierUpdata(data) {
  return request({
    url: '/mg/daohang-basic-api/supplier',
    method: 'put',
    loading: true,
    data,
  })
}

// 供应商分页列表
export function supplierPage(data) {
  return request({
    url: '/mg/daohang-basic-api/supplier/page',
    method: 'post',
    data,
  })
}
// 供应商详情
export function getSupplierById(id) {
  return request({
    url: `/mg/daohang-basic-api/supplier/${id}`,
    method: 'get',
    loading: true,
  })
}
// 供应商批量删除
export function supplierBatchDelete(data) {
  return request({
    url: `/mg/daohang-basic-api/supplier`,
    method: 'DELETE',
    loading: true,
    data,
  })
}

export function supplierList(data) {
  return request({
    url: '/mg/daohang-basic-api/supplier/list',
    method: 'post',
    data,
  })
}
