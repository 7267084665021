const state = () => ({
  // 用于标记当前接口查询是否带 * 号经营组织
  customerJournalDetail: {},
})

const mutations = {
  setData(state, data) {
    state.customerJournalDetail = data
  },
}

const actions = {
  async fetch_data_detail({ commit }, data) {
    commit(`setData`, data)
  },
}

export default { state, mutations, actions }
