import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './config/permission'
import './plugins'
import './styles/index.scss'
import i18n from './locale/index'
import { projectName } from '@/config'
LocalForage.config({
  name: projectName,
  storeName: 'dataStore',
  description: `${projectName}本地存储数据库`,
})
Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  // 处理点击弹框取消按钮时，控制台报错问题
  if (!(err instanceof Error) && err === 'cancel') {
    console.log(info)
    return
  }
  console.error(err)
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
