import { currencyList, getCurrencyCode, operationCurrencyList } from '@/api/basic/currency'
const state = () => ({
  // 货币表
  currencyList: {
    ENABLE: [],
    DISABLE: [],
  },

  // 经营货币配置表
  operationCurrency: [],
})

const mutations = {
  SET_CURRENCY_LIST(state, { list, status }) {
    state.currencyList[status] = list
  },
  SET_OPERATION_CURRENCY(state, { list }) {
    state.operationCurrency = list
  },
}

const actions = {
  /**
   * 货币列表
   * this.$store.dispatch('currency/FETCH_CURRENCY_LIST', { params: { status: 'ENABLE' } })
   * @param {*} payload
   * @returns
   */
  async FETCH_CURRENCY_LIST({ commit, state }, payload = '') {
    const { status } = payload?.params
    // 局限于一般场景下获取启用或者禁用数据的列表接口
    // 如果存在则不再发起请求
    if (state.currencyList[status].length > 0) return { data: state.currencyList[status] }
    // 发起 http 请求
    const { data: list } = await currencyList(payload?.params)
    // mutation
    commit(`SET_CURRENCY_LIST`, { list, status })
    return { data: list }
  },

  async FETCH_CURRENCY_CODE(_, code) {
    // 局限于一般场景下获取启用或者禁用数据的列表接口
    const { data } = await getCurrencyCode(code)
    // mutation
    return data
  },

  /**
   * 获取经营货币配置表数据默认查询本位币
   * @param {*} param0
   * @param {*} payload
   * @returns
   */
  async FETCH_OPERATION_CURRENCY({ commit, state }, payload = '') {
    if (state.operationCurrency.length > 0) return { data: state.operationCurrency }
    const params = { ...(payload.params ?? {}) }
    const { data: list } = await operationCurrencyList(params)
    commit(`SET_OPERATION_CURRENCY`, { list })
    return { data: list }
  },
}

export default { state, mutations, actions }
