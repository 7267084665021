import i18n from '@/locale'
/**
 * http 状态码
 */
export enum HttpStatusCode {
  /**
   * 无权限
   */
  UNAUTHORIZED = 401,
  /**
   * 登录失效
   */
  PAYMENT_REQUIRED = 402,
  /**
   * 404
   */
  NOT_FOUND = 404,
  /**
   * 处接口返回200外的其他情况
   */
  NO_SUCCESS = 100,
  /**
   * 接口正常返回数据
   */
  SUCCESS = 200,
  /**
   * 从缓存返回数据
   */
  CACHE_SUCCESS = 600,
  /**
   * 错误响应
   */
  ERROR_RESPONSE = 500,
}

export enum GlobalEventType {
  /**
   * 打开快捷键
   */
  KEYBOARD_SHORTCUTS = 'KEYBOARD_SHORTCUTS',
  /**
   * 保存表单
   */
  SAVE = 'SAVE',
  /**
   * 提交表单
   */
  SUBMIT = 'SUBMIT',
  /**
   * 打印
   */
  PRINT = 'PRINT',
  /**
   * 切换标签页
   */
  SWITCH_TAB = 'SWITCH_TAB',
  /**
   * 新增
   */
  ADD = 'ADD',
  /**
   * 审核
   */
  AUDIT = 'AUDIT',
  /**
   * 入账
   */
  RECORDING = 'RECORDING',
  /**
   * 快速添加
   */
  FAST_ADD = 'FAST_ADD',
  /**
   * 清空明细
   */
  CLEAR_ITEMS = 'CLEAR_ITEMS',
  /**
   * 取消/返回
   */
  CANCEL = 'CANCEL',
  /**
   * 更换客户
   */
  CUSTOMER_CHANGE = 'CUSTOMER_CHANGE',
}

export enum OperationCode {
  /**
   * 保存
   */
  SAVE = 'SAVE',
  /**
   * 审核
   */
  AUDIT = 'REVIEW',

  /**
   * 入账
   */
  RECORDING = 'COMPLETE',

  /**
   * 反审核
   */
  REAUDIT = 'DECLINE',

  /**
   * 关闭
   */
  CLOSE = 'CLOSE',

  /**
   * 整单结案
   */
  CLOSURE_ORDER = 'SETTLE_CLOSE',

  /**
   * 开始拣货
   * */
  START_PICKING = 'START_PICKING',
}

/**
 * 单据类型
 */
export const BillType = {
  PURCHASE_RECEIVE_ORDER: i18n.t('采购验收单'),
  PURCHASE_RETURN_APPLY_ORDER: i18n.t('退货申请单'),
  PURCHASE_RETURN_ORDER: i18n.t('采购退货单'),
  COST_ADJUST_ORDER: i18n.t('成本调整单'),
  SALE_ORDER: i18n.t('批发销货单'),
  SALE_RETURN_ORDER: i18n.t('批发退货单'),
  MATERIAL_RECEIVE_ORDER: i18n.t('领用单'),
  STOCK_BREAKAGE_ORDER: i18n.t('盈亏单'),
  SYS_COST_ADJUST_ORDER: i18n.t('系统调整单'),
  WAREHOUSE_TRANSFER_ORDER: i18n.t('移仓单'),
  PURCHASE_ORDER: i18n.t('采购订单'),
  DISTRIBUTION_REQUEST_ORDER: i18n.t('补货请求单'),
  VERBAL_ORDER: i18n.t('批发订单'),
  RATIO_AUDIT_ORDER: i18n.t('返点调整单'),
  PRICE_ADJUST_ORDER: i18n.t('基础售价调整单'),
  CUSTOMER_CREDIT_ORDER: i18n.t('授信单'),
  SETTLEMENT_ORDER: i18n.t('收款单'),
  DISCOUNT_ORDER: i18n.t('折扣折让单'),
  PRE_PAY_ORDER: i18n.t('预收款单'),
  RECONCILIATION_ORDER: i18n.t('应收对账单'),
  PAYMENT_ORDER: i18n.t('付款单'),
  SETTLEMENT_ORDER_PRE_PAY: i18n.t('使用预收款'),
  SETTLEMENT_ORDER_DISCOUNT: i18n.t('使用折扣折让'),
  STOCK_CHECK_TASK: i18n.t('盘点任务单'),
  STOCK_CHECK_ORDER: i18n.t('盘点单'),
  RECHECK_ORDER: i18n.t('复核单'),
  PRE_PAYMENT_ORDER: i18n.t('预付款单'),
  PAYMENT_ORDER_PRE_PAY: i18n.t('使用预付款'),
  PAYMENT_ORDER_DISCOUNT: i18n.t('本次折让'),
  CUSTOMER_REWARD_ORDER: i18n.t('积分调整单'),
  SKU_PROCESS_ORDER: i18n.t('商品加工单'),
  PAYMENT_DISCOUNT_ORDER: i18n.t('应付折让单'),
  PAYMENT_ORDER_USED_DISCOUNT: i18n.t('使用折让'),
}

/**
 * 通用单据状态枚举
 */
export const ReceiptStatus = {
  DRAFT: i18n.t('草稿'),
  REVIEWED: i18n.t('已审核'),
  COMPLETE: i18n.t('已入账'),
}
/**
 * 【批发销货单】业务来源
 */
export const SaleSourceMap = {
  IN_STORE: i18n.t("门店单"),
  TELESALES: i18n.t("电话单"),
  ONLINE_SELF: i18n.t("线上要货"),
  ONLINE_DELEGATE: i18n.t("线上代下单"),
}