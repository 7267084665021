// 按钮权限
export default {
  inserted(el, { arg, value }, vnode) {
    const buttons = value?.meta?.buttons || el.__vue__?.$route?.meta?.buttons
    if (!buttons?.find((btn) => btn.path === arg)) {
      el?.parentNode?.removeChild(el)
      if (vnode?.data.ref) {
        delete vnode.context.$refs[vnode.data.ref]
      }
    }
  },
}
