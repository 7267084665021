import { businessGroupListByUser, businessGroupList } from '@/api/organization/business'
const state = () => ({
  // 用于标记当前接口查询是否带 * 号经营组织
  ifAll: false,
  ifSourceType: false,
  businessOrganizationByUser: [],
  businessOrganizationList: [],
})

const mutations = {
  SET_BUSINESS_ORGANIZATION_BY_USER_LIST(state, { list }) {
    state.businessOrganizationByUser = list
  },
  SET_BUSINESS_ORGANIZATION_LIST(state, { list }) {
    state.businessOrganizationList = list
  },
  SET_IF_All(state, ifAll) {
    state.ifAll = ifAll
  },
  SET_IF_SOURCE_TYPE(state, ifSourceType) {
    state.ifSourceType = ifSourceType
  },
}

const actions = {
  /**
   * 当前登录人所属经营组织（员工与经营组织关联表）
   * 不存在 启用 禁用 状态
   * @param {*} payload
   * @param {*} payload.ifAll 是否查带 * 号的经营组织，默认 false
   * @returns
   */
  async FETCH_BUSINESS_ORGANIZATION_BY_USER_LIST({ commit, state }, payload) {
    payload = { ifAll: false, ...payload }
    if (state.ifAll !== Boolean(payload?.ifAll)) {
      const { data: list } = await businessGroupListByUser(payload)
      commit(`SET_BUSINESS_ORGANIZATION_BY_USER_LIST`, { list: list ?? [] })
      commit(`SET_IF_All`, Boolean(payload?.ifAll))
    }
    // 如果存在则不再发起请求
    if (state.businessOrganizationByUser?.length > 0) return { data: state.businessOrganizationByUser }
    // 发起 http 请求
    const { data: list } = await businessGroupListByUser(payload)
    // mutation
    commit(`SET_BUSINESS_ORGANIZATION_BY_USER_LIST`, { list: list ?? [] })
    return { data: state.businessOrganizationByUser }
  },
  /**
   * 当前登录人所属往来单位下的经营组织
   * @param {Object}  payload
   * @param {boolean} payload.ifSourceType - true 标识返回(* + 客户的) 经营组织, false 只返回客户的经营组织，默认 false
   * @returns
   */
  async FETCH_BUSINESS_ORGANIZATION_LIST({ commit, state }, payload) {
    payload = { ifSourceType: false, ...payload }
    if (state.ifSourceType !== Boolean(payload?.ifSourceType)) {
      const { data: list } = await businessGroupList({ status: 'ENABLE', ...payload })
      commit(`SET_BUSINESS_ORGANIZATION_LIST`, { list })
      commit(`SET_IF_SOURCE_TYPE`, Boolean(payload?.ifSourceType))
    }
    if (state.businessOrganizationList?.length > 0) return { data: state.businessOrganizationList }
    const { data: list } = await businessGroupList({ status: 'ENABLE', ...payload })
    commit(`SET_BUSINESS_ORGANIZATION_LIST`, { list })
    return { data: state.businessOrganizationList }
  },
}

export default { state, mutations, actions }
