import request from '@/utils/request'

//列表获取
export function noticeConstrain(data) {
  return request({
    url: '/mg/notice-api/notice/constrain',
    method: 'post',
    data,
    allowRepeat: true,
  })
}
export function noticeDetail(params) {
  return request({
    url: '/mg/notice-api/noticeDetail/businessDetail',
    method: 'get',
    params,
  })
}
export function noticeBusinessPage(data) {
  return request({
    url: '/mg/notice-api/notice/businessPage',
    method: 'post',
    data,
    allowRepeat: true,
  })
}
export function noticeRead(data) {
  return request({
    url: '/mg/notice-api/notice/read',
    method: 'post',
    data,
    allowRepeat: true,
  })
}

export function noticeRemind(data) {
  return request({
    url: '/mg/notice-api/notice/remind',
    method: 'post',
    data,
    allowRepeat: true,
  })
}
