import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-order-api/scanOrder/page',
    method: 'post',
    data,
  })
}

export function detail(id) {
  return request({
    url: `/mg/daohang-order-api/scanOrder/${id}`,
    method: 'get',
    loading: true,
  })
}

export function statusChange(data, loading = false) {
  return request({
    url: '/mg/daohang-order-api/scanOrder/statusChange',
    method: 'post',
    data,
    loading,
  })
}

export function del(data) {
  return request({
    url: '/mg/daohang-order-api/scanOrder',
    method: 'delete',
    data,
  })
}

export function exportItems(data) {
  return request({
    url: '/mg/daohang-order-api/scanOrderItem/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
