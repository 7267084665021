import request from '@/utils/request'

export function businessGroupPage(data) {
  data = { ifSourceType: false, ...data }
  return request({
    url: `/mg/daohang-basic-api/businessGroup/page`,
    method: 'post',
    data,
  })
}
/**
 * 当前登录人所属往来单位下的经营组织
 * @param {*} data
 * @returns
 */
export function businessGroupList(data) {
  data = { ifSourceType: false, ...data }
  return request({
    url: `/mg/daohang-basic-api/businessGroup/list`,
    method: 'post',
    data,
  })
}
export function businessGroupListByUser(data) {
  data = { ifAll: false, ...data }
  return request({
    url: `/mg/daohang-basic-api/employeeBusinessGroup/listByUser`,
    method: 'post',
    data,
  })
}
export function businessGroupUpdate(data) {
  return request({
    url: `/mg/daohang-basic-api/businessGroup`,
    method: 'put',
    loading: true,
    data,
  })
}
//根据partner 获取默认经营组织
export function getDefaultGroupByPartnerCode(code) {
  return request({
    url: `/mg/daohang-basic-api/businessGroup/queryDefaultGroupByPartnerCode/${code}`,
    method: 'get',
  })
}
