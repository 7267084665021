import request from '@/utils/request'

/**
 * 获取所有国家
 * 导航涉及到一部分非洲&印尼国家，数据库只维护这些国家数据
 * @returns
 */
export function countryList() {
  return request({
    url: '/mg/daohang-basic-api/dhComponentGeo/list/country',
    method: 'get',
  })
}

/**
 * 通过 code 获取下一级
 * @param {code} params
 * @returns
 */
export function childrenByCode(params) {
  return request({
    url: '/mg/daohang-basic-api/dhComponentGeo/list/subDistrict',
    method: 'get',
    params,
  })
}

export function countryTree() {
  return request({
    url: '/mg/daohang-basic-api/dhComponentGeo/list/worldAll',
    method: 'get',
  })
}
