import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.108.68' : '/'
//折扣折让分页
export function discountOrderPage(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/page`,
    method: 'post',
    data,
  })
}
//折扣折让新增
export function discountOrderSaveOrUpdate(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/saveOrUpdate`,
    method: 'put',
    loading: true,
    data,
  })
}
//折扣折让详情
export function getDiscountOrderById(id) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
//折扣折让类型列表
export function getDiscountTypeList(params) {
  return request({
    url: `/mg/daohang-order-api/discountType/list`,
    method: 'get',
    params,
  })
}
//折扣折让单删除
export function discountOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
//折扣折让单状态变更
export function discountOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/statusChange`,
    loading: true,
    method: 'post',
    data,
  })
}
//折扣折让单列表导出
export function discountOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//折扣折让单明细导出
export function discountOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrderItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//折扣折让单状态变更
export function discountOrderSum(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/sum`,
    method: 'post',
    data,
  })
}
//折扣折让单批量入账
export function discountOrderBatchComplete(data) {
  return request({
    url: `/mg/daohang-order-api/discountOrder/batchComplete`,
    method: 'post',
    data,
  })
}
