import request from '@/utils/request'

// 列表
export function getPaymentSlipList(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/page`,
    method: 'post',
    data,
  })
}

//新增
export function createPaymentSlipList(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/saveOrUpdate`,
    method: 'put',
    loading: true,
    data,
  })
}
//详情
export function getPaymentOrderById(id) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
//审核
export function paymentOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}
//删除
export function deletePaymentOrder(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
//导出
export function paymentOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//合计
export function paymentOrderSum(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/sum`,
    method: 'post',
    data,
  })
}

//合计
export function existsDocument(params) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrder/existsDocument`,
    method: 'get',
    params,
  })
}
//查询供应商应付折让金额
export function querySupplierRemainDiscountAmt(data) {
  return request({
    url: `/mg/daohang-pay-api/supplierBillSummary/list`,
    method: 'post',
    data,
  })
}

// 付款单单据明细导出
export function exportSettlementOrderBillItemExcel(data) {
  return request({
    url: `/mg/daohang-pay-api/paymentOrderBillItem/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}
