<template>
  <div class="mg-message-box-content">
    <strong class="mg-message-box-content__title">{{ title }}</strong>
    <div class="mg-message-box-content__body" v-if="dangerouslyUseHTMLString" v-html="content"></div>
    <div class="mg-message-box-content__body" v-else>{{ content }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      content: String,
      dangerouslyUseHTMLString: Boolean,
    },
  }
</script>

<style lang="scss" scoped>
  .mg-message-box-content {
    font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, Hiragino Sans GB,
      Microsoft Yahei, STHeiti, SimSun, sans-serifsans-serif;
    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #252a3d;
      display: block;
      margin-bottom: 10px;
    }
    &__body {
      line-height: 22px;
      font-size: 14px;
      word-break: break-word;
      max-height: 60vh;
      overflow: hidden;
      overflow-y: auto;
    }
  }
</style>
