import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.108.68' : '/'
// 批发销货单列表
export function getSaleOrderList(data) {
  return request({
    url: `/mg/daohang-order-api/saleOrder/page`,
    method: 'post',
    data,
  })
}
// 批发销货详情
export function getSaleOrderDetail(id) {
  return request({
    url: `/mg/daohang-order-api/saleOrder/edit/${id}`,
    method: 'get',
    loading: true,
  })
}
//列表查询商品信息
export function goodsCachelist(data) {
  return request({
    url: `/mg/daohang-product-api/goodsCache/list`,
    method: 'post',
    loading: true,
    data,
  })
}

//列表查询次级明细
export function getSaleOrderItemDetailList(params) {
  return request({
    url: `/mg/daohang-order-api/saleOrderItemDetail/list`,
    method: 'get',
    loading: true,
    params,
  })
}
//列表查询次级明细
export function saleOrderItemDetailList(data) {
  return request({
    url: `/mg/daohang-order-api/saleOrderItemDetail/listByIds`,
    method: 'post',
    loading: true,
    data,
  })
}

// 根据经营组织单条查询业务配置
export function sysBusinessConfigFindByQuery(data) {
  return request({
    url: `/mg/daohang-basic-api/sysBusinessConfig/findByQuery`,
    method: 'post',
    loading: true,
    data,
  })
}

//批发退货单分页查询
export function saleReturnOrderPage(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/page`,
    method: 'post',
    data,
  })
}
//批发退货单保存
export function saleReturnOrderSaveOrUpdate(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/saveOrUpdate`,
    method: 'put',
    loading: true,
    data,
  })
}
//批发退货单删除
export function saleReturnOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}

//批发退货单状态变更
export function saleReturnOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}

export function saleReturnOrderSum(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/sum`,
    method: 'post',
    data,
  })
}

//包装换算
export function packageConvert(data) {
  return request({
    url: `/mg/daohang-product-api/goodsCache/packageConvert`,
    method: 'post',
    data,
  })
}

//批发退货单详情
export function getSaleReturnOrderById(id) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
//批发退货单详情
export function settlementOrderCompleteAdd(data) {
  return request({
    url: `/mg/daohang-order-api/settlementOrder/completeAdd`,
    method: 'post',
    data,
    loading: true,
  })
}

//批发退货单列表导出
export function saleReturnOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/exportExcel`,
    method: 'post',
    loading: true,
    responseType: 'blob',
    data,
  })
}
//批发退货单明细导出
export function saleReturnOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrderItem/exportExcel`,
    method: 'post',
    loading: true,
    responseType: 'blob',
    data,
  })
}

//批发退货单明细sku交易记录
export function saleReturnOrderItemTradingRecordPage(data) {
  return request({
    url: `/mg/daohang-order-api/tradingRecord/page`,
    method: 'post',
    data,
  })
}

// 批发退货单修改备注
export function saleReturnOrderEditRemark(data) {
  return request({
    url: `/mg/daohang-order-api/saleReturnOrder/editRemark`,
    method: 'post',
    loading: true,
    data,
  })
}
