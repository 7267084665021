/**
 * 盈亏单相关打印变量
 */
const paymentSlipTempOptions = [
    {
        label: '付款单',
        value: 'all',
        children: [{ label: '付款单', value: 1 }],
    },
]
const paymentSlipTempJsonOptions = {
    all: {
        1:{
            "panels": [
                {
                    "index": 0,
                    "name": 1,
                    "height": 141,
                    "width": 210,
                    "paperHeader": 12,
                    "paperFooter": 382.5,
                    "printElements": [
                        {
                            "options": {
                                "left": 118.5,
                                "top": 18,
                                "height": 17,
                                "width": 343.5,
                                "right": 462.0000228881836,
                                "title": "XX配送中心付款单",
                                "bottom": 34.25,
                                "hCenter": 25.75,
                                "vCenter": 290.2500228881836,
                                "fontSize": 16.5,
                                "hideTitle": true,
                                "textAlign": "center",
                                "fontWeight": "700",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "标题",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 13.5,
                                "top": 37.5,
                                "height": 15,
                                "width": 214.5,
                                "field": "billNo",
                                "right": 183,
                                "title": "付款单号",
                                "bottom": 42,
                                "hCenter": 34.5,
                                "vCenter": 98.25,
                                "testData": "01-大仓",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 267,
                                "top": 37.5,
                                "height": 15,
                                "width": 190.5,
                                "field": "printTime",
                                "right": 400.5,
                                "title": "打印时间",
                                "bottom": 51.75,
                                "hCenter": 44.25,
                                "vCenter": 305.25,
                                "testData": "2023-04-06",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 13.5,
                                "top": 57,
                                "height": 15,
                                "width": 316.5,
                                "field": "supplierName",
                                "right": 184.5,
                                "title": "供应商名称",
                                "bottom": 61.5,
                                "hCenter": 54,
                                "vCenter": 99,
                                "testData": "CGSS20230327000054",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 13.5,
                                "top": 76.5,
                                "height": 15,
                                "width": 552,
                                "field": "remark",
                                "right": 369.75,
                                "title": "备注",
                                "bottom": 91.5,
                                "hCenter": 84,
                                "vCenter": 192.75,
                                "testData": "0.5",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 12.5,
                                "top": 97.5,
                                "height": 37.5,
                                "width": 553.5,
                                "field": "printLists",
                                "right": 568.5,
                                "bottom": 134.49609375,
                                "hCenter": 115.74609375,
                                "vCenter": 291.75,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "groupFooterFormatter": "function(t,e){return\"这里自定义统计脚信息\";}",
                                "tableHeaderBackground": "#ffffff",
                                "columns": [
                                    [
                                        {
                                            "width": 87.81225,
                                            "title": "发生日期",
                                            "field": "completeTime",
                                            "checked": true,
                                            "columnId": "completeTime",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "formatter2": "function(value,row,index, options){ return window.$dayjs(row.completeTime).format('YYYY-MM-DD') }",
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": true,
                                            "tableSummary": ""
                                        },
                                        {
                                            "width": 131.19899999999998,
                                            "title": "业务单号",
                                            "field": "sourceNo",
                                            "checked": true,
                                            "columnId": "sourceNo",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "align": "left",
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": true,
                                            "tableSummaryText": "合计",
                                            "tableSummary": ""
                                        },
                                        {
                                            "width": 76.02375000000005,
                                            "title": "付款金额",
                                            "field": "remainAmt",
                                            "checked": true,
                                            "columnId": "remainAmt",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": false,
                                            "tableSummary": "",
                                            "tableSummaryAlign": "right"
                                        },
                                        {
                                            "width": 258.465,
                                            "title": "备注",
                                            "field": "remark",
                                            "checked": true,
                                            "columnId": "remark",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": false,
                                            "tableSummary": "",
                                            "tableSummaryAlign": "right"
                                        },
                                        {
                                            "width": 100,
                                            "title": "延期应收金额",
                                            "field": "receivableAmt",
                                            "checked": false,
                                            "columnId": "receivableAmt",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": "30",
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0,
                                            "tableSummaryTitle": false,
                                            "tableSummary": "sum",
                                            "tableSummaryAlign": "right",
                                            "tableSummaryNumFormat": "2"
                                        },
                                        {
                                            "width": 100,
                                            "title": "含税进价",
                                            "field": "costAmt",
                                            "checked": false,
                                            "columnId": "costAmt",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": 30,
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0
                                        },
                                        {
                                            "width": 100,
                                            "title": "姓名",
                                            "field": "name",
                                            "checked": false,
                                            "columnId": "name",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "align": "left",
                                            "tableColumnHeight": 30,
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0
                                        },
                                        {
                                            "width": 100,
                                            "title": "性别",
                                            "field": "gender",
                                            "checked": false,
                                            "columnId": "gender",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": 30,
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0
                                        },
                                        {
                                            "width": 100,
                                            "title": "销售数量",
                                            "field": "count",
                                            "checked": false,
                                            "columnId": "count",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": 30,
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0
                                        },
                                        {
                                            "width": 100,
                                            "title": "销售金额",
                                            "field": "amount",
                                            "checked": false,
                                            "columnId": "amount",
                                            "fixed": false,
                                            "rowspan": 1,
                                            "colspan": 1,
                                            "tableColumnHeight": 30,
                                            "tableTextType": "text",
                                            "tableBarcodeMode": "CODE128A",
                                            "tableQRCodeLevel": 0
                                        }
                                    ]
                                ]
                            },
                            "printElementType": {
                                "title": "表格",
                                "type": "table",
                                "editable": true,
                                "columnDisplayEditable": true,
                                "columnDisplayIndexEditable": true,
                                "columnTitleEditable": true,
                                "columnResizable": true,
                                "columnAlignEditable": true,
                                "isEnableEditField": true,
                                "isEnableContextMenu": true,
                                "isEnableInsertRow": true,
                                "isEnableDeleteRow": true,
                                "isEnableInsertColumn": true,
                                "isEnableDeleteColumn": true,
                                "isEnableMergeCell": true
                            }
                        },
                        {
                            "options": {
                                "left": 62.5,
                                "top": 140,
                                "height": 18,
                                "width": 139.5,
                                "field": "settlementTotal",
                                "right": 214.998046875,
                                "title": "本次结算金额合计",
                                "bottom": 157.248046875,
                                "hCenter": 148.248046875,
                                "vCenter": 145.248046875,
                                "fontSize": 11,
                                "fontWeight": "bold",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 392.5,
                                "top": 140,
                                "height": 18,
                                "width": 135,
                                "field": "realPaymentAmt",
                                "right": 528,
                                "title": "本次实付金额",
                                "bottom": 158.748046875,
                                "hCenter": 149.748046875,
                                "vCenter": 460.5,
                                "fontSize": 11,
                                "fontWeight": "bold",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 232.5,
                                "top": 140,
                                "height": 18,
                                "width": 139.5,
                                "field": "usedPrepaymentAmt",
                                "right": 372,
                                "title": "使用预付款：¥0",
                                "bottom": 157.998046875,
                                "hCenter": 148.998046875,
                                "vCenter": 302.25,
                                "fontSize": 11,
                                "formatter": "function(value,options){return '¥'+(options == 0 ? '0' : window.$MGBN.round(options,2).toNumber())}",
                                "fontWeight": "bold",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 438.5,
                                "top": 169.5,
                                "height": 9.75,
                                "width": 120,
                                "right": 507.75,
                                "title": "传真：",
                                "bottom": 182.9999885559082,
                                "hCenter": 178.1249885559082,
                                "vCenter": 447.75,
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 15.5,
                                "top": 169.5,
                                "height": 9.75,
                                "width": 120,
                                "right": 136.5,
                                "title": "电话：",
                                "bottom": 183.7499885559082,
                                "hCenter": 178.8749885559082,
                                "vCenter": 76.5,
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 17,
                                "top": 184.5,
                                "height": 15,
                                "width": 114,
                                "right": 123,
                                "title": "客户确认：",
                                "bottom": 214.5000114440918,
                                "hCenter": 207.0000114440918,
                                "vCenter": 66,
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false,
                                "textContentVerticalAlign": "middle"
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        },
                        {
                            "options": {
                                "left": 432.5,
                                "top": 187.5,
                                "height": 12,
                                "width": 120,
                                "right": 549.0000228881836,
                                "title": "XX配送中心",
                                "bottom": 215.2500228881836,
                                "hCenter": 203.2500228881836,
                                "vCenter": 489.0000228881836,
                                "textAlign": "center",
                                "qrCodeLevel": 0,
                                "coordinateSync": false,
                                "widthHeightSync": false
                            },
                            "printElementType": {
                                "title": "文本",
                                "type": "text"
                            }
                        }
                    ],
                    "paperNumberLeft": 553.5,
                    "paperNumberTop": 18,
                    "paperNumberDisabled": true,
                    "paperNumberContinue": true,
                    "watermarkOptions": {}
                }
            ]
        }
    },
}

export default {
    // 预收款单 -- 不同配送中心打印模板名
    paymentSlipTempOptions,
    // 预收款单 -- 不同分公司打印模板名json
    paymentSlipTempJsonOptions,
}
