import { mapState } from 'vuex'
const langArray = ['zh-CN', 'en-US', 'id']
export const storeState = {
  computed: {
    ...mapState({
      customer: (state) => state.customer,
      business: (state) => state.business,
      stock: (state) => state.stock,
      currency: (state) => state.currency,
      employee: (state) => state.employee,
      supply: (state) => state.supply,
      payment: (state) => state.payment,
      category: (state) => state.category,
    }),
  },
  methods: {
    setLabelWidthByLang(labelWidths) {
      const index = langArray.findIndex((lang) => lang === this.$store.state.sys.lang)
      if (labelWidths[index] === void 0) return 'auto'
      return labelWidths[index]
    },
  },
}
