// @ts-nocheck

import Vue from 'vue'
// 领用单
import { receiveOrderPage } from '@/api/stock/receive'
// 预收款单
import { prePayOrderPage } from '@/api/receivable/prepay'
// 采购入库单
import { getPurchaseReceiveOrderPage } from '@/api/purchase/entry'
// 退货申请单
import { getReturnApplicationList } from '@/api/receivable/returnApplication'
// 采购退货单
import { purchaseReturnOrderPage } from '@/api/purchase/purchaseReturn'
// 批发销货单 -- 这里用别名是为了避免和批发订单的page重名, 导致引用错误
import * as wholesaleSales from '@/api/wholesale/sales'
// 批发退货单
import { saleReturnOrderPage } from '@/api/wholesale/return'
// 收款单
import { getReceiptVoucherList } from '@/api/receivable/receiptVoucherApi'
// 盈亏单
import { stockBreakageOrderPage } from '@/api/stock/profitAndLoss'
// 批发订单 -- 理由同[批发销货单]
import * as wholesaleOrders from '@/api/wholesale/orders'
// 移仓单
import { getWarehouseTransferOrderPage } from '@/api/stock/warehouseTransferOrder'
// 盘点任务单
import { stockCheckTaskPage } from '@/api/stock/inventoryTask'
// 门店补货单
import { getReplenishmentList } from '@/api/purchase/replenishmentListApi'
// 返点调整单
import { getReturnPointList } from '@/api/rebate/returnPointApi'
// 基础售价调整
import { priceAdjustOrderPage } from '@/api/price/adjust'
// 积分管理 -- 积分调整单
import { customerRewardOrderPage } from '@/api/wholesale/customerReward'

// 采购订单
import { getPurchaseOrderPage } from '@/api/purchase/purchaseOrder'
// 授信单
import { customerCreditOrderPage } from '@/api/credit/credit'
// 折扣折让单
import { discountOrderPage } from '@/api/receivable/discount'
// 付款单
import { getPaymentSlipList } from '@/api/prepay/paymentSlip'
// 预付款单
import { getPrePaymentOrderPage } from '@/api/prepay/prepaymentApi'
// 盘点单
import { getInventoryList } from '@/api/stock/inventoryListApi'
// 成本调整单
import { getCostAdjustmentSheetList } from '@/api/stock/costAdjustmentSheetApi'
// 复核单
import { handlePrintGetBoxNos } from '@/api/set/printTemplateApi'
// 应收对账单 -- 理由同[批发销货单]
import * as accountReceivable from '@/api/receivable/accountReceivable'
// 通用扫描单
import * as scanForm from '@/api/stock/scanForm'

// 默认原始模板，为了提供给mg-ui/mg-print.md查看使用
const templateDefalutJsonData = {
  panels: [
    {
      index: 0,
      name: 1,
      height: 297,
      width: 210,
      paperHeader: 49.5,
      paperFooter: 780,
      printElements: [
        {
          options: {
            left: 175.5,
            top: 10.5,
            height: 27,
            width: 259,
            title: 'Mg晨光自定义模块打印插件',
            fontSize: 19,
            fontWeight: '600',
            textAlign: 'center',
            lineHeight: 26,
            coordinateSync: true,
            widthHeightSync: true,
            draggable: false,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 448.5,
            top: 13.5,
            height: 19.5,
            width: 105,
            title: '123456789',
            fontFamily: '微软雅黑',
            textAlign: 'center',
            textType: 'barcode',
            right: 552.75,
            bottom: 32.99999713897705,
            vCenter: 500.25,
            hCenter: 23.24999713897705,
            coordinateSync: false,
            widthHeightSync: false,
            hideTitle: true,
            qrCodeLevel: 0,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 13.5,
            top: 33,
            height: 13,
            width: 52,
            title: '页眉线',
            textAlign: 'center',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 13.5,
            top: 54,
            height: 39,
            width: 276,
            title:
              '二维码以及条形码均采用svg格式打印。不同打印机打印不会造成失真。图片打印：不同DPI打印可能会导致失真，',
            fontFamily: '微软雅黑',
            textAlign: 'center',
            lineHeight: 18,
            right: 288.75,
            bottom: 93.00000286102295,
            vCenter: 150.75,
            hCenter: 73.50000286102295,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 315,
            top: 55.5,
            height: 13,
            width: 120,
            title: '姓名',
            field: 'name',
            testData: '晨光',
            color: '#f00808',
            textDecoration: 'underline',
            textAlign: 'center',
            fields: [
              {
                text: 'id',
                field: 'id',
              },
              {
                text: '姓名',
                field: 'name',
              },
              {
                text: '性别',
                field: 'gender',
              },
              {
                text: '数量',
                field: 'count',
              },
            ],
            coordinateSync: false,
            widthHeightSync: false,
            qrCodeLevel: 0,
          },
          printElementType: {
            title: '文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 529.5,
            top: 58.5,
            height: 43,
            width: 51,
            title: '123456789',
            textType: 'qrcode',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 100.5,
            height: 19,
            width: 213,
            title: '所有打印元素都可已拖拽的方式来改变元素大小',
            fontFamily: '微软雅黑',
            textAlign: 'center',
            lineHeight: 18,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 418.5,
            top: 126,
            height: 13,
            width: 164,
            title: '可以配置各属性的默认值',
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 100.5,
            top: 127.5,
            height: 13,
            width: 238,
            title: '单击元素，右侧可自定义元素属性',
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 127.5,
            height: 13,
            width: 51,
            title: '横线',
            textAlign: 'center',
            right: 60.75,
            bottom: 139.75000286102295,
            vCenter: 35.25,
            hCenter: 133.25000286102295,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 148.5,
            height: 10,
            width: 571.5,
            right: 488.25,
            bottom: 158.50000286102295,
            vCenter: 250.5,
            hCenter: 153.50000286102295,
          },
          printElementType: {
            title: '横线',
            type: 'hline',
          },
        },
        {
          options: {
            left: 241.5,
            top: 153,
            height: 32,
            width: 342,
            title:
              '自定义表格：用户可左键选中表头，右键查看可操作项，操作类似Excel，双击表头单元格可进行编辑。内容：title#field',
            fontFamily: '微软雅黑',
            textAlign: 'center',
            lineHeight: 15,
            right: 586.5,
            bottom: 183.5000114440918,
            vCenter: 415.5,
            hCenter: 167.5000114440918,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 126,
            top: 159,
            height: 13,
            width: 94,
            title: '表头列大小可拖动',
            fontFamily: '微软雅黑',
            textAlign: 'center',
            right: 220,
            bottom: 171.2500057220459,
            vCenter: 173,
            hCenter: 164.7500057220459,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 19.5,
            top: 159,
            height: 13,
            width: 90,
            title: '红色区域可拖动',
            fontFamily: '微软雅黑',
            textAlign: 'center',
            right: 109.5,
            bottom: 169.7500057220459,
            vCenter: 64.5,
            hCenter: 163.2500057220459,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 184.5,
            height: 56,
            width: 570,
            field: 'table',
            tableFooterRepeat: '',
            fields: [],
            right: 523.5,
            bottom: 240.5000057220459,
            vCenter: 267.75,
            hCenter: 212.5000057220459,
            columns: [
              [
                {
                  width: 114,
                  checked: true,
                  fixed: false,
                  rowspan: 1,
                  colspan: 1,
                  tableColumnHeight: 30,
                  tableTextType: 'text',
                  tableBarcodeMode: 'CODE128A',
                  tableQRCodeLevel: 0,
                },
                {
                  width: 114,
                  title: '姓名',
                  field: 'name',
                  checked: true,
                  columnId: 'name',
                  fixed: false,
                  rowspan: 1,
                  colspan: 1,
                  align: 'center',
                  tableColumnHeight: 30,
                  tableTextType: 'text',
                  tableBarcodeMode: 'CODE128A',
                  tableQRCodeLevel: 0,
                  tableSummary: 'count',
                },
                {
                  width: 114,
                  title: '数量',
                  field: 'count',
                  checked: true,
                  columnId: 'count',
                  fixed: false,
                  rowspan: 1,
                  colspan: 1,
                  align: 'center',
                  tableColumnHeight: 30,
                  tableTextType: 'text',
                  tableBarcodeMode: 'CODE128A',
                  tableQRCodeLevel: 0,
                  tableSummary: 'sum',
                },
                {
                  width: 114,
                  checked: true,
                  fixed: false,
                  rowspan: 1,
                  colspan: 1,
                  tableColumnHeight: 30,
                  tableTextType: 'text',
                  tableBarcodeMode: 'CODE128A',
                  tableQRCodeLevel: 0,
                },
                {
                  width: 114,
                  checked: true,
                  fixed: false,
                  rowspan: 1,
                  colspan: 1,
                  tableColumnHeight: 30,
                  tableTextType: 'text',
                  tableBarcodeMode: 'CODE128A',
                  tableQRCodeLevel: 0,
                },
                {
                  width: 85.25,
                  title: '性别',
                  field: 'gender',
                  checked: false,
                  columnId: 'gender',
                  fixed: false,
                  rowspan: 1,
                  colspan: 1,
                  tableColumnHeight: 30,
                  tableTextType: 'text',
                  tableBarcodeMode: 'CODE128A',
                  tableQRCodeLevel: 0,
                },
              ],
            ],
          },
          printElementType: {
            title: '表格',
            type: 'table',
            editable: true,
            columnDisplayEditable: true,
            columnDisplayIndexEditable: true,
            columnTitleEditable: true,
            columnResizable: true,
            columnAlignEditable: true,
            isEnableEditField: true,
            isEnableContextMenu: true,
            isEnableInsertRow: true,
            isEnableDeleteRow: true,
            isEnableInsertColumn: true,
            isEnableDeleteColumn: true,
            isEnableMergeCell: true,
          },
        },
        {
          options: {
            left: 247.5,
            top: 268.5,
            height: 13,
            width: 346.5,
            title: '自定义模块：主要为开发人员设计，能够快速，简单，实现自己功能',
            textAlign: 'center',
            right: 643.5,
            bottom: 281.74375915527344,
            vCenter: 470.25,
            hCenter: 275.24375915527344,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 70.5,
            top: 303,
            height: 18,
            width: 79,
            title: '配置项表格',
            textAlign: 'center',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 247.5,
            top: 303,
            height: 38,
            width: 346.5,
            title: '配置模块：主要为客户使用，开发人员可以配置属性，字段，标题等，客户直接使用，配置模块请参考实例2',
            fontFamily: '微软雅黑',
            lineHeight: 15,
            textAlign: 'center',
            color: '#d93838',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 370.5,
            height: 13,
            width: 123,
            title: '长文本会自动分页',
            textAlign: 'center',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 402,
            height: 40,
            width: 511.5,
            field: 'longText',
            right: 522.75,
            bottom: 441.9999942779541,
            vCenter: 267,
            hCenter: 421.9999942779541,
          },
          printElementType: {
            title: '长文',
            type: 'longText',
          },
        },
        {
          options: {
            left: 483,
            top: 457.5,
            height: 100,
            width: 100,
            right: 582.25,
            bottom: 557.5,
            vCenter: 532.25,
            hCenter: 507.5,
          },
          printElementType: {
            title: '矩形',
            type: 'rect',
          },
        },
        {
          options: {
            left: 27,
            top: 462,
            height: 100,
            width: 10,
            right: 37,
            bottom: 562,
            vCenter: 32,
            hCenter: 512,
          },
          printElementType: {
            title: '竖线',
            type: 'vline',
          },
        },
        {
          options: {
            left: 127.5,
            top: 465,
            height: 13,
            width: 90,
            title: '竖线',
            textAlign: 'center',
            right: 238.5,
            bottom: 542.5000228881836,
            vCenter: 193.5,
            hCenter: 536.0000228881836,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 129,
            top: 517.5,
            height: 13,
            width: 120,
            title: '横线',
            textAlign: 'center',
            right: 331.5,
            bottom: 571.0000228881836,
            vCenter: 271.5,
            hCenter: 564.5000228881836,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 36,
            top: 600,
            height: 10,
            width: 277,
            right: 313.75,
            bottom: 610.0000228881836,
            vCenter: 175.25,
            hCenter: 605.0000228881836,
          },
          printElementType: {
            title: '横线',
            type: 'hline',
          },
        },
        {
          options: {
            left: 445.5,
            top: 621,
            height: 13,
            width: 101,
            title: '矩形',
            textAlign: 'center',
            right: 545.75,
            bottom: 634.0000228881836,
            vCenter: 495.25,
            hCenter: 627.5000228881836,
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 525,
            top: 784.5,
            height: 13,
            width: 63,
            title: '页尾线',
            textAlign: 'center',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 12,
            top: 786,
            height: 49,
            width: 49,
          },
          printElementType: {
            title: 'html',
            type: 'html',
          },
        },
        {
          options: {
            left: 72,
            top: 790.5,
            height: 13,
            width: 137,
            title: '红色原型是自动定义的Html',
            textAlign: 'center',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
        {
          options: {
            left: 327,
            top: 808.5,
            height: 13,
            width: 205,
            title: '页眉线已上。页尾下以下每页都会重复打印',
            textAlign: 'center',
          },
          printElementType: {
            title: '自定义文本',
            type: 'text',
          },
        },
      ],
      paperNumberLeft: 568.5,
      paperNumberTop: 28.5,
    },
  ],
}

/**
 * 打印相关的公共常量
 *
 * templateJsonOptions：
 *
 * label -- 单据类型label
 * value -- 单据类型value
 * billIdField -- 各单据列表的id字段名
 * billTypeField -- 各单据列表的单据号字段名
 * [......] -- 可以绑定columns属性需要展示的字段，比如：formatDateFields，billTypeField
 * queryParams -- 各单据列表接口查询入参
 * columns -- 各单据预览所需要展示的字段（打印模板配置--定制页预览弹窗）
 * listApi -- 各单据列表分页接口
 * isUseDefaultPreview -- 是否使用静态默认预览（可选值：true/false）
 */
const templateJsonOptions = [
  {
    label: '采购入库单',
    value: 'PURCHASE_RECEIVE_ORDER',
    billIdField: 'purchaseReceiveOrderId',
    billTypeField: 'billNo',
    formatDateFields: ['completeTime'],
    queryParams: {
      page: 1,
      size: 10,
      statusList: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '进货总额',
        prop: 'totalCostAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: getPurchaseReceiveOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '退货申请单',
    value: 'PURCHASE_RETURN_APPLY_ORDER',
    billIdField: 'purchaseReturnApplyOrderId',
    billTypeField: 'billNo',
    formatDateFields: ['createTime'],
    queryParams: {
      page: 1,
      size: 10,
      status: 'COMPLETE',
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '含税成本金额',
        prop: 'totalCostAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: getReturnApplicationList,
    isUseDefaultPreview: false,
  },
  {
    label: '采购退货单',
    value: 'PURCHASE_RETURN_ORDER',
    billIdField: 'purchaseReturnOrderId',
    billTypeField: 'billNo',
    formatDateFields: ['createTime'],
    queryParams: {
      page: 1,
      size: 10,
      statuses: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '含税进货金额',
        prop: 'totalCostAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: purchaseReturnOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '成本调整单',
    value: 'COST_ADJUST_ORDER',
    listApi: getCostAdjustmentSheetList,
    isUseDefaultPreview: true,
  },
  {
    label: '批发销货单',
    value: 'SALE_ORDER',
    billIdField: 'saleOrderId',
    billTypeField: 'billNo',
    barcodeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      statuses: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '批发总额',
        prop: 'totalSaleAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: wholesaleSales.page,
    isUseDefaultPreview: false,
  },
  {
    label: '批发退货单',
    value: 'SALE_RETURN_ORDER',
    billIdField: 'saleReturnOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      statuses: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '实退金额',
        prop: 'actualReturnAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: saleReturnOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '领用单',
    value: 'MATERIAL_RECEIVE_ORDER',
    billIdField: 'materialReceiveOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '含税进货金额',
        prop: 'totalCostAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: receiveOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '复核单',
    value: 'RECHECK_ORDER',
    billIdField: 'recheckOrderId',
    billTypeField: 'billNo',
    boxNoField: 'boxQty',
    billNoField: 'saleOrderNo',
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '实复数量(单件)',
        prop: 'recheckedQty',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    listApi: handlePrintGetBoxNos,
    isUseDefaultPreview: false,
  },
  {
    label: '盈亏单',
    value: 'STOCK_BREAKAGE_ORDER',
    billIdField: 'stockBreakageOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      statuses: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '盈亏进货金额',
        prop: 'totalRetailAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: stockBreakageOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '系统调整单',
    value: 'SYS_COST_ADJUST_ORDER',
    listApi: null,
    isUseDefaultPreview: true,
  },
  {
    label: '商品资料调整',
    value: 'GOODS_ADJUST',
    listApi: null,
    isUseDefaultPreview: true,
  },
  {
    label: '移仓单',
    value: 'WAREHOUSE_TRANSFER_ORDER',
    billIdField: 'warehouseTransferOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      statuses: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '含税进货金额',
        prop: 'totalCostAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: getWarehouseTransferOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '采购订单',
    value: 'PURCHASE_ORDER',
    listApi: getPurchaseOrderPage,
    isUseDefaultPreview: true,
  },
  {
    label: '补货请求单',
    value: 'DISTRIBUTION_REQUEST_ORDER',
    listApi: getReplenishmentList,
    isUseDefaultPreview: true,
  },
  {
    label: '批发订单',
    value: 'VERBAL_ORDER',
    billIdField: 'verbalOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '发货金额',
        prop: 'totalDeliveredAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: wholesaleOrders.page,
    isUseDefaultPreview: false,
  },
  {
    label: '返点调整单',
    value: 'RATIO_AUDIT_ORDER',
    listApi: getReturnPointList,
    isUseDefaultPreview: true,
  },
  {
    label: '基础售价调整单',
    value: 'PRICE_ADJUST_ORDER',
    listApi: priceAdjustOrderPage,
    isUseDefaultPreview: true,
  },
  {
    label: '授信单',
    value: 'CUSTOMER_CREDIT_ORDER',
    listApi: customerCreditOrderPage,
    isUseDefaultPreview: true,
  },
  {
    label: '收款单',
    value: 'SETTLEMENT_ORDER',
    billIdField: 'settlementOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      statuses: ['COMPLETE'],
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '本次实收金额',
        prop: 'realPaymentAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: getReceiptVoucherList,
    isUseDefaultPreview: false,
  },
  {
    label: '折扣折让单',
    value: 'DISCOUNT_ORDER',
    listApi: discountOrderPage,
    isUseDefaultPreview: true,
  },
  {
    label: '预收款单',
    value: 'PRE_PAY_ORDER',
    billIdField: 'prePayOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '预收款金额',
        prop: 'prepayAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: prePayOrderPage,
    isUseDefaultPreview: false,
  },
  {
    label: '应收对账单',
    value: 'RECONCILIATION_ORDER',
    billIdField: 'billReconOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '本期收款金额',
        prop: 'totalPaymentAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: accountReceivable.page,
    isUseDefaultPreview: false,
  },
  {
    label: '付款单',
    value: 'PAYMENT_ORDER',
    billIdField: 'paymentOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '本次实付金额',
        prop: 'realPaymentAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: getPaymentSlipList,
    isUseDefaultPreview: false,
  },
  {
    label: '预付款单',
    value: 'PRE_PAYMENT_ORDER',
    listApi: getPrePaymentOrderPage,
    isUseDefaultPreview: true,
  },
  {
    label: '使用预付款',
    value: 'PAYMENT_ORDER_PRE_PAY',
    listApi: null,
    isUseDefaultPreview: true,
  },
  {
    label: '本次折让',
    value: 'PAYMENT_ORDER_DISCOUNT',
    listApi: null,
    isUseDefaultPreview: true,
  },
  {
    label: '使用预收款',
    value: 'SETTLEMENT_ORDER_PRE_PAY',
    listApi: null,
    isUseDefaultPreview: true,
  },
  {
    label: '使用折扣折让',
    value: 'SETTLEMENT_ORDER_DISCOUNT',
    listApi: null,
    isUseDefaultPreview: true,
  },
  {
    label: '盘点任务单',
    value: 'STOCK_CHECK_TASK',
    listApi: stockCheckTaskPage,
    isUseDefaultPreview: true,
  },
  {
    label: '盘点单',
    value: 'STOCK_CHECK_ORDER',
    billIdField: 'stockCheckOrderId',
    billTypeField: 'billNo',
    codeJointNames: [{ name: 'cargoWarehouseName', code: 'cargoWarehouseCode' }],
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '进货总额',
        prop: 'totalRestockAmt',
        align: 'right',
        formatter: (row, column, cellValue) => Vue.prototype.$format.amount(cellValue),
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: getInventoryList,
    isUseDefaultPreview: false,
  },
  {
    label: '积分管理',
    value: 'CUSTOMER_REWARD_ORDER',
    listApi: customerRewardOrderPage,
    isUseDefaultPreview: true,
  },
  {
    label: '通用扫描单',
    value: 'SCAN_ORDER',
    billIdField: 'scanOrderId',
    billTypeField: 'billNo',
    queryParams: {
      page: 1,
      size: 10,
      sortBy: 'createTime:DESC',
    },
    columns: [
      { label: '序号', type: 'index' },
      { label: '单据号', prop: 'billTypeField' },
      {
        label: '扫描数量',
        prop: 'totalScanQty',
        sortable: true,
      },
      {
        label: '创建时间',
        prop: 'createTime',
        formatter: (row, column, cellValue) => (cellValue ? dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss') : ''),
        sortable: true,
      },
      { label: '操作', prop: 'operate', slot: 'operate' },
    ],
    listApi: scanForm.page,
    isUseDefaultPreview: false,
  }
]

const templateJsonOptionsToFields = {
  PURCHASE_RECEIVE_ORDER: [
    { label: '仓库', value: 'cargoWarehouseName' },
    { label: '验收单号', value: 'billNo' },
    { label: '验收日期', value: 'completeTime' },
    { label: '供应商', value: 'supplierName' },
    { label: '采购单号', value: 'purchaseBillNo' },
    { label: '打印日期', value: 'printTime' },
    { label: '商品编码', value: 'skuCode' },
    { label: '商品名称', value: 'skuName' },
    { label: '商品条码', value: 'skuBarcode' },
    { label: '库位', value: 'warehouseLocationCode' },
    { label: '含税进价', value: 'costAmt' },
    { label: '数量', value: 'stockQty' },
    { label: '含税成本', value: 'costPrice' },
    { label: '制单', value: 'completeUserName' },
    { label: '条码', value: 'barcode' },
    { label: '备注', value: 'remark' },
  ],

  VERBAL_ORDER: [
    { label: '单号', value: 'billNo' },
    { label: '赠品数量', value: 'totalGiftQty' },
    { label: '录单日期', value: 'createTime' },
    { label: '客户', value: 'customerName' },
    { label: '打印时间', value: 'printTime' },
    { label: '货号', value: 'articleNo' },
    { label: '品名', value: 'skuName' },
    { label: '商品编码', value: 'skuCode' },
    { label: '规格', value: 'specsDesp' },
    { label: '包装', value: 'packageConversion' },
    { label: '数量', value: 'saleQty' },
    { label: '单价', value: 'salePrice' },
    { label: '金额', value: 'saleAmt' },
    { label: '赠送', value: 'ifGift' },
    { label: '总数量', value: 'totalQty' },
    { label: '原金额', value: 'totalAskAmt' },
    { label: '优惠金额', value: 'finalZkzrAmt' },
    { label: '条码', value: 'barcode' },
    { label: '电话', value: 'submitManPhone' },
    { label: '创建人名称', value: 'createUserName' },
    { label: '创建人编码', value: 'createUserCode' },
  ],

  SALE_ORDER: [
    { label: '单号', value: 'billNo' },
    { label: '赠品数量', value: 'totalGiftQty' },
    { label: '录单日期', value: 'createTime' },
    { label: '结算方式', value: 'settlementMode' },
    { label: '前单欠款', value: 'receivableBalanceAmt' },
    { label: '当前新累计欠款', value: 'currentSumNewBalanceAmt' },
    { label: '当前累计欠款金额', value: 'currentSumBalanceAmt' },
    { label: '当前预收款金额', value: 'currentRemainPrepayAmt' },
    { label: '当前折扣折让金额', value: 'currentRemainDiscountAmt' },
    { label: '优惠前金额', value: 'preZkzrAmt' },
    { label: '客户', value: 'customerName' },
    { label: '电话', value: 'contactPhone' },
    { label: '打印时间', value: 'printTime' },
    { label: '货号', value: 'articleNo' },
    { label: '品名', value: 'skuName' },
    { label: '规格', value: 'specsDesp' },
    { label: '包装', value: 'packageConversion' },
    { label: '数量', value: 'saleQty' },
    { label: '单价', value: 'receivablePrice' },
    { label: '金额', value: 'receivableAmt' },
    { label: '赠送', value: 'ifGift' },
    { label: '单品备注', value: 'remark' },
    { label: '折扣折让总金额', value: 'totalZkzrAmt' },
    { label: '总数量', value: 'totalQty' },
    { label: '原金额', value: 'totalRefSaleAmt' },
    { label: '优惠金额', value: 'totalReceivableReduceAmt' },
    { label: '应收金额', value: 'totalReceivableAmt' },
    { label: '备注', value: 'remark' },
    { label: '条码', value: 'barcode' },
    { label: '送货地址', value: 'deliveryAddress' },
    { label: '制单人', value: 'createUserName' },
  ],
  WAREHOUSE_TRANSFER_ORDER: [
    { label: '打印日期', value: 'printTime' },
    { label: '单据号', value: 'billNo' },
    { label: '调出组织', value: 'outWarehouseName' },
    { label: '调入组织', value: 'inWarehouseName' },
    { label: '商品编码', value: 'skuCode' },
    { label: '商品名称', value: 'skuName' },
    { label: '包装描述', value: 'packageConversion' },
    { label: '含税进价', value: 'costPrice' },
    { label: '数量', value: 'transferQty' },
    { label: '含税成本', value: 'costAmt' },
    { label: '制单', value: 'createUserName' },
  ],
  PURCHASE_RETURN_ORDER: [
    { label: '单号', value: 'billNo' },
    { label: '供应商', value: 'supplierName' },
    { label: '开单日期', value: 'createTime' },
    { label: '仓库', value: 'returnWarehouseName' },
    { label: '打印时间', value: 'printTime' },
    { label: '商品编码', value: 'skuCode' },
    { label: '商品货号', value: 'articleNo' },
    { label: '商品名称', value: 'skuName' },
    { label: '单位', value: 'stockUnit' },
    { label: '单价', value: 'costPrice' },
    { label: '数量', value: 'returnQty' },
    { label: '金额', value: 'costAmt' },
    { label: '开单', value: 'createUserName' },
    { label: '出库', value: 'completeUserName' },
    { label: '复核', value: 'reviewUserName' },
    { label: '本单数量', value: 'totalReturnQty' },
    { label: '本单金额', value: 'totalCostAmt' },
  ],
  SALE_RETURN_ORDER: [
    { label: '单号', value: 'billNo' },
    { label: '录单日期', value: 'createTime' },
    { label: '客户', value: 'customerName' },
    { label: '结算方式', value: 'settlementMode' },
    { label: '备注', value: 'remark' },
    { label: '打印时间', value: 'printTime' },
    { label: '货号', value: 'articleNo' },
    { label: '品名', value: 'skuName' },
    { label: '规格', value: 'specsDesp' },
    { label: '单位', value: 'stockUnit' },
    { label: '单品备注', value: 'remark' },
    { label: '数量', value: 'qty' },
    { label: '单价', value: 'returnPrice' },
    { label: '金额', value: 'returnableAmt' },
    { label: '总数量', value: 'totalReturnQty' },
    { label: '合计', value: 'actualReturnAmt' },
    { label: '制单人', value: 'createUserName' },
    { label: '审核', value: 'reviewUserName' },
  ],
  MATERIAL_RECEIVE_ORDER: [
    { label: '单号', value: 'billNo' },
    { label: '录入日期', value: 'createTime' },
    { label: '制单人', value: 'createUserName' },
    { label: '打印时间', value: 'printTime' },
    { label: '商品货号', value: 'articleNo' },
    { label: '商品名称', value: 'skuName' },
    { label: '规格', value: 'specsDesp' },
    { label: '单位', value: 'stockUnit' },
    { label: '数量', value: 'receiveQty' },
    { label: '进价', value: 'costPrice' },
    { label: '金额', value: 'costAmt' },
    { label: '合计数量', value: 'totalReceiveQty' },
    { label: '合计金额', value: 'totalCostAmt' },

    { label: '审核', value: 'reviewUserName' },
  ],
  STOCK_BREAKAGE_ORDER: [
    { label: '组织', value: 'businessGroupName' },
    { label: '单号', value: 'billNo' },
    { label: '备注', value: 'remark' },
    { label: '打印日期', value: 'printTime' },
    { label: '商品编码', value: 'skuCode' },
    { label: '商品名称', value: 'skuName' },
    { label: '规格', value: 'specsDesp' },
    { label: '货号', value: 'articleNo' },
    { label: '账面数量', value: 'storageQty' },
    { label: '实际数量', value: 'actualQty' },
    { label: '实际进价金额', value: 'costAmt' },
    { label: '盘亏率', value: 'stockBreakageRatio' },
    { label: '盈亏原因', value: 'returnPrice' },
  ],
  SETTLEMENT_ORDER: [
    { label: '单号', value: 'billNo' },
    { label: '开单日期', value: 'createTime' },
    { label: '打印时间', value: 'printTime' },
    { label: '批发客户', value: 'customerName' },
    { label: '合计预收款', value: 'usedPrepayAmt' },
    { label: '批发销货单号', value: 'sourceNo' },
    { label: '批发销货日期', value: 'completeTime' },
    { label: '延期收款日期', value: 'printTime' },
    { label: '延期应收金额', value: 'receivableAmt' },
    { label: '收款金额', value: 'receiveAmt' },
  ],
  RECHECK_ORDER: [
    { label: '公司名称', value: 'partnerName' },
    { label: '客户', value: 'customerName' },
    { label: '客户编码', value: 'customerCode' },
    { label: '配送门店', value: 'customerName' },
    { label: '打印时间', value: 'printTime' },
    { label: '销售单号', value: 'saleOrderNo' },
    { label: '装箱单号', value: 'billNo' },
    { label: '打印人员', value: 'userName' },
    { label: '备注', value: 'remark' },
    { label: '商品条码', value: 'skuBarcode' },
    { label: '货号', value: 'articleNo' },
    { label: '商品名称', value: 'skuName' },
    { label: '数量', value: 'scanQty' },
    { label: '规格', value: 'specsDesp' },
    { label: '包装单位', value: 'packageUnit' },
    { label: '批发价', value: 'avgReceivablePrice' },
    { label: '金额', value: 'scanAmt' },
    { label: '地址', value: 'deliveryAddress' },
    { label: '联系电话', value: 'contactPhone' },
    { label: '开单人', value: 'contactPersonnel' },
    { label: '复核人', value: 'userName' },
    { label: '箱号', value: 'boxNo' },
    { label: '送货线路', value: 'deliveryRoute' },
  ],
  PURCHASE_RETURN_APPLY_ORDER: [
    { label: '单据号', value: 'billNo' },
    { label: '供应商', value: 'supplierName' },
    { label: '开单日期', value: 'createTime' },
    { label: '退货说明', value: 'remark' },
    { label: '商品编码', value: 'skuCode' },
    { label: '货号', value: 'articleNo' },
    { label: '商品名称', value: 'skuName' },
    { label: '单位', value: 'stockUnit' },
    { label: '单价', value: 'costPrice' },
    { label: '单价', value: 'costPrice' },
    { label: '申请数量', value: 'returnQty' },
    { label: '申请金额', value: 'costAmt' },
    { label: '备注', value: 'remark' },
    { label: '开单人', value: 'createUserName' },
    { label: '本单数量', value: 'totalReturnQty' },
    { label: '本单金额', value: 'totalCostAmt' },
  ],
}

export default {
  // 闲置变量
  templateDefalutJsonData,
  // 系统内需要打印的单据类型
  templateJsonOptions,
  // 系统内需要打印的单据类型对应的字段名
  templateJsonOptionsToFields,
}
