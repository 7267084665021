import i18n from '@/locale'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import { successCodes, ignoreCodes, loginBackInCodes, cacheWhiteList, speechApiLists } from '@/config'
import { AxiosError } from 'axios'
import { HttpStatusCode } from '@/constants'
import Speech from 'speak-tts'
import { AppVersionStorage, LocaleStorage } from '@/utils/storage'

/**
 * 关闭loading
 * @param {*} config
 */
function closeLoading(config) {
  if (config?.loading) {
    setTimeout(() => {
      if (config?.loadingInstance) {
        config.loadingInstance.close()
        config.loadingInstance = null
      }
    })
  }
}

// 语音播报初始化
function handleSpeechInit() {
  return new Promise((resolve, reject) => {
    let speech = new Speech()
    if (!speech?.browserSupport)
      return Vue.prototype.$mgMessage(i18n.t('该浏览器暂不支持语音播报, 建议使用【谷歌】浏览器'), 'error')
    speech.setLanguage(LocaleStorage.get())
    speech
      .init()
      .then(() => {
        resolve(speech)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
// 语音播报
function handleErrorVoiceSpeak(msg) {
  if (!msg) return
  handleSpeechInit().then((res) => {
    res.speak({ text: msg }).then(() => {})
  })
}
/**
 * 检查版本是否需要更新
 * @param {*} response
 */
export function checkAppVersion(response) {
  // 过滤不需要检查版本号的接口
  if (['/mg/drp-admin-api/portal/page'].includes(response.config.url)) return
  const appVersion = response.headers['app-version']
  if (appVersion) {
    const localAppVersion = AppVersionStorage.get()
    if (!localAppVersion) {
      AppVersionStorage.set(appVersion)
      return
    }
    if (localAppVersion != appVersion) {
      alert('检测到当前系统版本已更新，请刷新页面！')
      AppVersionStorage.set(appVersion)
      window.location.reload()
    }
  }
}

/**
 * 状态码为 2xx 范围内都会触发此方法
 * @param {*} response
 * @returns
 */
export function handleResponseSuccess(response) {
  const { data, config, headers } = response
  const { code } = data

  // 关闭 loading
  closeLoading(config)

  checkAppVersion(response)

  // 处理文件流
  if (config.responseType === 'blob') {
    // 对象有 'content-disposition' 属性 一定是原始导出，接口返回的是文件流
    if (Object.keys(headers).includes('content-disposition')) {
      return response
    } else {
      //否则执行下载中心导出
      return { code: 200, flag: 'downloadCenter' }
    }
  }

  // 处理接口正常返回数据
  if (successCodes.includes(code)) {
    if (cacheWhiteList?.includes(config.url)) {
      LocalForage.setItem(config.url, data.data)
    }
    if (headers?.['api-query-verison']) return { ...data, headers }
    return data
  }
  // 处理其他异常情况，使用自定义错误码
  response.status = HttpStatusCode.NO_SUCCESS
  return handleResponseError({ response })
}
/**
 * 状态码超出 2xx 范围会触发此方法
 *  status http状态码
 *  code   接口返回的特定状态码
 */
export function handleResponseError({ code: cancelCode, message, response }) {
  if (!response) {
    response = { data: { code: cancelCode, msg: message } }
  }
  // 关闭 loading
  closeLoading(response.config || { loading: true })

  // 处理取消重复请求的错误
  if (cancelCode === AxiosError.ERR_CANCELED) {
    return Promise.reject({ code: cancelCode, msg: message, url: response?.config?.url })
  }
  // 处理客户端请求404错误
  let { status, data, statusText, config, headers } = response
  if (status === HttpStatusCode.NOT_FOUND) {
    data = { code: status, msg: `${statusText}【 ${config.url} 】` }
  }
  const { code, msg = statusText } = data || { code: 500, msg: '网络可能存在波动，请重试!' }

  switch (status) {
    // 无权限/登录失效
    case HttpStatusCode.UNAUTHORIZED:
    case HttpStatusCode.PAYMENT_REQUIRED:
      Vue.prototype.$mgMessage(msg, 'error')
      router.push({ name: '401' })
      break
    // 处理 handleResponseSuccess 中状态码不在successCodes中的情况
    case HttpStatusCode.NO_SUCCESS:
      // 根据指定的code，忽略错误消息提醒
      if (ignoreCodes.includes(code)) return
      break
    default:
      {
        if (ignoreCodes.includes(code)) return Promise.reject(response)
        let content = msg
        if (status >= HttpStatusCode.ERROR_RESPONSE && status < HttpStatusCode.CACHE_SUCCESS) {
          content = msg + `<br/> ${i18n.t('错误ID')}： ${headers['x-rid'] ? headers['x-rid'].split(',')[0] : ''}`
        }
        Vue.prototype
          .$mgConfirm(content, i18n.t('系统提示'), {
            dangerouslyUseHTMLString: true,
            showCancelButton: false,
            type: 'error',
          })
          .finally(() => {
            // 根据指定的code，提示用户重新登录
            if (loginBackInCodes.includes(code)) {
              store.dispatch('user/logout').then(() => {
                router.push({ name: 'login' })
              })
            }
          })
      }
      return Promise.reject(response)
  }
}

/**
 * 失败响应拦截 -- 用于语音播报
 */
export function handleSpeechResponseError(response) {
  const { data, config } = response
  // 特定接口语音播报
  if (speechApiLists?.includes(config?.url)) handleErrorVoiceSpeak(data?.msg || '')
  return Promise.reject(response)
}
