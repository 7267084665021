import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//192.168.0.188:10003' : '/'
//盘点任务查询分页
export function stockCheckTaskPage(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/page`,
    method: 'post',
    data,
  })
}
//盘点任务新增
export function stockCheckTaskSave(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask`,
    method: 'post',
    loading: true,
    timeout: 0,
    data,
  })
}
//盘点任务sku快速添加
export function stockCheckTaskListFastByQuery(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/listFastByQuery`,
    method: 'post',
    data,
  })
}

//盘点任务零差异/库存处理
export function stockCheckTaskCompleteDiff(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/completeDiff`,
    method: 'post',
    loading: true,
    timeout: 0,
    data,
  })
}
//盘点任务是否实盘
export function stockCheckTaskGetIfChecked(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/getIfChecked/${data}`,
    method: 'post',
    loading: true,
  })
}
//未入账盘点单查询
export function stockCheckTaskGetCheckOrder(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/getCheckOrder/${data}`,
    method: 'post',
    loading: true,
  })
}

//盘点任务列表导出
export function stockCheckTaskExportExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//盘点任务-终止盘点
export function stockCheckTaskVoided(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/voided`,
    method: 'post',
    loading: true,
    data,
  })
}

//盘点任务查询单条
export function getStockCheckTaskById(id) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/${id}`,
    method: 'get',
    loading: true,
  })
}

//盘点任务单明细列表导出
export function stockCheckTaskItemExportExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTaskItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//盘点单明细列表分页
export function stockCheckTaskItemPage(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTaskItem/page`,
    method: 'post',
    loading: true,
    data,
  })
}
//盘点单明细列表合计
export function stockCheckTaskItemSum(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTaskItem/sum`,
    method: 'post',
    data,
  })
}
