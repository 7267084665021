<template>
  <el-container class="layout-container">
    <el-header class="layout-header">
      <Header />
    </el-header>
    <el-container class="layout-content">
      <Aside />
      <Main />
    </el-container>
    <KeyboardShortcuts />
  </el-container>
</template>

<script>
  import Main from './components/Main'
  import Header from './components/Header'
  import Aside from './components/Aside'
  import KeyboardShortcuts from '@/components/keyboard-shortcuts'
  export default {
    name: 'Layout',
    components: { Main, Header, Aside, KeyboardShortcuts },
  }
</script>

<style lang="scss" scoped>
  .layout-container {
    .layout-header {
      height: $mg-layout-header-height !important;
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      background: #fff;
      position: relative;
      z-index: 12;
    }
    .layout-content {
      height: $mg-layout-content-height;
    }
  }
</style>
