export function speak(text) {
  return new Promise((resolve, reject) => {
    if (!window.speechSynthesis) {
      reject(new Error('SpeechSynthesis is not supported in this browser.'))
      return
    }

    const utterance = new SpeechSynthesisUtterance(text)
    utterance.onend = () => {
      resolve()
    }

    utterance.onerror = (event) => {
      reject(event.error)
    }

    // window.speechSynthesis.speak(utterance)
    try {
      window.speechSynthesis.speak(utterance)
    } catch (ex) {
      console.log('speechSynthesis not available', ex)
    }
  })
}
