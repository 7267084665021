/**
 * 盈亏单相关打印变量
 */
const preReceiveOrderTempOptions = [
  {
    label: '预收款单',
    value: 'all',
    children: [{ label: '预收款单', value: 1 }],
  },
]
const preReceiveOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 280,
          width: 210,
          paperHeader: 64.5,
          paperFooter: 777,
          printElements: [
            {
              options: {
                left: 157.5,
                top: 7.5,
                height: 28.5,
                width: 247.5,
                right: 405.745719909668,
                title: '山东晨光文具有限责任公司',
                bottom: 36.74574279785156,
                hCenter: 22.49574279785156,
                vCenter: 281.99571990966797,
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 217.5,
                top: 39,
                height: 17,
                width: 120,
                right: 338.24146270751953,
                title: '批发预收款单',
                bottom: 56.743614196777344,
                hCenter: 48.243614196777344,
                vCenter: 278.24146270751953,
                fontSize: 14,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 151.5,
                top: 76.5,
                height: 25,
                width: 400.5,
                field: 'billNo',
                right: 551.9914627075195,
                title: '文本',
                bottom: 101.49146270751952,
                hCenter: 88.99146270751953,
                vCenter: 351.74146270751953,
                testData: 'YSK123123123',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 76.5,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '单据号',
                bottom: 101.99146270751952,
                hCenter: 89.24146270751953,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 100.5,
                height: 9,
                width: 400.5,
                right: 529.4936141967773,
                bottom: 109.49361419677734,
                hCenter: 104.99361419677734,
                vCenter: 341.24361419677734,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 25.5,
                top: 102,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '开单日期',
                bottom: 126.99574279785156,
                hCenter: 114.49574279785156,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 105,
                height: 25,
                width: 400.5,
                field: 'createTime',
                right: 553.4936141967773,
                title: '文本',
                bottom: 130,
                hCenter: 117.5,
                vCenter: 353.24361419677734,
                testData: '2022-12-12 12:12:12',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 127.5,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '单据打印日期',
                bottom: 152.5,
                hCenter: 140,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 130.5,
                height: 25,
                width: 400.5,
                field: 'printingDate',
                right: 551.9936141967773,
                title: '文本',
                bottom: 155.5,
                hCenter: 143,
                vCenter: 351.74361419677734,
                testData: '2022-12-12 12:12:12',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 130.5,
                height: 9,
                width: 400.5,
                right: 555.7414627075195,
                bottom: 137.99785995483398,
                hCenter: 133.49785995483398,
                vCenter: 355.49146270751953,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 153,
                top: 153,
                height: 9,
                width: 400.5,
                right: 554.9978713989258,
                bottom: 162.74146270751953,
                hCenter: 158.24146270751953,
                vCenter: 354.7478713989258,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 25.5,
                top: 153,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '操作员',
                bottom: 176.49146270751953,
                hCenter: 163.99146270751953,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 156,
                height: 25,
                width: 400.5,
                field: 'createUserName',
                right: 555.7414627075195,
                title: '文本',
                bottom: 153.99360275268555,
                hCenter: 141.49360275268555,
                vCenter: 355.49146270751953,
                testData: '李先生',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 177,
                height: 9,
                width: 400.5,
                right: 553.495719909668,
                bottom: 186.74146270751953,
                hCenter: 182.24146270751953,
                vCenter: 353.24571990966797,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 153,
                top: 177,
                height: 25,
                width: 400.5,
                field: 'customerName',
                right: 554.9999771118164,
                title: '文本',
                bottom: 202.75425720214844,
                hCenter: 190.25425720214844,
                vCenter: 354.7499771118164,
                testData: '001',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 177,
                height: 25,
                width: 126,
                right: 149.9999771118164,
                title: '付款方',
                bottom: 128.49146270751953,
                hCenter: 115.99146270751952,
                vCenter: 86.9999771118164,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 201,
                height: 9,
                width: 400.5,
                right: 552.9971694946289,
                bottom: 206.4971466064453,
                hCenter: 201.9971466064453,
                vCenter: 352.7471694946289,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 153,
                top: 201,
                height: 25,
                width: 400.5,
                field: 'partnerName',
                right: 552.745719909668,
                title: '文本',
                bottom: 227.49357986450195,
                hCenter: 214.99357986450195,
                vCenter: 352.49571990966797,
                testData: '002',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 202.5,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '收款方',
                bottom: 227.5,
                hCenter: 215,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 228,
                height: 9,
                width: 400.5,
                right: 554.2478713989258,
                bottom: 237,
                hCenter: 232.5,
                vCenter: 353.9978713989258,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 153,
                top: 228,
                height: 25,
                width: 400.5,
                field: 'prepayAmt',
                right: 552.2471694946289,
                title: '文本',
                bottom: 253.99928665161133,
                hCenter: 241.49928665161133,
                vCenter: 351.9971694946289,
                testData: '123.33',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 228,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '预收款金额',
                bottom: 252.99359130859375,
                hCenter: 240.49359130859375,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 252,
                height: 9,
                width: 400.5,
                right: 554.4971694946289,
                bottom: 261.2471466064453,
                hCenter: 256.7471466064453,
                vCenter: 354.2471694946289,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 153,
                top: 252,
                height: 25,
                width: 400.5,
                field: 'prepayAmt',
                right: 551.4971694946289,
                title: '文本',
                bottom: 277.9992866516113,
                hCenter: 265.4992866516113,
                vCenter: 351.2471694946289,
                testData: '123.66',
                hideTitle: true,
                textAlign: 'center',
                upperCase: '7',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 253.5,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '预收款金额大写',
                bottom: 278.4978485107422,
                hCenter: 265.9978485107422,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 279,
                height: 9,
                width: 400.5,
                right: 553.7471694946289,
                bottom: 288.2471466064453,
                hCenter: 283.7471466064453,
                vCenter: 353.4971694946289,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 153,
                top: 279,
                height: 25,
                width: 400.5,
                field: 'remark',
                right: 551.9935684204102,
                title: '文本',
                bottom: 306.24357986450195,
                hCenter: 293.74357986450195,
                vCenter: 351.74356842041016,
                testData: '测试备注测试备注测试备注测试备注测试备注测试备注测试备注测试备注',
                hideTitle: true,
                textAlign: 'center',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 25.5,
                top: 279,
                height: 25,
                width: 126,
                right: 151.49361419677734,
                title: '收款信息',
                bottom: 303.9914855957031,
                hCenter: 291.4914855957031,
                vCenter: 88.49361419677734,
                fontSize: 10,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 153,
                top: 303,
                height: 9,
                width: 400.5,
                right: 551.9914627075195,
                bottom: 311.25,
                hCenter: 306.75,
                vCenter: 351.74146270751953,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 375,
                top: 334.5,
                height: 28.5,
                width: 76.5,
                right: 451.49361419677734,
                title: '付款方',
                bottom: 363.7478713989258,
                hCenter: 349.4978713989258,
                vCenter: 413.24361419677734,
                fontSize: 11,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: { left: 433.5, top: 357, height: 9, width: 118.5 },
              printElementType: { title: '横线', type: 'hline' },
            },
          ],
          paperNumberLeft: 556.5,
          paperNumberTop: 28.5,
        },
      ],
    },
  },
}

export default {
  // 预收款单 -- 不同配送中心打印模板名
  preReceiveOrderTempOptions,
  // 预收款单 -- 不同分公司打印模板名json
  preReceiveOrderTempJsonOptions,
}
