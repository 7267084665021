import { physicalWarehouseList } from '@/api/stock/physicalWarehouse'
import { logicWarehouseList } from '@/api/stock/logicWarehouse'
import { fetchCargoWarehouseListByBusinessGroup } from '@/api/stock/warehouseProperties'
const state = () => ({
  // 物理仓列表
  physicalWarehouseList: {
    NORMAL: [],
    ALL: [],
  },

  // 逻辑仓列表
  logicWarehouseList: {
    NORMAL: [],
    ALL: [],
  },

  /**
   * 经营组织下对应仓库
   * @param ifPurchase 采购仓
   * @param ifReturn 退货仓
   * @param ifSale 销售仓
   * */
  warehouseType: {
    ifPurchase: [],
    ifReturn: [],
    ifSale: [],
    all: [],
  },

  // 当前登录人所属经营组织下的所有逻辑仓
  logicWarehouseByAllBusinessGroup: [],
})

const mutations = {
  SET_PHYSICAL_WAREHOUSE_LIST(state, { list, prop }) {
    state.physicalWarehouseList[prop] = list
  },

  SET_LOGIC_WAREHOUSE_LIST(state, { list, prop }) {
    state.logicWarehouseList[prop] = list
  },

  SET_WAREHOUSE_TYPE_LIST(state, { list, type }) {
    state.warehouseType[type] = list
  },

  SET_LOGIC_WAREHOUSE_ALL_BUSINESSGROUP(state, data) {
    state.logicWarehouseByAllBusinessGroup = data
  },
}

const actions = {
  /**
   * 物理仓列表
   * @param {*} payload
   * @returns
   */
  async FETCH_PHYSICAL_WAREHOUSE_LIST({ commit, state }, payload = '') {
    const { status } = payload?.params
    // 局限于一般场景下获取启用或者禁用数据的列表接口
    const prop = status.includes('NORMAL') && status.includes('FORBIDDEN') ? 'ALL' : status[0]
    // 如果存在则不再发起请求
    if (state.physicalWarehouseList[prop].length > 0) return
    // 发起 http 请求
    const { data: list } = await physicalWarehouseList(payload?.params)
    // mutation
    commit(`SET_PHYSICAL_WAREHOUSE_LIST`, { list, prop })
  },

  /**
   * 逻辑仓列表
   * @param {*} payload
   * @returns
   */
  async FETCH_LOGIC_WAREHOUSE_LIST({ commit, state }, payload = '') {
    const { status } = payload?.params
    // 局限于一般场景下获取启用或者禁用数据的列表接口
    const prop = status.includes('NORMAL') && status.includes('FORBIDDEN') ? 'ALL' : status[0]
    // 如果存在则不再发起请求
    if (state.logicWarehouseList[prop].length > 0) return
    // 发起 http 请求
    const { data: list } = await logicWarehouseList(payload?.params)
    // mutation
    commit(`SET_LOGIC_WAREHOUSE_LIST`, { list, prop })
  },

  /**
   * 经营组织下对应仓库
   * this.$store.dispatch('stock/FETCH_WAREHOUSE_TYPE_LIST', { params: { }, type: 'ifReturn' })
   * @param {type:'ifPurchase'} 采购仓
   * @param {type:'ifReturn'} 退货仓
   * @param {type:'ifSale'} 销售仓
   * @param {type:'all'} 所有仓库
   * @returns
   */
  async FETCH_WAREHOUSE_TYPE_LIST({ commit }, payload) {
    const { type } = payload
    // 发起 http 请求
    const { data: list } = await fetchCargoWarehouseListByBusinessGroup({ ...payload?.params, [type]: true })
    // mutation
    commit(`SET_WAREHOUSE_TYPE_LIST`, { list, type })
    return { data: list }
  },

  /**
   * 当前登录人所属经营组织下的所有逻辑仓
   * @param {*} param0
   * @returns
   */
  async FETCH_LOGIC_WAREHOUSE_LIST_ALL_BUSINESSGROUP({ dispatch, commit, state }) {
    if (state.logicWarehouseByAllBusinessGroup.length > 0) return { data: state.logicWarehouseByAllBusinessGroup }
    // 获取当前登录人所属的经营组织
    const { data: businessGroup } = await dispatch(
      'business/FETCH_BUSINESS_ORGANIZATION_BY_USER_LIST',
      { ifAll: false },
      { root: true }
    )
    // 查询所有经营组织下的逻辑仓
    const { data: warehouseList } = await dispatch('FETCH_WAREHOUSE_TYPE_LIST', {
      type: 'all',
      params: {
        businessGroupCode: businessGroup.map((item) => item.businessGroupCode),
      },
    })
    commit('SET_LOGIC_WAREHOUSE_ALL_BUSINESSGROUP', warehouseList)
    return { data: state.logicWarehouseByAllBusinessGroup }
  },
}

export default { state, mutations, actions }
