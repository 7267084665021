module.exports = {
  projectName: process.env.VUE_APP_NAME,
  tokenName: 'X-TOKEN',
  // 最多可显示的标签个数
  maxTabs: 9,
  // 路由白名单
  routesWhiteList: ['/login', '/401', '/404', '*', '/'],
  // 监听页面内容改变
  listeningPageChangeRouteNames: [
    'EditGoodsInfo',
    'AddGoodsInfo',
    'AddRebateGoods',
    'EditRebateGoods',
    'AddReturnPoint',
    'EditReturnPoint',
    'AddRebateGoods',
    'EditRebateGoods',
    'AddNoDiscountGoods',
    'EditNoDiscountGoods',
    'AddReturnApplication',
    'EditReturnApplication',
    'AddReceiptVoucher',
    'EditReceiptVoucher',
    'AddCostAdjustmentSheet',
    'EditCostAdjustmentSheet',
    'PurchaseEntryAdd',
    'PurchaseEntryEdit',
    'WarehouseTransferOrderAdd',
    'WarehouseTransferOrderEdit',
    'AddInventoryList',
    'EditInventoryList',
    'AddReplenishmentList',
    'EditReplenishmentList',
    'SupplyInformationOrderAdd',
    'SupplyInformationOrderEdit',
    'PurchaseOrderAdd',
    'PurchaseOrderEdit',
    'WholesaleOrdersAdd',
    'WholesaleOrdersEdit',
    'WholesaleSalesAdd',
    'WholesaleSalesEdit',
    'WholesaleSalesTransfer',
    'outboundReview',
    'PrePayAdd',
    'PrePayEdit',
    'DicountAdd',
    'DicountEdit',
    'skubomAdd',
    'skubomEdit',
    'priceAdjustAdd',
    'priceAdjustEdit',
    'wholesalePolicyAdd',
    'wholesalePolicyEdit',
    'PurchaseReturnAdd',
    'PurchaseReturnEdit',
    'InventoryTaskAdd',
    'ProfitAndLossAdd',
    'ProfitAndLossEdit',
    'ReceiveOrderAdd',
    'ReceiveOrderEdit',
    'WholesaleReturnAdd',
    'WholesaleReturnEdit',
    'prepaymentAdd',
    'prepaymentEdit',
    'paymentSlipAdd',
    'paymentSlipEdit',
    'SingleItemPromotionAdd',
    'SingleItemPromotionEdit',
    'paymentDiscountAdd',
    'paymentDiscountEdit',
  ],
}
