/**
 * 采购入库单相关打印变量
 */
const purchaseReceiptTempOptions = [
  {
    label: '采购入库单',
    value: 'all',
    children: [
      { label: '采购入库单1', value: 1 },
      { label: '采购入库单2', value: 2 },
    ],
  },
]
const purchaseReceiptTempJsonOptions = {
  // 郑州管城区配送中心
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 280,
          width: 210,
          paperHeader: 94.5,
          paperFooter: 769.5,
          printElements: [
            {
              options: {
                left: 241.5,
                top: 6,
                height: 17,
                width: 120,
                testData: '',
                fontSize: 16.5,
                fontWeight: '700',
                textAlign: 'center',
                hideTitle: true,
                title: '商品验收单',
                right: 361.5,
                bottom: 23,
                vCenter: 301.5,
                hCenter: 14.5,
              },
              printElementType: {
                title: '标题',
                type: 'text',
              },
            },
            {
              options: {
                left: 189,
                top: 27,
                height: 15,
                width: 183,
                title: '验收单号',
                right: 371.25,
                bottom: 42,
                vCenter: 279.75,
                hCenter: 34.5,
                field: 'billNo',
                testData: 'JHC11001202303270004',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 376.5,
                top: 27,
                height: 15,
                width: 190.5,
                title: '打印时间',
                right: 569.25,
                bottom: 42,
                vCenter: 473.25,
                hCenter: 34.5,
                field: 'printTime',
                testData: '2023-04-06',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 27,
                height: 15,
                width: 169.5,
                title: '仓库',
                right: 135,
                bottom: 42,
                vCenter: 75,
                hCenter: 34.5,
                field: 'cargoWarehouseName',
                testData: '01-大仓',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 189,
                top: 46.5,
                height: 15,
                width: 183,
                title: '供应商',
                right: 372,
                bottom: 61.5,
                vCenter: 280.5,
                hCenter: 54,
                field: 'supplierName',
                testData: '01-义务晨兴公司',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 378,
                top: 46.5,
                height: 15,
                width: 189,
                title: '验收日期',
                right: 564.75,
                bottom: 61.5,
                vCenter: 469.5,
                hCenter: 54,
                field: 'completeTime',
                testData: '2023-04-06',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 46.5,
                height: 15,
                width: 169.5,
                title: '采购单号',
                right: 184.5,
                bottom: 61.5,
                vCenter: 99,
                hCenter: 54,
                field: 'billNo',
                testData: 'CGSS20230327000054',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 66,
                height: 24,
                width: 553.5,
                title: '备注',
                right: 561,
                bottom: 90,
                vCenter: 287.25,
                hCenter: 78,
                field: 'remark',
                testData:
                  '我是备注我是备注我是注我备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 102,
                height: 45,
                width: 553.5,
                field: 'printLists',
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                right: 562.5,
                bottom: 137.9999885559082,
                vCenter: 286.5,
                hCenter: 119.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 100,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品条码',
                      field: 'skuBarcode',
                      checked: true,
                      columnId: 'skuBarcode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '含税进价',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '数量',
                      field: 'stockQty',
                      checked: true,
                      columnId: 'stockQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '含税成本',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 142.5,
                top: 154.5,
                height: 15,
                width: 127.5,
                title: '财务：',
                right: 273,
                bottom: 169.4999885559082,
                vCenter: 215.25,
                hCenter: 161.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 291,
                top: 154.5,
                height: 15,
                width: 120,
                title: '物价：',
                right: 409.5,
                bottom: 169.4999885559082,
                vCenter: 350.25,
                hCenter: 161.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 429,
                top: 154.5,
                height: 15,
                width: 138,
                title: '制单',
                right: 544.7499847412109,
                bottom: 169.74999618530273,
                vCenter: 486.99998474121094,
                hCenter: 162.24999618530273,
                field: 'completeUserName',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 154.5,
                height: 15,
                width: 114,
                title: '业务：',
                right: 112.5,
                bottom: 169.4999885559082,
                vCenter: 63,
                hCenter: 161.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
    2: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 280,
          width: 210,
          paperHeader: 94.5,
          paperFooter: 769.5,
          printElements: [
            {
              options: {
                left: 241.5,
                top: 6,
                height: 17,
                width: 120,
                testData: '',
                fontSize: 16.5,
                fontWeight: '700',
                textAlign: 'center',
                hideTitle: true,
                title: '商品验收单',
                right: 361.5,
                bottom: 23,
                vCenter: 301.5,
                hCenter: 14.5,
              },
              printElementType: {
                title: '标题',
                type: 'text',
              },
            },
            {
              options: {
                left: 189,
                top: 27,
                height: 15,
                width: 183,
                title: '验收单号',
                right: 371.25,
                bottom: 42,
                vCenter: 279.75,
                hCenter: 34.5,
                field: 'billNo',
                testData: 'JHC11001202303270004',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 376.5,
                top: 27,
                height: 15,
                width: 190.5,
                title: '打印时间',
                right: 569.25,
                bottom: 42,
                vCenter: 473.25,
                hCenter: 34.5,
                field: 'printTime',
                testData: '2023-04-06',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 27,
                height: 15,
                width: 169.5,
                title: '仓库',
                right: 135,
                bottom: 42,
                vCenter: 75,
                hCenter: 34.5,
                field: 'cargoWarehouseName',
                testData: '01-大仓',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 189,
                top: 46.5,
                height: 15,
                width: 183,
                title: '供应商',
                right: 372,
                bottom: 61.5,
                vCenter: 280.5,
                hCenter: 54,
                field: 'supplierName',
                testData: '01-义务晨兴公司',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 378,
                top: 46.5,
                height: 15,
                width: 189,
                title: '验收日期',
                right: 564.75,
                bottom: 61.5,
                vCenter: 469.5,
                hCenter: 54,
                field: 'completeTime',
                testData: '2023-04-06',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 46.5,
                height: 15,
                width: 169.5,
                title: '采购单号',
                right: 184.5,
                bottom: 61.5,
                vCenter: 99,
                hCenter: 54,
                field: 'billNo',
                testData: 'CGSS20230327000054',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 66,
                height: 24,
                width: 553.5,
                title: '备注',
                right: 561,
                bottom: 90,
                vCenter: 287.25,
                hCenter: 78,
                field: 'remark',
                testData:
                  '我是备注我是备注我是注我备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 102,
                height: 45,
                width: 553.5,
                field: 'printLists',
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                right: 562.5,
                bottom: 137.9999885559082,
                vCenter: 286.5,
                hCenter: 119.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 100,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品条码',
                      field: 'skuBarcode',
                      checked: true,
                      columnId: 'skuBarcode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '含税进价',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '数量',
                      field: 'stockQty',
                      checked: true,
                      columnId: 'stockQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '含税成本',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 142.5,
                top: 154.5,
                height: 15,
                width: 127.5,
                title: '财务：',
                right: 273,
                bottom: 169.4999885559082,
                vCenter: 215.25,
                hCenter: 161.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 291,
                top: 154.5,
                height: 15,
                width: 120,
                title: '物价：',
                right: 409.5,
                bottom: 169.4999885559082,
                vCenter: 350.25,
                hCenter: 161.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 429,
                top: 154.5,
                height: 15,
                width: 138,
                title: '制单',
                right: 544.7499847412109,
                bottom: 169.74999618530273,
                vCenter: 486.99998474121094,
                hCenter: 162.24999618530273,
                field: 'completeUserName',
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
            {
              options: {
                left: 13.5,
                top: 154.5,
                height: 15,
                width: 114,
                title: '业务：',
                right: 112.5,
                bottom: 169.4999885559082,
                vCenter: 63,
                hCenter: 161.9999885559082,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
                qrCodeLevel: 0,
              },
              printElementType: {
                title: '文本',
                type: 'text',
              },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
  },
}

export default {
  // 采购入库单 -- 不同配送中心打印模板名
  purchaseReceiptTempOptions,
  // 采购入库单 -- 不同分公司打印模板名json
  purchaseReceiptTempJsonOptions,
}
