import request from '@/utils/request'

// 积分调整单列表
export function customerRewardOrderPage(data) {
  return request({
    url: '/mg/daohang-order-api/customerRewardOrder/page',
    method: 'post',
    data,
  })
}

// 新增积分调整单
export function customerRewardOrderSave(data) {
  return request({
    url: '/mg/daohang-order-api/customerRewardOrder',
    method: 'put',
    loading: true,
    data,
  })
}

// 积分调整单查询

export function getCustomerRewardOrderById(id) {
  return request({
    url: `/mg/daohang-order-api/customerRewardOrder/${id}`,
    method: 'get',
    loading: true,
  })
}

// 积分调整单状态变更
export function customerRewardOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/customerRewardOrder/examineAndApprove`,
    method: 'post',
    loading: true,
    data,
  })
}

// 删除积分调整单
export function customerRewardOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/customerRewardOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
export function getCustomerRewardSummaryById(id) {
  return request({
    url: `/mg/daohang-order-api/customerRewardSummary/customerId/${id}`,
    method: 'get',
    loading: true,
  })
}
//积分调整单列表导出
export function customerRewardOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/customerRewardOrder/exportExcel`,
    method: 'post',
    loading: true,
    responseType: 'blob',
    data,
  })
}
