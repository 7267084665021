import i18n from '@/locale'
import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/page',
    method: 'post',
    data,
  })
}

export function save(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder',
    method: 'put',
    loading: true,
    data,
  })
}

/**
 * 编辑页面查数据
 * 和查看页面区分使用
 * @param {*} id
 * @returns
 */
export function editView(id) {
  return request({
    url: `/mg/daohang-order-api/saleOrder/editView/${id}`,
    method: 'get',
    loading: true,
  })
}

/**
 * 查看页面查数据
 * 和编辑页面区分使用
 * @param {*} id
 * @returns
 */
export function detail(id) {
  return request({
    url: `/mg/daohang-order-api/saleOrder/edit/${id}`,
    method: 'get',
    loading: true,
  })
}

export function statusUpdate(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/examineAndApprove',
    method: 'post',
    data,
    loading: true,
  })
}

export function pageSum(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/sum',
    method: 'post',
    data,
  })
}

/**
 * 查询最新的结算方式
 * @param {*} id
 * @returns
 */
export function latestSettlementMode(id) {
  return request({
    url: `/mg/daohang-order-api/saleOrder/getSettlementMode/${id}`,
    method: 'get',
    loading: true,
    loadingText: i18n.t('结算方式校验'),
  })
}

/**
 * 更新结算方式为临时赊欠
 * @param {*} data
 * @returns
 */
export function updateSettlementMode(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/updateSettlementMode',
    method: 'post',
    data,
    loading: true,
    loadingText: i18n.t('更新结算方式'),
  })
}

/**
 * 转批发 - 批发订单转批发销货单
 * @param {*} id
 * @returns
 */
export function transferWholesale(id) {
  return request({
    url: `/mg/daohang-order-api/saleOrder/verbalConvert/${id}`,
    method: 'get',
    loading: true,
    loadingText: i18n.t('转批发，请耐心等待'),
  })
}

export function pageExport(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function itemExport(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrderItem/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

/**
 * 更换客户
 * @param {*} data
 * @returns
 */
export function customerChange(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/updateCustomer',
    method: 'post',
    data,
    loading: true,
  })
}

/**
 * 批发缺货记录
 * @param {*} data
 * @returns
 */
export function OOSLog(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrderQhLog/save',
    method: 'put',
    data,
  })
}

/**
 * 查询 0 售价明细
 * @param {*} billNo
 * @returns
 */
export function filterZeroPriceDetails(billNo) {
  return request({
    url: `/mg/daohang-order-api/saleOrderItem/selectSalePrice/${billNo}`,
    method: 'post',
    loading: true,
  })
}
/**
 * 批发缺货列表
 * @param {*} data
 * @returns
 */
export function saleOrderQhLogPage(data) {
  return request({
    url: `/mg/daohang-order-api/saleOrderQhLog/page`,
    method: 'post',
    data,
  })
}
// 结案
export function saleOrderQhLogClosedById(data) {
  return request({
    url: `/mg/daohang-order-api/saleOrderQhLog/closed`,
    method: 'post',
    data,
  })
}
// 批量结案
export function saleOrderQhLogBatchClosedByIds(data) {
  return request({
    url: `/mg/daohang-order-api/saleOrderQhLog/batchClosed`,
    method: 'post',
    data,
  })
}

// 导出批发缺货记录
export function exportSaleOrderQhLogList(data) {
  return request({
    url: `/mg/daohang-order-api/saleOrderQhLog/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}

// 备注修改
export function alterRemark(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/editRemark',
    method: 'post',
    data,
    loading: true,
  })
}

// 获取聚宝盆触发的打印数据
export function postJpbPrintData(data) {
  return request({
    url: '/mg/daohang-order-api/print/getJpbPrintData',
    method: 'post',
    data,
  })
}

export function bulkManage(data) {
  return request({
    url: '/mg/daohang-order-api/saleOrder/batchComplete',
    method: 'post',
    data,
  })
}

/**
 * 查欠量表
 * @param {*} data
 * @returns
 */
export function verbalSaleOrderMiddleList(data) {
  return request({
    url: `/mg/daohang-order-api/verbalSaleOrderMiddle/list`,
    method: 'post',
    data,
    loading: true,
  })
}
