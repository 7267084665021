<template>
  <div class="tabsbar select-none">
    <div class="placeholder-block"></div>
    <el-tabs class="tabs" type="card" @tab-remove="handleRemoveTab" v-model="tabActive" @tab-click="handleTabClick">
      <el-tab-pane
        v-for="route in visiteRoutes"
        :key="route.path"
        :label="setLabel(route.meta)"
        :name="route.path"
        :closable="!isAffix(route)"
      />
    </el-tabs>
    <el-dropdown @command="handleCommand" class="dropdown_tab">
      <span style="font-size: 12px; cursor: pointer">
        {{ $t('更多操作') }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="tabs-more">
        <el-dropdown-item command="closeOthersTabs">
          <i class="el-icon-error"></i>
          {{ $t('关闭其他') }}
        </el-dropdown-item>
        <el-dropdown-item command="closeAllTabs">
          <i class="el-icon-remove"></i>
          {{ $t('关闭全部') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
  import i18n from '@/locale'

  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'TabsBar',
    data() {
      return {
        tabActive: null,
      }
    },
    watch: {
      $route: {
        handler(route) {
          if (this.tabActive !== route.path) {
            this.tabActive = route.path
            this.setTab(route)
          }
        },
        immediate: true,
      },
    },
    computed: {
      ...mapGetters({
        visiteRoutes: 'tabsBar/visiteRoutes',
        changePages: 'tabsBar/changePages',
        lang: 'sys/lang',
      }),
    },
    methods: {
      ...mapActions({
        setTab: 'tabsBar/setTab',
        removeTab: 'tabsBar/removeTab',
        clearTab: 'tabsBar/clear',
        removeOthersTab: 'tabsBar/removeOthersTab',
        setChangePages: 'tabsBar/setChangePages',
      }),
      handleCommand(command) {
        switch (command) {
          case 'closeOthersTabs':
            this.closeOthersTabs()
            break
          case 'closeAllTabs':
            this.closeAllTabs()
            break
        }
      },
      // 关闭其他标签
      async closeOthersTabs() {
        let changePage = this.changePages.filter((item) => item !== this.$route.name)
        if (changePage.length) {
          await this.$mgConfirm(i18n.t('有页面正在编辑，确认关闭?'))
        }
        await this.removeOthersTab(this.$route.path)
        await this.setChangePages(this.changePages.includes(this.$route.name) ? [this.$route.name] : [])
      },
      // 关闭所有标签
      async closeAllTabs() {
        if (this.changePages.length) {
          await this.$mgConfirm(i18n.t('有页面正在编辑，确认关闭?'))
        }
        await this.removeOthersTab('/index')
        await this.setChangePages([])
        await this.$router.push({ path: '/index' })
      },
      setLabel(meta) {
        if (this.lang === 'en-US' && meta?.icon) {
          return meta?.icon
        }
        return meta?.title
      },
      isAffix(tag) {
        return tag.meta?.affix
      },
      async handleRemoveTab(path) {
        const index = this.visiteRoutes.findIndex((item) => item.path === path)
        const delRoute = this.visiteRoutes[index]
        await this.$mgCloseEditPageConfirm(delRoute?.name)
        if (this.tabActive === path) {
          const currentRoute = this.visiteRoutes[index - 1] || this.visiteRoutes[index + 1]
          if (currentRoute) {
            if (this.tabActive !== currentRoute.path) {
              this.handleTabClick({ name: currentRoute.path })
            }
          }
        }
        this.removeTab(delRoute)
      },
      handleTabClick(tab) {
        if (this.$route.path !== tab.name) {
          const route = this.visiteRoutes.find((item) => item.path === tab.name)
          this.$router.push(route)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tabsbar {
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    border-bottom: 1px solid #ddd;
    .tabs {
      width: calc(100% - 150px);
      position: fixed;
      top: $mg-layout-header-height;
      z-index: 1;
    }
    .dropdown_tab {
      position: fixed;
      z-index: 2;
      right: 10px;
      top: calc($mg-layout-header-height + 15px);
    }
    .placeholder-block {
      height: $mg-layout-tabsbar-height;
    }
  }
</style>
<style lang="scss" scoped>
  :deep(.el-tabs__item.is-active) {
    background: $mg-base-hover-color;
  }
  :deep(.el-tabs__header) {
    margin: 0;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__nav) {
    border: none;
  }
  :deep(.el-tabs--card > .el-tabs__header) {
    border-bottom: none;
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item) {
    border-bottom: none;
  }
</style>
