/**
 * 采购退货相关打印变量
 */
const purchaseReturnOrderTempOptions = [
  {
    label: '采购退货单',
    value: 'all',
    children: [{ label: '采购退货单(采购退货单)', value: 1 }],
  },
]
const purchaseReturnOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 87,
          paperFooter: 391.275,
          printElements: [
            {
              options: {
                left: 120,
                top: 19.5,
                height: 17,
                width: 334.5,
                right: 453.75,
                title: '成都晨光文具销售有限公司采购退货单',
                bottom: 36.5,
                hCenter: 28,
                vCenter: 286.5,
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 426,
                top: 42,
                height: 15,
                width: 168,
                field: 'createTime',
                right: 609,
                title: '开单日期',
                bottom: 57,
                hCenter: 49.5,
                vCenter: 525,
                testData: '2023-10-15 19:12:12',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 231,
                top: 43.5,
                height: 15,
                width: 168,
                field: 'supplierName',
                right: 387,
                title: '供应商',
                bottom: 55.5,
                hCenter: 50.625,
                vCenter: 303,
                testData: '8600-义务123519可适当放宽',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 28.5,
                top: 43.5,
                height: 15,
                width: 171,
                field: 'billNo',
                right: 199.5,
                title: '单据编号',
                bottom: 53.25,
                hCenter: 48.375,
                vCenter: 114,
                testData: 'CT0C0000230421000001',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 226.5,
                top: 67.5,
                height: 15,
                width: 316.5,
                field: 'remark',
                right: 402.75,
                title: '退货说明',
                bottom: 82.5,
                hCenter: 75,
                vCenter: 317.25,
                testData: '测试备注',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 30,
                top: 67.5,
                height: 15,
                width: 171,
                field: 'returnWarehouseName',
                right: 196.5,
                title: '仓库',
                bottom: 84,
                hCenter: 76.5,
                vCenter: 111,
                testData: '01-大仓',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 28.5,
                top: 93,
                height: 54,
                width: 550,
                field: 'printLists',
                right: 569.5,
                bottom: 175.5,
                hCenter: 148.5,
                vCenter: 294.5,
                coordinateSync: false,
                widthHeightSync: false,
                tableHeaderFontSize: 9.75,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderFontWeight: 'bold',
                columns: [
                  [
                    {
                      width: 76.08579999999999,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 103.24195000000002,
                      title: '商品货号',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 184.66074999999992,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 75.59725000000002,
                      title: '单位',
                      field: 'stockUnit',
                      checked: true,
                      columnId: 'stockUnit',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 65.08300000000004,
                      title: '单价',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 71.863,
                      title: '数量',
                      field: 'returnQty',
                      checked: true,
                      columnId: 'returnQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 123.46825000000004,
                      title: '退货金额',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '备注',
                      field: 'remark',
                      checked: true,
                      columnId: 'remark',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 129,
                top: 138,
                height: 15,
                width: 81,
                field: 'completeUserName',
                right: 210,
                title: '出库',
                bottom: 152.25,
                hCenter: 144.75,
                vCenter: 169.5,
                testData: '李大川',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 234,
                top: 139.5,
                height: 15,
                width: 82.5,
                field: 'reviewUserName',
                right: 315.75,
                title: '复核',
                bottom: 154.5,
                hCenter: 147,
                vCenter: 274.5,
                testData: '张菲菲',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 28.5,
                top: 139.5,
                height: 15,
                width: 70.5,
                field: 'createUserName',
                right: 96,
                title: '开单',
                bottom: 154.5,
                hCenter: 147,
                vCenter: 62.25,
                testData: '王师傅',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 357,
                top: 141,
                height: 15,
                width: 82.5,
                field: 'totalReturnQty',
                right: 441,
                title: '本单数量',
                bottom: 155.25,
                hCenter: 147.75,
                vCenter: 399.75,
                testData: '72',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 465,
                top: 141,
                height: 15,
                width: 108,
                field: 'totalCostAmt',
                right: 521.25,
                title: '本单金额',
                bottom: 181.5,
                hCenter: 174,
                vCenter: 480,
                testData: '48543.66',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 556.5,
          paperNumberTop: 28.5,
        },
      ],
    },
  },
}

export default {
  // 采购退货单 -- 不同配送中心打印模板名
  purchaseReturnOrderTempOptions,
  // 采购退货单 -- 不同分公司打印模板名json
  purchaseReturnOrderTempJsonOptions,
}
