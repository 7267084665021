import i18n from '@/locale'
import Mousetrap from 'mousetrap'
import { GlobalEventType } from '@/constants'

/**
 * 修饰符只支持 ctrl,shift,alt,meta
 * MacOS 可用 option 替代 alt, command 替代 meta
 * 特殊键: backspace, tab, enter, return, capslock, esc, escape, space, pageup, pagedown, end, home, left, up, right, down, ins, del, plus
 */
export const KeyboardActions = [
  {
    label: i18n.t('新增'),
    value: 'f1',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.ADD)
      return false
    },
  },

  {
    label: i18n.t('保存'),
    value: 'f4',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.SAVE)
      return false
    },
  },

  {
    label: i18n.t('审核'),
    value: 'f9',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.AUDIT)
      return false
    },
  },

  {
    label: i18n.t('入账'),
    value: 'f6',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.RECORDING)
      return false
    },
  },

  {
    label: i18n.t('打印'),
    value: 'f11',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.PRINT)
      return false
    },
  },

  {
    label: i18n.t('快速添加'),
    value: 'f8',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.FAST_ADD)
      return false
    },
  },

  {
    label: i18n.t('清空明细'),
    value: 'f7',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.CLEAR_ITEMS)
      return false
    },
  },

  {
    label: i18n.t('取消/返回'),
    value: 'esc',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.CANCEL)
      return false
    },
  },

  {
    label: i18n.t('更换客户'),
    value: 'f3',
    handler() {
      this.$GlobalEventBus.emit(GlobalEventType.CUSTOMER_CHANGE)
      return false
    },
  },
]

const install = (Vue) => {
  KeyboardActions.forEach((item) => {
    Mousetrap.bind(item.value, item.handler.bind(Vue.prototype))
  })
}
export default install
