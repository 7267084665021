import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.71.38' : '/'
//库存查询分页
export function accountCleanPlanLogQuerty(data) {
  return request({
    url: `/mg/daohang-pay-api/accountCleanPlanLog/page`,
    method: 'post',
    data,
  })
}
//库存查询导出
export function accountCleanPlanLogExportExcel(data) {
  return request({
    url: `/mg/daohang-pay-api/accountCleanPlanLog/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//库存查询记录
export function accountCleanPlanLogCancel(data) {
  return request({
    url: `/mg/daohang-pay-api/accountCleanPlanLog/cancel`,
    method: 'post',
    data,
  })
}

//库存查询合计
export function accountCleanPlanLogSum(data) {
  return request({
    url: `/mg/daohang-pay-api/accountCleanPlanLog/sum`,
    method: 'post',
    data,
  })
}

// 应收账款清理记录
export function accountCleanPlanLogList(data) {
  return request({
    url: `/mg/daohang-pay-api/accountCleanPlanLog/list`,
    method: 'post',
    data,
  })
}
