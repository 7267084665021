/**
 * 收款单相关打印变量
 */
const receiptVoucherPrintTempOptions = [
  {
    label: '收款单',
    value: 'all',
    children: [{ label: '收款单', value: 1 }],
  },
]
const receiptVoucherPrintTempJsonOptions = {
  // 郑州管城区配送中心
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 61.5,
          paperFooter: 387.49821428571425,
          printElements: [
            {
              options: {
                left: 241.5,
                top: 6,
                height: 17,
                width: 120,
                right: 361.5,
                title: '批发延期收款单',
                bottom: 23,
                hCenter: 14.5,
                vCenter: 301.5,
                fontSize: 16.5,
                testData: '',
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 27,
                height: 15,
                width: 183,
                field: 'createTime',
                right: 371.25,
                title: '开单日期',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 279.75,
                testData: 'JHC11001202303270004',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 376.5,
                top: 27,
                height: 15,
                width: 190.5,
                field: 'printTime',
                right: 569.25,
                title: '打印时间',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 473.25,
                testData: '2023-04-06',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 27,
                height: 15,
                width: 169.5,
                field: 'billNo',
                right: 183,
                title: '收款单号',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 98.25,
                testData: '01-大仓',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 46.5,
                height: 15,
                width: 183,
                field: 'usedPrepayAmt',
                right: 372.75,
                title: '合计预收款',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 281.25,
                testData: '999.00',
                formatter:
                  "function(value,options, templateData) {return (Number(options ) === 0 ? '0' : options)+ '元'}",
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 46.5,
                height: 15,
                width: 169.5,
                field: 'customerName',
                right: 184.5,
                title: '批发客户',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 99,
                testData: 'CGSS20230327000054',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 67.5,
                height: 60,
                width: 553.5,
                field: 'printLists',
                right: 570,
                bottom: 127.5,
                hCenter: 97.5,
                vCenter: 293.25,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 100,
                      title: '批发销货单号',
                      field: 'sourceNo',
                      checked: true,
                      columnId: 'sourceNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummaryText: '合计',
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '批发销货日期',
                      field: 'completeTime',
                      checked: true,
                      columnId: 'completeTime',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      formatter2:
                        "function(value,row,index, options){ return window.$dayjs(row.completeTime).format('YYYY-MM-DD HH:mm:ss') }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '延期收款日期',
                      field: 'printTime',
                      checked: true,
                      columnId: 'printTime',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      formatter2:
                        "function(value,row,index, options){ return window.$dayjs(Date.now()).format('YYYY-MM-DD') }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '延期应收金额',
                      field: 'receivableAmt',
                      checked: true,
                      columnId: 'receivableAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryAlign: 'right',
                      tableSummaryNumFormat: '2',
                    },
                    {
                      width: 100,
                      title: '收款金额',
                      field: 'receiveAmt',
                      checked: true,
                      columnId: 'receiveAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryAlign: 'right',
                      tableSummaryNumFormat: '2',
                    },
                    {
                      width: 100,
                      title: '含税进价',
                      field: 'costAmt',
                      checked: false,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 13.5,
                top: 127.5,
                height: 15,
                width: 114,
                right: 127.5,
                title: '业务：',
                bottom: 145.5,
                hCenter: 138,
                vCenter: 70.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 214.5,
                top: 127.5,
                height: 15,
                width: 127.5,
                right: 341.25,
                title: '财务：',
                bottom: 142.5,
                hCenter: 135,
                vCenter: 277.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 427.5,
                top: 129,
                height: 15,
                width: 138,
                right: 544.7499847412109,
                title: '制单',
                bottom: 169.7499961853027,
                hCenter: 162.2499961853027,
                vCenter: 486.99998474121094,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
  },
}

export default {
  // 收款单 -- 不同配送中心打印模板名
  receiptVoucherPrintTempOptions,
  // 收款单 -- 不同分公司打印模板名json
  receiptVoucherPrintTempJsonOptions,
}
