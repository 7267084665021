import { noticeConstrain, noticeRemind } from '@/api/set/notice'
const state = () => ({
  voiceNoticeList: [], //语音播放列表
  dialogNoticeList: [], //弹框列表
  pullInterval: null, //轮询时间
  noticeRemindTotalNums: 0, //未读消息
  noticeRemindAnnouncementNums: 0, //未读公告
  noticeRemindServiceNums: 0, //未读业务提醒
})

const mutations = {
  SET_VOICE_NOTICE_LIST(state, { list }) {
    state.voiceNoticeList = list
  },
  REMOVE_VOICE_NOTICE(state, index) {
    state.voiceNoticeList.splice(index, 1)
  },
  SET_DIALOG_NOTICE_LIST(state, { list }) {
    state.dialogNoticeList = list
  },

  REMOVE_DIALOG_NOTICE(state, index) {
    state.dialogNoticeList.splice(index, 1)
  },
  SET_PULL_INTERVAL(state, interval) {
    state.pullInterval = interval
  },
  SET_NOTICE_REMIND_TOTAL_NUMS(state, noticeRemindTotalNums) {
    state.noticeRemindTotalNums = noticeRemindTotalNums
  },
  SET_NOTICE_REMIND_ANNOUNCEMENT_NUMS(state, noticeRemindAnnouncementNums) {
    state.noticeRemindAnnouncementNums = noticeRemindAnnouncementNums
  },
  SET_NOTICE_REMIND_SERVICE_NUMS(state, noticeRemindServiceNums) {
    state.noticeRemindServiceNums = noticeRemindServiceNums
  },
}

const actions = {
  /**
   * 当前登录人通知列表
   * @param {*} payload
   * @returns
   */
  async FETCH_NOTICE_LIST({ commit, rootGetters }) {
    // 发起 http 请求
    const { data } = await noticeConstrain({ userCreateTime: rootGetters['user/createTime'], noticeSystem: 'DH' })
    let voiceNoticeList = data.notices && data.notices.filter((item) => item.playAudio)
    let dialogNoticeList = data.notices && data.notices.filter((item) => item.displayPopUp)

    commit('SET_VOICE_NOTICE_LIST', { list: voiceNoticeList })
    commit('SET_DIALOG_NOTICE_LIST', { list: dialogNoticeList })
    commit('SET_PULL_INTERVAL', data.pullInterval) // 存储 pullInterval
    return { data }
  },
  async FETCH_NOTICE_REMINDNUMS({ commit, rootGetters }) {
    // 发起 http 请求
    const { data } = await noticeRemind({ userCreateTime: rootGetters['user/createTime'], noticeSystem: 'DH' })
    const noticeRemindTotalNums = Object.values(data).reduce((acc, curr) => acc + curr, 0)

    commit('SET_NOTICE_REMIND_TOTAL_NUMS', noticeRemindTotalNums)
    commit('SET_NOTICE_REMIND_ANNOUNCEMENT_NUMS', data.ANNOUNCEMENT)
    commit('SET_NOTICE_REMIND_SERVICE_NUMS', data.SERVICE_REMINDING)
    return { data }
  },
  removeVoiceNotice({ commit }, index) {
    commit('REMOVE_VOICE_NOTICE', index)
  },
  removeDialogNotice({ commit }, index) {
    commit('REMOVE_DIALOG_NOTICE', index)
  },
}
const getters = {
  voiceNoticeList: (state) => state.voiceNoticeList,
  dialogNoticeList: (state) => state.dialogNoticeList,
  pullInterval: (state) => state.pullInterval,
  noticeRemindTotalNums: (state) => state.noticeRemindTotalNums, //
  noticeRemindAnnouncementNums: (state) => state.noticeRemindAnnouncementNums,
  noticeRemindServiceNums: (state) => state.noticeRemindServiceNums,
}
export default { state, mutations, actions, getters }
