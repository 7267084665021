const saleOrderTempOptions = [
  {
    label: '批发销货单',
    value: 'all',
    children: [
      { label: '批发销货单', value: 1 },
      { label: '小票', value: 2 },
    ],
  },
]
const saleOrderTempJsonOptions = {
  all: {
    1: {
      "panels": [
        {
          "index": 0,
          "name": 1,
          "height": 141,
          "width": 210,
          "paperHeader": 121.5,
          "paperFooter": 387.49821428571425,
          "printElements": [
            {
              "options": {
                "left": 16.5,
                "top": 4.5,
                "height": 36,
                "width": 36,
                "right": 51,
                "bottom": 42,
                "vCenter": 33,
                "hCenter": 24,
                "src": "https://sh-oss-mg.oss-cn-shanghai.aliyuncs.com/img/logo.jpg",
                "fit": ""
              },
              "printElementType": {
                "title": "图片",
                "type": "image"
              }
            },
            {
              "options": {
                "left": 240,
                "top": 12,
                "height": 17,
                "width": 120,
                "right": 361.5,
                "title": "晨光文具批发单",
                "bottom": 23,
                "hCenter": 14.5,
                "vCenter": 301.5,
                "fontSize": 16.5,
                "hideTitle": true,
                "textAlign": "center",
                "fontWeight": "700",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false
              },
              "printElementType": {
                "title": "标题",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 42,
                "height": 27,
                "width": 170,
                "field": "barcode",
                "right": 138.75,
                "title": "文本",
                "bottom": 42.75,
                "hCenter": 37.875,
                "vCenter": 78.75,
                "testData": "123",
                "textType": "barcode",
                "hideTitle": true,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 325.5,
                "top": 42,
                "height": 22.5,
                "width": 61.5,
                "field": "totalGiftQty",
                "right": 333,
                "title": "文本",
                "bottom": 55.5,
                "hCenter": 44.25,
                "vCenter": 302.25,
                "fontSize": 12,
                "testData": "123",
                "formatter": "function(value, options) { return options > 0?'本单含赠品':''}",
                "hideTitle": true,
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 280.5,
                "top": 70.5,
                "height": 15,
                "width": 183,
                "field": "createTime",
                "right": 371.25,
                "title": "录单日期",
                "bottom": 42,
                "hCenter": 34.5,
                "vCenter": 279.75,
                "testData": "2023-04-19",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 70.5,
                "height": 15,
                "width": 210,
                "field": "billNo",
                "right": 135,
                "title": "单号",
                "bottom": 42,
                "hCenter": 34.5,
                "vCenter": 75,
                "testData": "10000000000000000",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 487.5,
                "top": 70.5,
                "height": 15,
                "width": 96,
                "field": "settlementMode",
                "right": 569.25,
                "title": "结算方式",
                "bottom": 42,
                "hCenter": 34.5,
                "vCenter": 473.25,
                "testData": "现款现货",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 15,
                "top": 90,
                "height": 9,
                "width": 570
              },
              "printElementType": {
                "title": "横线",
                "type": "hline"
              }
            },
            {
              "options": {
                "left": 280.5,
                "top": 96,
                "height": 15,
                "width": 114,
                "field": "contactPhone",
                "right": 468,
                "title": "电话",
                "bottom": 103.5,
                "hCenter": 96,
                "vCenter": 376.5,
                "testData": "13112331233",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 459,
                "top": 96,
                "height": 15,
                "width": 126,
                "field": "receivableBalanceAmt",
                "right": 591,
                "title": "前单欠款",
                "bottom": 102,
                "hCenter": 95.25,
                "vCenter": 496.5,
                "testData": "0.58",
                "formatter": "function(value, options){return (options == 0 ? '0' : options)}",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 96,
                "height": 15,
                "width": 232.5,
                "field": "customerName",
                "right": 184.5,
                "title": "客户",
                "bottom": 61.5,
                "hCenter": 54,
                "vCenter": 99,
                "testData": "123-客户名称",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 280.5,
                "top": 112.5,
                "height": 15,
                "width": 189,
                "field": "printTime",
                "right": 474,
                "title": "打印时间",
                "bottom": 115.5,
                "hCenter": 108,
                "vCenter": 379.5,
                "testData": "2023-04-06",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 18,
                "top": 136.5,
                "height": 42,
                "width": 565.5,
                "field": "printLists",
                "textAlign": "center",
                "coordinateSync": false,
                "widthHeightSync": false,
                "groupFooterFormatter": "function(t,e){return\"这里自定义统计脚信息\";}",
                "columns": [
                  [
                    {
                      "width": 53.03095,
                      "title": "行号",
                      "field": "index",
                      "checked": true,
                      "columnId": "index",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "left",
                      "halign": "center",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 91.844275,
                      "title": "货号",
                      "field": "articleNo",
                      "checked": true,
                      "columnId": "articleNo",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "halign": "center",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 305.46977499999997,
                      "title": "品名",
                      "field": "skuName",
                      "checked": true,
                      "columnId": "skuName",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 85.76874999999995,
                      "title": "规格",
                      "field": "specsDesp",
                      "checked": true,
                      "columnId": "specsDesp",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 59.32675000000016,
                      "title": "包装",
                      "field": "packageConversion",
                      "checked": true,
                      "columnId": "packageConversion",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    },
                    {
                      "width": 78.92274999999987,
                      "title": "数量",
                      "field": "saleQty",
                      "checked": true,
                      "columnId": "saleQty",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    },
                    {
                      "width": 90.85225000000008,
                      "title": "单价",
                      "field": "receivablePrice",
                      "checked": true,
                      "columnId": "receivablePrice",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 83.97249999999991,
                      "title": "金额",
                      "field": "receivableAmt",
                      "checked": true,
                      "columnId": "receivableAmt",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 50.81200000000004,
                      "title": "赠送",
                      "field": "ifGift",
                      "checked": true,
                      "columnId": "ifGift",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "formatter2": "function(value, row){return row.ifGift ? '是' :''}",
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 254.637025,
                      "title": "品名",
                      "field": "skuCode",
                      "checked": false,
                      "columnId": "skuCode",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": "30",
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0,
                      "tableSummaryTitle": true,
                      "tableSummary": ""
                    },
                    {
                      "width": 60.830500000000114,
                      "title": "包装",
                      "checked": false,
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    },
                    {
                      "width": 88.69974999999995,
                      "title": "数量",
                      "checked": false,
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    },
                    {
                      "width": 76.60974999999999,
                      "title": "单价",
                      "checked": false,
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    },
                    {
                      "width": 92.5584999999999,
                      "title": "金额",
                      "checked": false,
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    },
                    {
                      "width": 100,
                      "title": "姓名",
                      "field": "name",
                      "checked": false,
                      "columnId": "name",
                      "fixed": false,
                      "rowspan": 1,
                      "colspan": 1,
                      "align": "left",
                      "tableColumnHeight": 30,
                      "tableTextType": "text",
                      "tableBarcodeMode": "CODE128A",
                      "tableQRCodeLevel": 0
                    }
                  ]
                ]
              },
              "printElementType": {
                "title": "表格",
                "type": "table",
                "editable": true,
                "columnDisplayEditable": true,
                "columnDisplayIndexEditable": true,
                "columnTitleEditable": true,
                "columnResizable": true,
                "columnAlignEditable": true,
                "isEnableEditField": true,
                "isEnableContextMenu": true,
                "isEnableInsertRow": true,
                "isEnableDeleteRow": true,
                "isEnableInsertColumn": true,
                "isEnableDeleteColumn": true,
                "isEnableMergeCell": true
              }
            },
            {
              "options": {
                "left": 145.5,
                "top": 180,
                "height": 15,
                "width": 127.5,
                "field": "totalRefSaleAmt",
                "right": 273,
                "title": "原金额",
                "bottom": 169.4999885559082,
                "hCenter": 161.9999885559082,
                "vCenter": 215.25,
                "testData": "100",
                "formatter": "function(value, options){return (options == 0 ? '0' : options)}",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 298.5,
                "top": 180,
                "height": 15,
                "width": 120,
                "field": "totalReceivableReduceAmt",
                "right": 414,
                "title": "优惠金额",
                "bottom": 226.5,
                "hCenter": 219,
                "vCenter": 354,
                "testData": "10",
                "formatter": "function(value, options){return (options == 0 ? '0' : options)}",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 180,
                "height": 15,
                "width": 114,
                "field": "totalQty",
                "right": 112.5,
                "title": "总数量",
                "bottom": 169.4999885559082,
                "hCenter": 161.9999885559082,
                "vCenter": 63,
                "testData": "100",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 208.5,
                "height": 15,
                "width": 358.5,
                "field": "totalReceivableAmt",
                "right": 152.25,
                "title": "合计大写",
                "bottom": 214.5,
                "hCenter": 207,
                "vCenter": 83.25,
                "fontSize": 12,
                "testData": "13455.56",
                "upperCase": "7",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 397.5,
                "top": 208.5,
                "height": 15,
                "width": 183,
                "field": "totalReceivableAmt",
                "right": 418.5,
                "title": "应收金额",
                "bottom": 214.5,
                "hCenter": 207,
                "vCenter": 358.5,
                "fontSize": 12,
                "testData": "13455.56",
                "formatter": "function(value,options){return '¥'+(options == 0 ? '0' : options)}",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 18,
                "top": 232.5,
                "height": 9,
                "width": 565.5
              },
              "printElementType": {
                "title": "横线",
                "type": "hline"
              }
            },
            {
              "options": {
                "left": 339,
                "top": 235.5,
                "height": 15,
                "width": 114,
                "right": 378,
                "title": "客服电话：13312345678",
                "bottom": 241.5,
                "hCenter": 234,
                "vCenter": 321,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 235.5,
                "height": 15,
                "width": 312,
                "right": 129.99609375,
                "title": "地址：上海市松江区",
                "bottom": 252.99609375,
                "hCenter": 245.49609375,
                "vCenter": 72.99609375,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 468,
                "top": 235.5,
                "height": 15,
                "width": 114,
                "right": 471,
                "title": "订货热线：021-445888",
                "bottom": 240.75,
                "hCenter": 233.25,
                "vCenter": 414,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 252,
                "height": 15,
                "width": 565.5,
                "right": 328.74609375,
                "title": "注：自提客户请自行核实数量，出门概不负责。发货客户请提货核实数量如有疑问三日内回馈，逾期我司不承担任何责任",
                "bottom": 268.74609375,
                "hCenter": 261.24609375,
                "vCenter": 172.74609375,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 279,
                "height": 15,
                "width": 564,
                "field": "deliveryAddress",
                "right": 327.24609375,
                "title": "送货地址",
                "bottom": 285.24609375,
                "hCenter": 277.74609375,
                "vCenter": 171.24609375,
                "testData": "上海市松江区",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 300,
                "height": 15,
                "width": 562.5,
                "right": 327.24609375,
                "title": "收货日期/收货件数/客户签字：",
                "bottom": 308.49609375,
                "hCenter": 300.99609375,
                "vCenter": 171.24609375,
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 321,
                "height": 15,
                "width": 561,
                "field": "remark",
                "right": 327.24609375,
                "title": "备注",
                "bottom": 308.49609375,
                "hCenter": 300.99609375,
                "vCenter": 171.24609375,
                "testData": "这里是备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注备注",
                "fontWeight": "bold",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 351,
                "height": 15,
                "width": 114,
                "field": "billNo",
                "right": 130.5,
                "title": "文本",
                "bottom": 366,
                "hCenter": 358.5,
                "vCenter": 73.5,
                "testData": "1000000000000",
                "hideTitle": true,
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            },
            {
              "options": {
                "left": 16.5,
                "top": 369,
                "height": 15,
                "width": 312,
                "field": "createUserName",
                "right": 326.49609375,
                "title": "制单人",
                "bottom": 384.24609375,
                "hCenter": 376.74609375,
                "vCenter": 170.49609375,
                "testData": "张三",
                "qrCodeLevel": 0,
                "coordinateSync": false,
                "widthHeightSync": false,
                "textContentVerticalAlign": "middle"
              },
              "printElementType": {
                "title": "文本",
                "type": "text"
              }
            }
          ],
          "paperNumberLeft": 553.5,
          "paperNumberTop": 18
        }
      ]
    },
    2: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 349,
          width: 57,
          paperHeader: 76.5,
          paperFooter: 333.13636363636357,
          printElements: [
            {
              options: {
                left: 12,
                top: 6,
                height: 18,
                width: 136.5,
                right: 146.25,
                title: '小票模板标题',
                bottom: 23.9912109375,
                hCenter: 14.9912109375,
                vCenter: 78,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 24,
                height: 15,
                width: 154.5,
                field: 'billNo',
                right: 146.24147415161133,
                title: '单号',
                bottom: 38.991485595703125,
                hCenter: 31.491485595703125,
                vCenter: 79.49147415161133,
                fontSize: 8,
                testData: 'CGSS20230327000054',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 39,
                height: 15,
                width: 154.5,
                field: 'customerName',
                right: 168,
                title: '客户',
                bottom: 53.9999885559082,
                hCenter: 46.4999885559082,
                vCenter: 88.5,
                fontSize: 8,
                testData: '9999-大客户',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 57,
                height: 15,
                width: 154.5,
                field: 'createTime',
                right: 164.99147415161133,
                title: '开单日期',
                bottom: 72.0000114440918,
                hCenter: 64.5000114440918,
                vCenter: 89.99147415161133,
                fontSize: 8,
                testData: '2023-05-19 14:33',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 10.5,
                top: 81,
                height: 42,
                width: 160.5,
                field: 'printLists',
                right: 162.75,
                bottom: 122.9999885559082,
                hCenter: 101.9999885559082,
                vCenter: 84.75,
                fontSize: 8,
                rowStyler: "function(value, options){ return { 'margin-bottom': '4px' } }",
                tableBorder: 'noBorder',
                coordinateSync: false,
                widthHeightSync: false,
                tableFooterBorder: 'noBorder',
                tableHeaderBorder: 'noBorder',
                tableHeaderRepeat: 'page',
                tableBodyRowBorder: 'bottomBorder',
                tableBodyCellBorder: 'noBorder',
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息"}',
                tableFooterCellBorder: 'noBorder',
                tableHeaderBackground: '#ffffff',
                tableHeaderCellBorder: 'noBorder',
                columns: [
                  [
                    {
                      width: 23.597349999999977,
                      title: '编码/名称',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      renderFormatter:
                        'function(value,row,index,options){ return row.articleNo ? (`<div style="position: relative;padding-right: 0px;">${ row.articleNo }<span style="position:absolute; top:-13pt;left: -4pt;transform: scale(0.95);white-space: nowrap;">${ row.skuName }</span></div>` ) : \'\' }',
                      styler2:
                        "function(value,row,index,options){ return { 'position': 'relative', 'left': '0', 'padding': '14pt 0px 4pt 0pt', 'white-space': row.articleNo && row.articleNo.length <= 5 ? 'nowrap' : 'pre-wrap', 'max-width': '50pt', 'transform': 'scale(0.9)' } }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 275.56082499999997,
                      title: '单价  数量  金额',
                      field: 'receivablePrice',
                      checked: true,
                      columnId: 'receivablePrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      renderFormatter:
                        "function(value,row,index,options){ return `${ row?.receivablePrice || '' } / ${ row?.saleQty || '' } / ${ row?.receivableAmt || '' }` }",
                      styler2:
                        "function(value,row,index,options){ return { 'position': 'relative','padding-top': '8pt', 'padding-right': '0', 'max-width': '30pt', 'white-space':'pre-wrap','transform': 'translate3d(-7pt, 1pt, 0) scale(0.9)' } }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 115.2210249999999,
                      title: '金额',
                      field: 'receivableAmt',
                      checked: false,
                      columnId: 'receivableAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      styler2:
                        "function(value,row,index,options){ return { 'position': 'relative','padding-top': '8pt','padding-right': '0','max-width': '30pt', 'white-space':'pre-wrap','transform': 'scale(0.9)' } }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 96.76134999999996,
                      title: '数量',
                      field: 'saleQty',
                      checked: false,
                      columnId: 'saleQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      styler2:
                        "function(value,row,index,options){ return { 'position': 'relative','padding-top': '8pt','padding-right': '0','max-width': '30pt', 'white-space':'pre-wrap','transform': 'scale(0.9)' } }",
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 13.5,
                top: 135,
                height: 15,
                width: 153,
                field: 'completeUserName',
                right: 152.99788284301758,
                title: '开单员',
                bottom: 176.24787139892578,
                hCenter: 168.74787139892578,
                vCenter: 83.24788284301758,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 156,
                height: 13.5,
                width: 57,
                field: 'totalQty',
                right: 68.24788284301758,
                title: '合计',
                bottom: 195,
                hCenter: 188.25,
                vCenter: 39.74788284301758,
                testData: '150',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 66,
                top: 156,
                height: 15,
                width: 102,
                field: 'totalReceivableAmt',
                right: 144.75,
                title: '合计金额',
                bottom: 197.9999885559082,
                hCenter: 190.4999885559082,
                vCenter: 101.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 175.5,
                height: 33,
                width: 151.5,
                right: 156,
                title: '配送中心电话：0731-85713222  QQ号：1135831465  微信：mg1135831465',
                bottom: 242.9999885559082,
                hCenter: 221.9999885559082,
                vCenter: 84,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12,
                top: 216,
                height: 67.5,
                width: 154.5,
                right: 182.25,
                title:
                  '温馨提示：特价产品如无质量问题，不接受退换货！本单产品及价格如有疑问，请在三天内及时联系，逾期自负！正价商品，如需退换货，请于开单日期起三个月内联系配送中心，谢谢合作！',
                bottom: 308.9999957084656,
                hCenter: 275.2499957084656,
                vCenter: 105,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 132,
          paperNumberTop: 18,
          paperNumberDisabled: true,
          panelPageRule: 'none',
          leftOffset: -10,
        },
      ],
    },
  },
}

export default {
  saleOrderTempOptions,
  saleOrderTempJsonOptions,
}
