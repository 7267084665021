import request from '@/utils/request'

// 门店补货单列表
export function getReplenishmentList(data) {
  return request({
    url: '/mg/daohang-order-api/distributionRequestOrder/page',
    method: 'post',
    data,
  })
}

// 门店补货单列表合计
export function getReplenishmentListSum(data) {
  return request({
    url: '/mg/daohang-order-api/distributionRequestOrder/sumAmt',
    method: 'post',
    data,
  })
}

// 门店补货单保存、编辑
export function saveDistributionRequestOrder(data) {
  return request({
    url: '/mg/daohang-order-api/distributionRequestOrder',
    method: 'put',
    loading: true,
    data,
  })
}

// 门店补货单删除
export function removeReplenishmentList(data) {
  return request({
    url: '/mg/daohang-order-api/distributionRequestOrder',
    method: 'delete',
    data,
  })
}

// 门店补货单删除子表
export function removeReplenishmentListItem(data) {
  return request({
    url: '/mg/daohang-order-api/distributionRequestOrderItem',
    method: 'delete',
    data,
  })
}

// 门店补货单审核 - 反审核 -入账
export function auditReplenishmentList(data) {
  return request({
    url: '/mg/daohang-order-api/distributionRequestOrder/examineAndApprove',
    method: 'post',
    loading: true,
    data,
  })
}

// 门店补货单查看
export function getReplenishmentListDetail(id) {
  return request({
    url: `/mg/daohang-order-api/distributionRequestOrder/${id}`,
    method: 'get',
  })
}

// 门店补货单导出
export function exportReplenishmentListExcel(data, onDownloadProgress) {
  return request({
    url: `/mg/daohang-order-api/distributionRequestOrder/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
    onDownloadProgress,
  })
}
// 可订货商品导出
export function exportExcelByOrderGoodsExcel(data, onDownloadProgress) {
  return request({
    url: `/mg/daohang-order-api/distributionRequestOrder/exportExcelByOrderGoods`,
    method: 'post',
    data,
    responseType: 'blob',
    onDownloadProgress,
  })
}

// 门店补货单单据明细导出
export function exportReplenishmentListItemExcel(data) {
  return request({
    url: `/mg/daohang-order-api/distributionRequestOrderItem/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 查询要货规则
export function getPurchasingRules() {
  return request({
    url: `/mg/daohang-order-api/distributionRequestOrder/purchasingRules`,
    method: 'get',
  })
}
