import request from '@/utils/request'

// 返点调整单列表
export function getReturnPointList(params) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrder/page',
    method: 'get',
    params,
  })
}

// 返点调整单保存
export function saveReturnPoint(data) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrder',
    method: 'put',
    loading: true,
    data,
  })
}
// 返点调整单编辑
export function editReturnPoint(data) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrder/update',
    method: 'post',
    loading: true,
    data,
  })
}

// 返点调整单删除
export function removeReturnPoint(data) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrder',
    method: 'delete',
    data,
  })
}

// 返点调整单删除子表
export function removeReturnPointItem(data) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrderItem',
    method: 'delete',
    data,
  })
}

// 返点调整单审核
export function auditReturnPoint(data) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}

// 返点调整单入账
export function intoAccountReturnPoint(data) {
  return request({
    url: '/mg/daohang-order-api/ratioAuditOrder/intoAccount',
    method: 'post',
    loading: true,
    data,
  })
}

// 返点调整单查看
export function getReturnPointDetail(params) {
  return request({
    url: `/mg/daohang-order-api/ratioAuditOrder/itemList`,
    method: 'get',
    params,
  })
}

// 返点调整单查看
export function exportReturnPointExcel(data) {
  return request({
    url: `/mg/daohang-order-api/ratioAuditOrder/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 快速添加客户
export function getCustomerInfoList(params) {
  return request({
    url: `/mg/daohang-order-api/ratioAuditOrder/getCustomerInfo`,
    method: 'get',
    params,
  })
}
