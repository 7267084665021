<template>
  <el-menu-item v-if="!isHasChildren" :index="menu.path" class="truncate">{{ menuName }}</el-menu-item>
  <el-submenu v-else :index="menu.path">
    <template slot="title">{{ menuName }}</template>
    <template v-for="submenu in menu.children">
      <SubMenu :menu="submenu" :key="submenu.path" v-if="!isHidden(submenu)" />
    </template>
  </el-submenu>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SubMenu',
    props: {
      menu: Object,
    },
    computed: {
      ...mapGetters({
        lang: 'sys/lang',
      }),
      isHasChildren() {
        return this?.menu?.children?.length > 0
      },
      menuName() {
        if (this.lang === 'en-US' && this.menu?.meta?.icon) {
          return this.menu?.meta?.icon
        }
        return this.menu?.meta?.title
      },
    },
    methods: {
      isHidden(menu) {
        return menu?.meta?.hidden
      },
    },
  }
</script>

<style></style>
