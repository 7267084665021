/**
 * 聚合各模块下打印常量
 * - 这里没把具体文件中的变量拆出来做聚合, 是为了数据隔离, 避免各自模块下的变量命名重复
 * - 以common模块, 示例如下：
 * - import modules from "config"
 * - const demoField = modules.common.×××
 */
const modules = {}
const configFun = () => {
    const files = require.context('.', false, /\.js$/)
    files.keys().forEach((key) => {
        const fileName = key.replace(/(\.\/|\.js)/g, '')
        if (fileName !== 'index') {
            modules[fileName] = files(key).default
        }
    })
}
configFun()
export default modules