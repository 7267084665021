import request from '@/utils/request'

// 授信列表
export function customerCreditOrderPage(data) {
  return request({
    url: '/mg/daohang-pay-api/customerCreditOrder/page',
    method: 'post',
    data,
  })
}

// 新增授信
export function customerCreditOrderSave(data) {
  return request({
    url: '/mg/daohang-pay-api/customerCreditOrder',
    method: 'post',
    loading: true,
    data,
  })
}

// 授信查询

export function getCustomerCreditOrderSaveById(id) {
  return request({
    url: `/mg/daohang-pay-api/customerCreditOrder/${id}`,
    method: 'get',
    loading: true,
  })
}

// 授信状态变更
export function customerCreditOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-pay-api/customerCreditOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}

// 删除授信
export function customerCreditOrderDelete(data) {
  return request({
    url: `/mg/daohang-pay-api/customerCreditOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
//预收款单批量改状态
export function customerCreditOrderBatchStatusChange(data) {
  return request({
    url: `/mg/daohang-pay-api/customerCreditOrder/batchStatusChange`,
    method: 'post',
    data,
  })
}
// 授信列表
export function customerCreditOrderSum(data) {
  return request({
    url: '/mg/daohang-pay-api/customerCreditOrder/sumAmt',
    method: 'post',
    data,
  })
}

//列表导出
export function customerCreditOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-pay-api/customerCreditOrder/exportExcel`,
    method: 'post',
    loading: true,
    responseType: 'blob',
    data,
  })
}
