import request from '@/utils/request'

// 列表
export function getPrePaymentOrderPage(data) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder/page`,
    method: 'post',
    data,
  })
}

// 新增
export function prePaymentOrderSaveOrUpdate(data) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder/saveOrUpdate`,
    method: 'put',
    loading: true,
    data,
  })
}

// 详情
export function getPrePaymentOrderById(id) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
// 审核
export function prePaymentOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}

export function prePaymentOrderDelete(data) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}

export function prePaymentOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function prePaymentOrderSum(data) {
  return request({
    url: `/mg/daohang-pay-api/prePaymentOrder/sum`,
    method: 'post',
    data,
  })
}
