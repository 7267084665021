import { employeeList } from '@/api/permission'
const state = () => ({
  employeeList: [],
})

const mutations = {
  SET_EMPLOYEE_LIST(state, { list }) {
    state.employeeList = list
  },
}

const actions = {
  /**
   * 当前登录人所属往来单位下所有启用状态的员工列表
   * @param {*} payload
   * @returns
   */
  async FETCH_EMPLOYEE_LIST({ commit, state }) {
    // 如果存在则不再发起请求
    if (state.employeeList.length > 0) return { data: state.employeeList }
    // 发起 http 请求
    const { data: list } = await employeeList({ employeeStatus: 'ENABLE' })
    // mutation
    commit(`SET_EMPLOYEE_LIST`, { list })
    return { data: list }
  },
}

export default { state, mutations, actions }
