import request from '@/utils/request'
import { tokenName } from '@/config'

export function login(data) {
  return request({
    url: '/mg/drp-user-api/userAccount/login',
    method: 'post',
    data,
  })
}

export function getUserInfo(accessToken) {
  return request({
    url: '/mg/drp-user-api/employee/detail/DISTRIBUTION_CENTRE',
    method: 'get',
    data: {
      [tokenName]: accessToken,
    },
  })
}

export function logout() {
  return request({
    url: '/mg/drp-user-api/userAccount/logout',
    method: 'get',
  })
}

export function register() {
  return request({
    url: '/register',
    method: 'post',
  })
}

// 修改密码
export function putChangePassWord(data) {
  return request({
    url: '/mg/drp-user-api/userAccount/changePassWord',
    method: 'put',
    data: data,
  })
}
