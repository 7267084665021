import request from '@/utils/request'

// 盘点单列表
export function getInventoryList(data) {
  return request({
    url: '/mg/daohang-stock-api/stockCheckOrder/page',
    method: 'post',
    data,
  })
}
// 盘点单列表合计
export function sumInventoryList(data) {
  return request({
    url: '/mg/daohang-stock-api/stockCheckOrder/sum',
    method: 'post',
    data,
  })
}
// 盘点单保存、编辑
export function saveInventoryList(data) {
  return request({
    url: '/mg/daohang-stock-api/stockCheckOrder/saveOrUpdate',
    method: 'put',
    loading: true,
    data,
  })
}

// 盘点单删除
export function removeInventoryList(data) {
  return request({
    url: '/mg/daohang-stock-api/stockCheckOrder',
    method: 'delete',
    data,
  })
}

// 盘点单删除子表
export function removeInventoryListItem(data) {
  return request({
    url: '/mg/daohang-stock-api/stockCheckOrderItem',
    method: 'delete',
    data,
  })
}

// 盘点单审核 - 反审核 -入账
export function auditInventoryList(data) {
  return request({
    url: '/mg/daohang-stock-api/stockCheckOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}

// 盘点单查看
export function getInventoryListDetail(id) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckOrder/${id}`,
    method: 'get',
  })
}

// 盘点单导出
export function exportInventoryListExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckOrder/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 盘点单单据明细导出
export function exportInventoryListItemExcel(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckOrderItem/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 盘点任务单列表
export function getStockCheckTaskList(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTask/list`,
    method: 'post',
    data,
  })
}

// 快速添加商品接口
export function getSkuFastList(params) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTaskItem/quickSearch`,
    method: 'get',
    params,
  })
}

// 商品分页接口
export function getSkuList(data) {
  return request({
    url: `/mg/daohang-stock-api/stockCheckTaskItem/page`,
    method: 'post',
    data,
  })
}
