import { getCategoryListCommon } from '@/api/goodsInfo/goodsInfoApi'

const state = () => ({
  categoryList: [],
})

const mutations = {
  set_category_list(state, categoryList) {
    state.categoryList = categoryList
  },
}
const actions = {
  /**
   * 获取品类列表
   * @param {object}  payload
   * @param {LEVEL1|LEVEL2|LEVEL3|LEVEL4}  payload.categoryLevel
   * @param {DH|DST|EE}  payload.sourceTypeEnum
   */
  async FETCH_CATEGORY_LIST({ state, commit }, payload) {
    if (state.categoryList?.length) return { data: state.categoryList }
    const { data } = await getCategoryListCommon(payload)
    commit('set_category_list', data)
    return { data: state.categoryList }
  },
}
export default { state, mutations, actions }
