import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse/page',
    method: 'post',
    data,
  })
}

export function add(data) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse',
    method: 'put',
    data,
  })
}

export function update(data) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse/update',
    method: 'post',
    data,
  })
}

export function detail(params) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse/itemList',
    method: 'get',
    params,
  })
}

export function del(data) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse',
    method: 'delete',
    data,
  })
}

export function updateStatus(data) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse/status',
    method: 'put',
    data,
  })
}

export function logicWarehouseList(data) {
  return request({
    url: '/mg/daohang-stock-api/cargoWarehouse/list',
    method: 'post',
    data,
  })
}
