import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.108.68' : '/'
//采购退货单分页
export function purchaseReturnOrderPage(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder/page`,
    method: 'post',
    data,
  })
}
//采购退货单新增
export function purchaseReturnOrderSaveOrUpdate(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder/saveOrUpdate`,
    method: 'put',
    loading: true,
    data,
  })
}
//采购退货单详情
export function getPurchaseReturnOrderById(id) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
//采购退货单列表导出
export function purchaseReturnOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//采购退货单状态变更
export function purchaseReturnOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}
//采购退货单明细导出
export function purchaseReturnOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrderItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
// 导出
export function exportErrorExcel(params) {
  return request({
    url: '/mg/daohang-order-api/genericExcelFile/error/exportExcel',
    method: 'get',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params,
  })
}
//折扣折让单删除
export function purchaseReturnOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}

//采购退货单合计
export function purchaseReturnOrderSum(data) {
  return request({
    url: `/mg/daohang-order-api/purchaseReturnOrder/sum`,
    method: 'post',
    data,
  })
}
