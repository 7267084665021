import { supplierList, supplierPage } from '@/api/basic/supplier'
const state = () => ({
  supplierList: {
    ENABLE: [],
    ALL: [],
  },
})

const mutations = {
  SET_SUPPLIER_LIST(state, { list, prop }) {
    state.supplierList[prop] = list
  },
}

const actions = {
  /**
   * 当前登录人所属往来单位下的所有启用状态的供应商
   * @param {*} payload
   * @returns
   */
  async FETCH_SUPPLIER_LIST({ commit, state }, payload = '') {
    const { statusList } = payload?.params
    // 局限于一般场景下获取启用或者禁用数据的列表接口
    const prop = statusList.includes('ENABLE') && statusList.includes('DISABLE') ? 'ALL' : statusList[0]
    // 如果存在则不再发起请求
    if (state.supplierList[prop].length > 0) return { data: state.supplierList[prop] }
    // 发起 http 请求
    const { data: list } = await supplierList(payload?.params)
    // mutation
    commit(`SET_SUPPLIER_LIST`, { list, prop })
    return { data: state.supplierList[prop] }
  },
  /**
   * 分页搜索: 当前登录人所属往来单位下的所有启用状态的供应商
   * @param {*} _
   * @param {*} payload
   * @returns
   */
  async FETCH_SUPPLIER_PAGE(_, payload) {
    return await supplierPage(payload)
  },
}

export default { state, mutations, actions }
