import request from '@/utils/request'

export function getWarehouseTransferOrderPage(data) {
  return request({
    url: '/mg/daohang-order-api/warehouseTransferOrder/page',
    method: 'post',
    data,
  })
}
/**
 * 删除
 * @param {object} data
 * @param {string} data.id
 */
export function deleteWarehouseTransferOrderById(data) {
  return request({
    url: '/mg/daohang-order-api/warehouseTransferOrder',
    method: 'delete',
    data,
  })
}

export function getWarehouseTransferOrderDetailById(id) {
  return request({
    url: `/mg/daohang-order-api/warehouseTransferOrder/${id}`,
    method: 'get',
  })
}
/**
 * 更改状态
 * @param {object} data
 * @param {string} data.id
 * @param {10|20|30} data.operation
 */
export function warehouseTransferOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/warehouseTransferOrder/statusChange`,
    method: 'post',
    data,
  })
}
/**
 * 保存/更新
 * @param {*} data
 * @returns
 */
export function warehouseTransferOrderSaveOrUpdate(data, config) {
  return request({
    url: `/mg/daohang-order-api/warehouseTransferOrder/saveOrUpdate`,
    method: 'put',
    data,
    ...config,
  })
}
/**
 * 导出列表
 * @param {*} data
 * @returns
 */
export function warehouseTransferOrderExportExcel(data, onDownloadProgress) {
  return request({
    url: `/mg/daohang-order-api/warehouseTransferOrder/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
    onDownloadProgress,
  })
}
/**
 * 导出明细
 * @param {*} data
 * @returns
 */
export function warehouseTransferOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/warehouseTransferOrderItem/exportExcel`,
    method: 'post',
    data,
    responseType: 'blob',
  })
}
/**
 * 合计
 * @param {*} data
 * @returns
 */
export function warehouseTransferOrderSum(data) {
  return request({
    url: `/mg/daohang-order-api/warehouseTransferOrder/sum`,
    method: 'post',
    data,
  })
}
