import request from '@/utils/request'

//新增角色
export function setAddRole(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsRole`,
    method: 'put',
    data: data,
  })
}
//编辑角色
export function updateRole(data) {
  return request({
    url: `/mg/drp-user-api/postInfo/updateByDh`,
    method: 'put',
    data: data,
  })
}
//获取列表
export function getList(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsRole/page`,
    method: 'get',
    params: data,
  })
}

//启禁用
export function setEnableDisable(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsRole/operStatus`,
    method: 'put',
    data: data,
  })
}
//岗位禁用
export function postInfoAuditSubmitByRole(data) {
  return request({
    url: `/mg/drp-user-api/postInfo/auditSubmitByRole`,
    method: 'post',
    data: data,
  })
}
//岗位删除
export function postInfoDeleteByRoleId(data) {
  return request({
    url: `/mg/drp-user-api/postInfo/deleteByRoleId`,
    method: 'delete',
    data: data,
  })
}

//编辑 查询单条  当前角色权限树
export function getCurrentRoleTree(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsRole/${data}`,
    method: 'get',
  })
}

//删除角色信息
export function delRole(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsRole`,
    method: 'delete',
    data: data,
  })
}
export function getMenuList() {
  return request({
    url: `/mg/drp-user-api/employee/dhTopAuthority`,
    method: 'get',
  })
}

export function setAddMenu(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsMenu`,
    method: 'put',
    data: data,
  })
}

export function delMenu(data) {
  return request({
    url: `/mg/drp-user-api/componentPermissionsMenu`,
    method: 'delete',
    data: data,
  })
}

/**
 * 获取用户管理列表
 * @param { Object } data
 */
export function postUserManageLists(data) {
  return request({
    url: `/mg/daohang-basic-api/employeeBusinessGroup/page`,
    method: 'post',
    data,
  })
}
/**
 * 恢复初始密码
 * @param { String } data
 */
export function putInitPassword(data) {
  return request({
    url: `/mg/drp-user-api/userAccount/regainPassWord`,
    method: 'put',
    data,
  })
}

//部门单独树
export function getDeptTree(data) {
  return request({
    url: `/mg/drp-admin-api/dept/deptTree`,
    method: 'post',
    data: data,
  })
}

//员工列表查询
export function employeeList(data) {
  return request({
    url: `/mg/drp-user-api/employee/list`,
    method: 'post',
    allowRepeat: true,
    data,
  })
}
//员工关联角色、岗位
export function employeeRoleUpdate(data) {
  return request({
    url: `/mg/drp-user-api/employeeRole`,
    method: 'put',
    data,
  })
}
//新增编辑员工岗位条件查询
export function postListByDept(data) {
  return request({
    url: `/mg/drp-user-api/postInfo/list`,
    method: 'post',
    data,
  })
}
// 编辑用户信息
export function employeeAdd(data, method) {
  return request({
    url: `/mg/drp-user-api/employee/dhAdd`,
    method,
    data,
  })
}
// 编辑用户信息
export function employeeUpdate(data, method) {
  return request({
    url: `/mg/drp-user-api/employee/dhUpdate`,
    method,
    data,
  })
}

// 将用户和经营组织关联
export function employeeBusinessGroup(data) {
  return request({
    url: `/mg/daohang-basic-api/employeeBusinessGroup/save`,
    method: 'put',
    data,
  })
}

// 用户启用/禁用
export function employeeStatus(data) {
  return request({
    url: `/mg/drp-user-api/employee/status`,
    method: 'put',
    data: data,
  })
}
//新增岗位
export function postCreate(data) {
  return request({
    url: `/mg/drp-user-api/postInfo`,
    method: 'post',
    data,
  })
}

// 新增/修改用户信息-调用导航的接口
export function employeeAddOrUpdateForDH(data) {
  return request({
    url: `/mg/daohang-order-api/discountAuth`,
    method: 'put',
    data,
  })
}
// 员工权限详情的接口
export function employeeDiscountAuthDetail(code) {
  return request({
    url: `/mg/daohang-order-api/discountAuth/${code}`,
    method: 'get',
  })
}
//列表查询--根据当前用户人查询门户ID跟租户ID
export function employeeBusinessGroupListByUserV2(data) {
  return request({
    url: `/mg/daohang-basic-api/employeeBusinessGroup/listByUserV2`,
    method: 'post',
    data,
  })
}
