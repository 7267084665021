import request from '@/utils/request'

export function priceAdjustOrderPage(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrder/page`,
    method: 'post',
    data,
  })
}
export function priceAdjustOrderUpdate(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrder`,
    method: 'put',
    loading: true,
    data,
  })
}

export function priceAdjustOrderExport(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function priceAdjustOrderGetById(id) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrder/${id}`,
    method: 'get',
    loading: true,
  })
}
export function skuBasePriceBatchList(data) {
  return request({
    url: `/mg/daohang-promotion-api/skuBasePrice/list`,
    method: 'post',
    data,
  })
}
export function priceAdjustOrderExamineAndApprove(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrder/examineAndApprove`,
    method: 'post',
    loading: true,
    data,
  })
}

export function priceAdjustOrderBatchDelete(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrder`,
    method: 'DELETE',
    data,
  })
}
export function priceAdjustOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrderItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
export function priceAdjustOrderItemBatchDelete(data) {
  return request({
    url: `/mg/daohang-promotion-api/priceAdjustOrderItem`,
    method: 'DELETE',
    data,
  })
}

export function skuPage(data) {
  return request({
    url: `/mg/daohang-product-api/goodsCache/page`,
    method: 'post',
    data,
  })
}

//批量查询 库存成本价
export function kcSkuCostList(data) {
  return request({
    url: `/mg/daohang-stock-api/kcSkuCost/list`,
    method: 'post',
    data,
    loading: true,
  })
}
