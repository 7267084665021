import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse/page',
    method: 'post',
    data,
  })
}

export function detail(params) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse/itemList',
    method: 'get',
    params,
    loading: true,
  })
}

export function add(data) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse',
    method: 'put',
    data,
  })
}

export function update(data) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse/update',
    method: 'post',
    data,
    loading: true,
  })
}

export function del(params) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse',
    method: 'delete',
    params,
  })
}

/**
 * 当前登录人所属的经营组织&排除已经存在仓库经营属性的经营组织
 * @param {*} data
 * @returns
 */
export function businessGroupList(data) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse/getEmployeeBusinessGroup',
    method: 'post',
    data,
  })
}

/**
 * 所有启用状态的逻辑仓&排除已经绑定经营组织的逻辑仓
 * @returns
 */
export function cargoWarehouseBusiness(data) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse/getWarehouse',
    method: 'post',
    data,
  })
}

/**
 * 经营组织下对应的仓库查询
 * @returns
 */
export function fetchCargoWarehouseListByBusinessGroup(data) {
  return request({
    url: '/mg/daohang-stock-api/businessCargoWarehouse/list',
    method: 'post',
    data,
    allowRepeat: true,
  })
}
