/**
 * 批发退货相关打印变量
 */
const inventoryListTempOptions = [
  {
    label: '盘点单',
    value: 'all',
    children: [{ label: '盘点单', value: 1 }],
  },
]
const inventoryListTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 7.5,
          paperFooter: 373.5,
          printElements: [
            {
              options: {
                left: 186,
                top: 15,
                height: 17,
                width: 174,
                title: '盘点单',
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 345,
                top: 40,
                height: 15,
                width: 151.5,
                field: 'printTime',
                right: 610.2421875,
                title: '打印时间',
                bottom: 250.9921875,
                hCenter: 243.4921875,
                vCenter: 534.4921875,
                testData: '2023-04-55 19：23：23',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12.5,
                top: 42.5,
                height: 15,
                width: 159,
                field: 'billNo',
                right: 169.5,
                title: '单据编号',
                bottom: 58.5,
                hCenter: 51,
                vCenter: 90,
                testData: 'PT0C0000230421000002',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 345,
                top: 62.5,
                height: 15,
                width: 220.5,
                field: 'stockCheckTaskNo',
                right: 566.49609375,
                title: '盘点任务单号',
                bottom: 77.49609375,
                hCenter: 69.99609375,
                vCenter: 456.24609375,
                testData: 'PT0C0000230421000002',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12.5,
                top: 67.5,
                height: 15,
                width: 190.5,
                field: 'cargoWarehouseCode',
                right: 202.9921875,
                title: '仓库',
                bottom: 90,
                hCenter: 82.5,
                vCenter: 107.7421875,
                testData: '8600-义务123519可适当放宽',
                formatter:
                  "function(title,value,options,templateData,target){ return templateData && `${ templateData?.cargoWarehouseCode }-${ templateData?.cargoWarehouseName }` || ''  }",
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 17.5,
                top: 90,
                height: 63,
                width: 550,
                field: 'printLists',
                right: 566.5,
                bottom: 158.49609375,
                hCenter: 123.99609375,
                vCenter: 291.5,
                textAlign: 'center',
                coordinateSync: false,
                widthHeightSync: false,
                tableHeaderFontSize: 9.75,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableFooterCellBorder: 'noBorder',
                tableHeaderFontWeight: 'bold',
                columns: [
                  [
                    {
                      width: 63.731881323113456,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 81.23178714202588,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 61.05966080062248,
                      title: '商品条码',
                      field: 'skuBarcode',
                      checked: true,
                      columnId: 'skuBarcode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummaryText: '合计',
                      tableSummary: '',
                    },
                    {
                      width: 59.019233953272014,
                      title: '含税进价',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 67.93765593506853,
                      title: '售价',
                      field: 'retailPrice',
                      checked: true,
                      columnId: 'retailPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 70.27676138692938,
                      title: '实盘数量',
                      field: 'checkQty',
                      checked: true,
                      columnId: 'checkQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryNumFormat: '0',
                    },
                    {
                      width: 62.12763484358366,
                      title: '进价金额',
                      field: 'restockAmt',
                      checked: true,
                      columnId: 'restockAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                    },
                    {
                      width: 84.6153846153846,
                      title: '售价金额',
                      field: 'sellAmt',
                      checked: true,
                      columnId: 'sellAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 360.5,
                top: 159.5,
                height: 15,
                width: 207,
                field: 'createUserName',
                right: 498.4921875,
                title: '创建人',
                bottom: 176.7421875,
                hCenter: 169.2421875,
                vCenter: 430.2421875,
                testData: '王大姐',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 14.75,
                top: 160.25,
                height: 15,
                width: 138,
                right: 156.24609375,
                title: '录入人：',
                bottom: 209.25,
                hCenter: 201.75,
                vCenter: 87.24609375,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 541.5,
          paperNumberTop: 379.5,
          paperNumberContinue: true,
          watermarkOptions: {},
        },
      ],
    },
  },
}

export default {
  // 批发退货单 -- 不同配送中心打印模板名
  inventoryListTempOptions,
  // 批发退货单 -- 不同分公司打印模板名json
  inventoryListTempJsonOptions,
}
