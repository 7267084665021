import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.75.23' : '/'
//盈亏单分页查询
export function stockBreakageOrderPage(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder/page`,
    method: 'post',
    data,
  })
}
//盈亏单分页合计
export function stockBreakageOrderSum(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder/sum`,
    method: 'post',
    data,
  })
}
//盈亏单新增编辑
export function stockBreakageOrderSave(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder`,
    method: 'put',
    loading: true,
    data,
  })
}
//盈亏单状态变更
export function stockBreakageOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}

//盈亏单查询单条
export function getStockBreakageOrderById(params) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder/itemList`,
    method: 'get',
    loading: true,
    params,
  })
}
//盈亏单列表导出
export function stockBreakageOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//盈亏单明细列表导出
export function stockBreakageOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrderItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//盈亏单删除
export function stockBreakageOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/stockBreakageOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}
