import request from '@/utils/request'

/** 采购入库单-分页 */
export function getPurchaseReceiveOrderPage(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReceiveOrder/page',
    method: 'post',
    data,
  })
}
/** 采购入库单-合计 */
export function sumAmtPurchaseReceiveOrder(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReceiveOrder/sumAmt',
    method: 'post',
    data,
  })
}

/** 采购入库单-列表导出 */
export function exportExcelPurchaseReceiveOrderList(data, onDownloadProgress) {
  return request({
    url: '/mg/daohang-order-api/purchaseReceiveOrder/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
    onDownloadProgress,
  })
}
/** 采购入库单-明细导出 */
export function exportExcelPurchaseReceiveOrderItems(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReceiveOrderItem/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

/** 采购入库单-删除 */
export function deletePurchaseReceiveOrder(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReceiveOrder',
    method: 'delete',
    data,
  })
}

/** 采购入库单-审核:10/反审核:30/入账:20 */
export function purchaseReceiveOrderStatusChange(data) {
  return request({
    url: '/mg/daohang-order-api/purchaseReceiveOrder/statusChange',
    method: 'post',
    data,
  })
}

/** 采购入库单-明细 */
export function getPurchaseReceiveOrderById(id) {
  return request({
    url: `/mg/daohang-order-api/purchaseReceiveOrder/${id}`,
    method: 'get',
  })
}

/** 采购入库单-保存 */
export function savePurchaseReceiveOrder(data, config) {
  return request({
    url: `/mg/daohang-order-api/purchaseReceiveOrder`,
    method: 'post',
    data,
    ...config,
  })
}
