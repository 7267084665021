import { convertRouter, addButtonRouter } from '@/utils/handleRoutes'
import { constantRoutes, resetRouter } from '@/router'
import { cloneDeep } from 'lodash-es'
import { getAssociationId } from '@/api/receivable/receiptVoucherApi'
import route from '@/router'
const state = () => ({
  routes: [],
  subRoutes: [],
})
const getters = {
  routes: (state) => state.routes,
  subRoutes: (state) => state.subRoutes,
}
const mutations = {
  setRoutes(state, routes) {
    state.routes = constantRoutes.concat(routes)
  },
  setSubRoutes(state, subRoutes) {
    state.subRoutes = subRoutes
  },
  clear(state) {
    state.routes = []
    state.subRoutes = []
  },
}
const actions = {
  // 设置所有路由
  setRoutes({ commit }, permissions) {
    // 由于设置的严苛模式，因此需要对该对象进行深拷贝
    const pageRoutes = convertRouter(cloneDeep(permissions))
    const buttonRoutes = addButtonRouter(pageRoutes)
    const routes = pageRoutes.concat(buttonRoutes)
    // 兜底路由
    routes.push({
      path: '*',
      redirect: '404',
      meta: { title: 'not Found', hidden: true },
    })
    commit('setRoutes', routes)
    return routes
  },
  // 设置某个路由下的子路由，比如菜单hover显示的时候
  setSubRoutes({ commit }, subRoutes) {
    commit('setSubRoutes', subRoutes)
  },
  clear({ commit }) {
    commit('clear')
    resetRouter()
  },

  /**
   * 通过单据号跳转到对应详情页
   * @param {*} _
   * @param {*} param1
   */
  async navigatoToDetail(_, { billType, billNo, routeName }) {
    const { data } = await getAssociationId({ billType, billNo })
    if (data) {
      route.push({
        name: routeName[billType],
        params: {
          id: data,
        },
      })
    }
  },
}
export default { state, getters, mutations, actions }
