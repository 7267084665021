/**
 * 盈亏单相关打印变量
 */
const profitAndLossOrderTempOptions = [
  {
    label: '盈亏单',
    value: 'all',
    children: [{ label: '盈亏单(盈亏单)', value: 1 }],
  },
]
const profitAndLossOrderTempJsonOptions = {
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 141,
          width: 210,
          paperHeader: 93,
          paperFooter: 391.275,
          printElements: [
            {
              options: {
                left: 220.5,
                top: 19.5,
                height: 17,
                width: 120,
                title: '商品盈亏单',
                fontSize: 16.5,
                testData: '',
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 336,
                top: 45,
                height: 15,
                width: 154.5,
                field: 'printTime',
                right: 466.5,
                title: '打印日期',
                bottom: 55.5,
                hCenter: 48,
                vCenter: 403.5,
                testData: '2022-10-12 19:12:12',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 28.5,
                top: 45,
                height: 15,
                width: 171,
                field: 'businessGroupName',
                right: 199.5,
                title: '组织',
                bottom: 59.25,
                hCenter: 51.75,
                vCenter: 114,
                testData: 'C1101-义务配送中心',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 28.5,
                top: 60,
                height: 15,
                width: 171,
                field: 'billNo',
                right: 198.75,
                title: '单据编号',
                bottom: 77.25,
                hCenter: 69.75,
                vCenter: 113.25,
                testData: '1000000000000000000000',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 336,
                top: 73.5,
                height: 15,
                width: 148.5,
                field: 'checkTime',
                right: 484.5,
                title: '盘点日期',
                bottom: 87.75,
                hCenter: 80.25,
                vCenter: 410.25,
                testData: '2022-10-12 19:12:12',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 28.5,
                top: 75,
                height: 15,
                width: 171,
                field: 'cargoWarehouseName',
                right: 198,
                title: '存放地点',
                bottom: 90.75,
                hCenter: 83.25,
                vCenter: 112.5,
                testData: '陆家嘴金融大厦',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 21,
                top: 100.5,
                height: 39,
                width: 550,
                field: 'printLists',
                right: 570.25,
                bottom: 195,
                hCenter: 168,
                vCenter: 295.25,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderFontWeight: 'bold',
                columns: [
                  [
                    {
                      width: 76.8202,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 123.1798,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 88.56625,
                      title: '规格',
                      field: 'specsDesp',
                      checked: true,
                      columnId: 'specsDesp',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 80.75500000000002,
                      title: '进项税率',
                      checked: true,
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 80.12049999999999,
                      title: '账面数量',
                      field: 'storageQty',
                      checked: true,
                      columnId: 'storageQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 96.51924999999999,
                      title: '实际数量',
                      field: 'actualQty',
                      checked: true,
                      columnId: 'actualQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 124.93375000000007,
                      title: '实际进价金额',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 129.10524999999993,
                      title: '盘亏率',
                      field: 'stockBreakageRatio',
                      checked: true,
                      columnId: 'stockBreakageRatio',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                    {
                      width: 100,
                      title: '盘亏原因',
                      field: 'returnableAmt',
                      checked: true,
                      columnId: 'returnableAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: true,
                      tableSummary: '',
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 202.5,
                top: 147,
                height: 15,
                width: 30,
                field: '123',
                right: 238.5,
                title: '物价',
                bottom: 201.75,
                hCenter: 194.25,
                vCenter: 220.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 448.5,
                top: 147,
                height: 15,
                width: 31.5,
                field: '123',
                right: 483.99609375,
                title: '制单',
                bottom: 201.99609375,
                hCenter: 194.49609375,
                vCenter: 465.99609375,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 30,
                top: 147,
                height: 15,
                width: 30,
                field: '123',
                right: 59.25,
                title: '业务',
                bottom: 201.75,
                hCenter: 194.25,
                vCenter: 44.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: { left: 484.5, top: 163.5, height: 9, width: 90 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: { left: 67.5, top: 163.5, height: 9, width: 90 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 241.5,
                top: 157.5,
                height: 9,
                width: 90,
                right: 330.75,
                bottom: 169.5,
                vCenter: 285.75,
                hCenter: 165,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
          ],
          paperNumberLeft: 556.5,
          paperNumberTop: 28.5,
        },
      ],
    },
  },
}

export default {
  // 盈亏单 -- 不同配送中心打印模板名
  profitAndLossOrderTempOptions,
  // 盈亏单 -- 不同分公司打印模板名json
  profitAndLossOrderTempJsonOptions,
}
