import { TabsBarStorage, ChangePwdStorage, TokenStorage, WebFunnyStorage } from '@/utils/storage'
import * as UserApi from '@/api/user'

const state = () => ({
  accessToken: TokenStorage.get(),
  belongId: null,
  employeeName: null,
  employeeNo: null,
  belongName: null,
  belongCode: null,
  permissions: [],
  createTime: null,
})
const getters = {
  accessToken: (state) => state.accessToken,
  belongId: (state) => state.belongId,
  permissions: (state) => state.permissions,
  employeeName: (state) => state.employeeName,
  employeeNo: (state) => state.employeeNo,
  belongName: (state) => state.belongName,
  belongCode: (state) => state.belongCode,
  createTime: (state) => state.createTime,
}
const mutations = {
  removeAccessToken(state) {
    state.accessToken = ''
    TokenStorage.clear()
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    TokenStorage.set(accessToken)
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setBelongId(state, belongId) {
    state.belongId = belongId
  },
  setEmployeeNo(state, employeeNo) {
    state.employeeNo = employeeNo
  },
  setEmployeeName(state, employeeName) {
    state.employeeName = employeeName
  },
  setBelongName(state, belongName) {
    state.belongName = belongName
  },
  setBelongCode(state, belongCode) {
    state.belongCode = belongCode
  },
  setCreateTime(state, createTime) {
    state.createTime = createTime
  },
  clearUserData(state) {
    state.belongId = ''
    state.belongCode = ''
    state.employeeName = ''
    state.employeeNo = ''
    state.permissions = []
    state.createTime = ''
  },
}
const actions = {
  removeAccessToken({ commit }) {
    commit('removeAccessToken')
    commit('clearUserData')
    TabsBarStorage.clear()
  },
  async login({ commit }, loginInfo) {
    const { data } = await UserApi.login({
      ...loginInfo,
      belongType: 'DRP_DH_LOGIN_USERS',
    })
    if (data.token) {
      commit('setAccessToken', data.token)
      ChangePwdStorage.set(data.needUpdatePwd)
    }
  },
  async getUserInfo({ commit, dispatch, state }) {
    const { data } = await UserApi.getUserInfo(state.accessToken)

    // 写入 webfunny userId
    if (process.env.VUE_APP_WEB_MONITOR_ID) {
      WebFunnyStorage.set({
        userId: data.employeeId,
        userTag: data.belongId,
        projectVersion: '1.0.0',
        env: 'pro',
      })
    }

    commit('setBelongId', data.belongId)

    // 过滤掉零售
    data.permissionsMenus[0].children = data.permissionsMenus[0].children.filter((item) => item.perms !== 'pos')
    commit('setPermissions', data.permissionsMenus)
    commit('setEmployeeName', data.employeeName)
    commit('setEmployeeNo', data.employeeNo)
    commit('setBelongName', data.belongName)
    commit('setBelongCode', data.belongCode)
    commit('setCreateTime', data.createTime)
    dispatch('sys/getZipConfig', {}, { root: true })
  },
  // 修改密码
  async changePassword({ commit }, changePwdInfo) {
    await UserApi.putChangePassWord(changePwdInfo)
    commit('removeAccessToken')
    ChangePwdStorage.clear()
  },
  // 退出登录清空 accessToken 和路由
  async logout({ dispatch }) {
    await UserApi.logout()
    dispatch('removeAccessToken')
    dispatch('routes/clear', {}, { root: true })
    dispatch('tabsBar/clear', {}, { root: true })
  },
}
export default { state, getters, mutations, actions }
