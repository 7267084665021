import request from '@/utils/request'

// 成本调整单查询
export function getCostAdjustmentSheetList(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrder/page',
    method: 'post',
    data,
  })
}

// 成本调整单保存
export function saveCostAdjustmentSheet(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrder/save',
    method: 'post',
    loading: true,
    data,
  })
}

// 成本调整单详情
export function getCostAdjustmentSheetDetail(id) {
  return request({
    url: `/mg/daohang-stock-api/kcCostAdjustOrder/${id}`,
    method: 'get',
  })
}

// 成本调整单详情明细
export function getCostAdjustmentSheetGoodsDetail(data) {
  return request({
    url: `/mg/daohang-stock-api/kcCostAdjustOrderItem/list`,
    method: 'post',
    data,
  })
}

// 审核-反审核-入账
export function statusChangeCostAdjustmentSheet(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}
// 导出
export function exportCostAdjustmentSheet(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrder/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}
// 明细删除
export function removeCostAdjustmentSheetDetail(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrderItem',
    method: 'delete',
    data,
  })
}
// 详情页导出
export function exportCostAdjustmentSheetItemsExcel(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrderItem/exportExcel',
    method: 'post',
    responseType: 'blob',
    data,
  })
}
// 批量删除
export function deleteBatchCostAdjustmentSheet(data) {
  return request({
    url: '/mg/daohang-stock-api/kcCostAdjustOrder',
    method: 'delete',
    loading: true,
    data,
  })
}
