/**
 * 复核单单相关打印变量
 */
const reviewPrintTempOptions = [
  {
    label: '复核单',
    value: 'all',
    children: [
      { label: '装箱单', value: 1 },
      { label: '箱贴单10X10', value: 2 },
      { label: '箱贴单10X18', value: 3 },
    ],
  },
]
const reviewPrintTempJsonOptions = {
  // 郑州管城区配送中心
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 298,
          width: 210,
          paperHeader: 117,
          paperFooter: 818.9678571428572,
          printElements: [
            {
              options: {
                left: 241.5,
                top: 6,
                height: 17,
                width: 120,
                right: 361.5,
                title: '装箱单',
                bottom: 23,
                hCenter: 14.5,
                vCenter: 301.5,
                fontSize: 16.5,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 27,
                height: 15,
                width: 183,
                field: 'customerName',
                right: 371.25,
                title: '配送门店',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 279.75,
                testData: '01-义务晨兴公司',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 376.5,
                top: 27,
                height: 15,
                width: 190.5,
                field: 'printTime',
                right: 569.25,
                title: '打印时间',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 473.25,
                testData: '2023-04-06',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 27,
                height: 15,
                width: 169.5,
                field: 'partnerName',
                right: 135,
                title: '公司名称',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 75,
                testData: '01-义务晨兴公司',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 46.5,
                height: 15,
                width: 183,
                field: 'billNo',
                right: 372,
                title: '装箱单号',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 280.5,
                testData: 'CGSS20230327000054',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 378,
                top: 46.5,
                height: 15,
                width: 189,
                field: 'userName',
                right: 567,
                title: '打印人员',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 472.5,
                testData: '2023-04-06',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 46.5,
                height: 15,
                width: 169.5,
                field: 'saleOrderNo',
                right: 184.5,
                title: '销售单号',
                bottom: 61.5,
                hCenter: 54,
                vCenter: 99,
                testData: 'CGSS20230327000054',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 67.5,
                height: 15,
                width: 169.5,
                field: 'customerName',
                right: 183.99609375,
                title: '客户',
                bottom: 82.74609375,
                hCenter: 75.24609375,
                vCenter: 99.24609375,
                testData: '01-义务晨兴公司',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 15,
                top: 90,
                height: 24,
                width: 553.5,
                field: 'remark',
                right: 564.75,
                title: '备注',
                bottom: 93,
                hCenter: 81,
                vCenter: 288,
                testData:
                  '我是备注我是备注我是注我备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注我是备注',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 15,
                top: 123,
                height: 60,
                width: 553.5,
                field: 'printLists',
                right: 562.5,
                bottom: 137.9999885559082,
                hCenter: 119.9999885559082,
                vCenter: 286.5,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 100,
                      title: '序号',
                      field: 'index',
                      checked: true,
                      columnId: 'index',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品条码',
                      field: 'skuBarcode',
                      checked: true,
                      columnId: 'skuBarcode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '货号',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '数量',
                      field: 'scanQty',
                      checked: true,
                      columnId: 'scanQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryNumFormat: '0',
                    },
                    {
                      width: 100,
                      title: '规格',
                      field: 'specsDesp',
                      checked: true,
                      columnId: 'specsDesp',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '包装单位',
                      field: 'packageUnit',
                      checked: true,
                      columnId: 'packageUnit',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '批发价',
                      field: 'avgReceivablePrice',
                      checked: true,
                      columnId: 'avgReceivablePrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '金额',
                      field: 'scanAmt',
                      checked: true,
                      columnId: 'scanAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: '30',
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                      tableSummaryTitle: false,
                      tableSummary: 'sum',
                      tableSummaryAlign: 'right',
                      tableSummaryNumFormat: '2',
                    },
                    {
                      width: 100,
                      title: '姓名',
                      field: 'name',
                      checked: false,
                      columnId: 'name',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '性别',
                      field: 'gender',
                      checked: false,
                      columnId: 'gender',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售数量',
                      field: 'count',
                      checked: false,
                      columnId: 'count',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 100,
                      title: '销售金额',
                      field: 'amount',
                      checked: false,
                      columnId: 'amount',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 16.5,
                top: 189,
                height: 16.5,
                width: 553.5,
                right: 562.5,
                title:
                  '提示：请收货时及时确认核对装箱单，如有问题请及时发差异表给接单员(24小时内)，收货后3个工作日我们即认为您已按单收讫。',
                bottom: 184.5,
                hCenter: 176.25,
                vCenter: 285.75,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 285,
                top: 214.5,
                height: 15,
                width: 127.5,
                field: 'contactPhone',
                right: 273,
                title: '联系电话',
                bottom: 169.4999885559082,
                hCenter: 161.9999885559082,
                vCenter: 215.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 432,
                top: 214.5,
                height: 15,
                width: 138,
                field: 'contactPersonnel',
                right: 568.5,
                title: '开单人',
                bottom: 208.5,
                hCenter: 201,
                vCenter: 499.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 15,
                top: 214.5,
                height: 15,
                width: 255,
                field: 'deliveryAddress',
                right: 270,
                title: '地址',
                bottom: 208.5,
                hCenter: 201,
                vCenter: 142.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 15,
                top: 237,
                height: 15,
                width: 120,
                right: 135,
                title: '拣货人',
                bottom: 225.75,
                hCenter: 218.25,
                vCenter: 75,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 151.5,
                top: 238.5,
                height: 15,
                width: 120,
                field: 'userName',
                right: 269.25,
                title: '复核人',
                bottom: 226.5,
                hCenter: 219,
                vCenter: 209.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
    2: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 100,
          width: 100,
          paperHeader: 0,
          paperFooter: 274.82142857142856,
          printElements: [
            {
              options: {
                left: 85.5,
                top: 3,
                height: 17,
                width: 100.5,
                right: 198.75,
                title: '广州配送仓',
                bottom: 20,
                hCenter: 11.5,
                vCenter: 138.75,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 186,
                top: 15,
                height: 9.75,
                width: 91.5,
                field: 'printTime',
                right: 280.5,
                title: '文本',
                bottom: 24.75,
                hCenter: 19.875,
                vCenter: 233.25,
                testData: '2023-09-09 09:09:09',
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: { left: 0, top: 31.5, height: 9, width: 282, right: 90, bottom: 40.5, hCenter: 36, vCenter: 45 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: { left: 0, top: 60, height: 9, width: 282 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 70.5,
                top: 60,
                height: 172.5,
                width: 9,
                right: 78.75,
                bottom: 87,
                hCenter: 73.5,
                vCenter: 74.25,
              },
              printElementType: { title: '竖线', type: 'vline' },
            },
            {
              options: {
                left: 0,
                top: 64.5,
                height: 17,
                width: 70.5,
                right: 70.5,
                title: '客户名称',
                bottom: 81.5,
                hCenter: 73,
                vCenter: 35.25,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 157.5,
                top: 67.5,
                height: 9.75,
                width: 120,
                field: 'customerName',
                right: 277.5,
                title: '文本',
                bottom: 73.5,
                hCenter: 68.625,
                vCenter: 217.5,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 75,
                top: 67.5,
                height: 9.75,
                width: 76.5,
                field: 'customerCode',
                right: 150.75,
                bottom: 74.25,
                hCenter: 69.375,
                vCenter: 112.5,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 87,
                height: 9,
                width: 283.5,
                right: 283.5,
                bottom: 95.25,
                hCenter: 90.75,
                vCenter: 141.75,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 91.5,
                height: 17,
                width: 69,
                right: 69,
                title: '联系电话',
                bottom: 109.25,
                hCenter: 100.75,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 75,
                top: 96,
                height: 9.75,
                width: 120,
                field: 'contactPhone',
                right: 194.25,
                title: '文本',
                bottom: 105,
                hCenter: 100.125,
                vCenter: 134.25,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 111,
                height: 9,
                width: 280.5,
                right: 90,
                bottom: 120,
                hCenter: 115.5,
                vCenter: 45,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 118.5,
                height: 17,
                width: 69,
                right: 69,
                title: '送货地址',
                bottom: 132.5,
                hCenter: 124,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 73.5,
                top: 120,
                height: 12,
                width: 198,
                field: 'deliveryAddress',
                right: 263.25,
                title: '文本',
                bottom: 129.75,
                hCenter: 124.875,
                vCenter: 168,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 141,
                height: 9,
                width: 283.5,
                right: 282,
                bottom: 147,
                hCenter: 142.5,
                vCenter: 140.25,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 70.5,
                top: 147,
                height: 27,
                width: 202.5,
                field: 'remark',
                right: 276,
                title: '长文',
                bottom: 166.5,
                hCenter: 153,
                vCenter: 174.75,
                hideTitle: true,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '长文', type: 'longText' },
            },
            {
              options: {
                left: 0,
                top: 148.5,
                height: 17,
                width: 69,
                right: 69.99609375,
                title: '备注',
                bottom: 158.99609375,
                hCenter: 150.49609375,
                vCenter: 35.49609375,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: -1.5,
                top: 175.5,
                height: 9,
                width: 283.5,
                right: 283.5,
                bottom: 184.5,
                hCenter: 180,
                vCenter: 141.75,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 184.5,
                height: 17,
                width: 69,
                right: 69,
                title: '单据号',
                bottom: 201.5,
                hCenter: 193,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 73.5,
                top: 184.5,
                height: 12,
                width: 198,
                field: 'saleOrderNo',
                right: 271.74609375,
                title: '文本',
                bottom: 195.24609375,
                hCenter: 189.24609375,
                vCenter: 172.74609375,
                testData: 'PX0C0000230420000003',
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 151.5,
                top: 201,
                height: 64.5,
                width: 9,
                right: 160.5,
                bottom: 267,
                hCenter: 233.25,
                vCenter: 156,
              },
              printElementType: { title: '竖线', type: 'vline' },
            },
            {
              options: { left: 0, top: 202.5, height: 9, width: 282 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 211.5,
                height: 17,
                width: 69,
                right: 69,
                title: '箱号',
                bottom: 228.5,
                hCenter: 220,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 156,
                top: 213,
                height: 51,
                width: 120,
                field: 'deliveryRoute',
                right: 276,
                title: '文本',
                bottom: 261,
                hCenter: 235.5,
                vCenter: 216,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 75,
                top: 214.5,
                height: 9.75,
                width: 76.5,
                field: 'boxNo',
                right: 150.24609375,
                title: '文本',
                bottom: 224.49609375,
                hCenter: 219.62109375,
                vCenter: 111.99609375,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 232.5,
                height: 9,
                width: 151.5,
                right: 280.5,
                bottom: 241.5,
                hCenter: 237,
                vCenter: 139.5,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: { left: 150, top: 264, height: 9, width: 130.5 },
              printElementType: { title: '横线', type: 'hline' },
            },
          ],
          paperNumberLeft: 262.5,
          paperNumberTop: 258,
          paperNumberDisabled: true,
        },
      ],
    },
    3: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 100,
          width: 180,
          paperHeader: 0,
          paperFooter: 274.82142857142856,
          printElements: [
            {
              options: {
                left: 282,
                top: 0,
                height: 265.5,
                width: 9,
                right: 294,
                bottom: 90,
                hCenter: 45,
                vCenter: 289.5,
              },
              printElementType: { title: '竖线', type: 'vline' },
            },
            {
              options: {
                left: 85.5,
                top: 3,
                height: 17,
                width: 100.5,
                right: 198.75,
                title: '广州配送仓',
                bottom: 20,
                hCenter: 11.5,
                vCenter: 138.75,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 186,
                top: 15,
                height: 9.75,
                width: 91.5,
                field: 'printTime',
                right: 280.5,
                title: '文本',
                bottom: 24.75,
                hCenter: 19.875,
                vCenter: 233.25,
                testData: '2023-09-09 09:09:09',
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 342,
                top: 24,
                height: 24,
                width: 124.5,
                field: 'customerCode',
                right: 466.5,
                title: '文本',
                bottom: 48,
                hCenter: 36,
                vCenter: 404.25,
                fontSize: 14,
                testData: '1233133',
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: { left: 0, top: 31.5, height: 9, width: 282, right: 90, bottom: 40.5, hCenter: 36, vCenter: 45 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 70.5,
                top: 60,
                height: 172.5,
                width: 9,
                right: 78.75,
                bottom: 87,
                hCenter: 73.5,
                vCenter: 74.25,
              },
              printElementType: { title: '竖线', type: 'vline' },
            },
            {
              options: { left: 0, top: 60, height: 9, width: 282 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 64.5,
                height: 17,
                width: 70.5,
                right: 70.5,
                title: '客户名称',
                bottom: 81.5,
                hCenter: 73,
                vCenter: 35.25,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 75,
                top: 67.5,
                height: 9.75,
                width: 76.5,
                field: 'customerCode',
                right: 150.75,
                bottom: 74.25,
                hCenter: 69.375,
                vCenter: 112.5,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 157.5,
                top: 67.5,
                height: 9.75,
                width: 120,
                field: 'customerName',
                right: 277.5,
                title: '文本',
                bottom: 73.5,
                hCenter: 68.625,
                vCenter: 217.5,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 316.5,
                top: 67.5,
                height: 181.5,
                width: 160.5,
                field: 'customerName',
                right: 477,
                title: '文本',
                bottom: 249,
                hCenter: 158.25,
                vCenter: 396.75,
                fontSize: 16,
                testData: '12312333333333333333333333333333333333333333333333333333333333333333333333',
                hideTitle: true,
                lineHeight: 16.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 87,
                height: 9,
                width: 283.5,
                right: 283.5,
                bottom: 95.25,
                hCenter: 90.75,
                vCenter: 141.75,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 91.5,
                height: 17,
                width: 69,
                right: 69,
                title: '联系电话',
                bottom: 109.25,
                hCenter: 100.75,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 75,
                top: 96,
                height: 9.75,
                width: 120,
                field: 'contactPhone',
                right: 194.25,
                title: '文本',
                bottom: 105,
                hCenter: 100.125,
                vCenter: 134.25,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 111,
                height: 9,
                width: 280.5,
                right: 90,
                bottom: 120,
                hCenter: 115.5,
                vCenter: 45,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 118.5,
                height: 17,
                width: 69,
                right: 69,
                title: '送货地址',
                bottom: 132.5,
                hCenter: 124,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 73.5,
                top: 120,
                height: 12,
                width: 198,
                field: 'deliveryAddress',
                right: 263.25,
                title: '文本',
                bottom: 129.75,
                hCenter: 124.875,
                vCenter: 168,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 141,
                height: 9,
                width: 283.5,
                right: 282,
                bottom: 147,
                hCenter: 142.5,
                vCenter: 140.25,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 70.5,
                top: 147,
                height: 27,
                width: 202.5,
                field: 'remark',
                right: 276,
                title: '长文',
                bottom: 166.5,
                hCenter: 153,
                vCenter: 174.75,
                hideTitle: true,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '长文', type: 'longText' },
            },
            {
              options: {
                left: 0,
                top: 148.5,
                height: 17,
                width: 69,
                right: 69.99609375,
                title: '备注',
                bottom: 158.99609375,
                hCenter: 150.49609375,
                vCenter: 35.49609375,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: -1.5,
                top: 175.5,
                height: 9,
                width: 283.5,
                right: 283.5,
                bottom: 184.5,
                hCenter: 180,
                vCenter: 141.75,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 0,
                top: 184.5,
                height: 17,
                width: 69,
                right: 69,
                title: '单据号',
                bottom: 201.5,
                hCenter: 193,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 73.5,
                top: 184.5,
                height: 12,
                width: 198,
                field: 'saleOrderNo',
                right: 271.74609375,
                title: '文本',
                bottom: 195.24609375,
                hCenter: 189.24609375,
                vCenter: 172.74609375,
                testData: 'PX0C0000230420000003',
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 151.5,
                top: 201,
                height: 64.5,
                width: 9,
                right: 160.5,
                bottom: 267,
                hCenter: 233.25,
                vCenter: 156,
              },
              printElementType: { title: '竖线', type: 'vline' },
            },
            {
              options: { left: 0, top: 202.5, height: 9, width: 282 },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: {
                left: 156,
                top: 208.5,
                height: 51,
                width: 120,
                field: 'deliveryRoute',
                right: 276.75,
                title: '文本',
                bottom: 259.5,
                hCenter: 234,
                vCenter: 216.75,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 211.5,
                height: 17,
                width: 69,
                right: 69,
                title: '箱号',
                bottom: 228.5,
                hCenter: 220,
                vCenter: 34.5,
                fontSize: 12,
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 75,
                top: 214.5,
                height: 9.75,
                width: 76.5,
                field: 'boxNo',
                right: 150.24609375,
                title: '文本',
                bottom: 224.49609375,
                hCenter: 219.62109375,
                vCenter: 111.99609375,
                hideTitle: true,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 0,
                top: 232.5,
                height: 9,
                width: 151.5,
                right: 280.5,
                bottom: 241.5,
                hCenter: 237,
                vCenter: 139.5,
              },
              printElementType: { title: '横线', type: 'hline' },
            },
            {
              options: { left: 150, top: 264, height: 9, width: 130.5 },
              printElementType: { title: '横线', type: 'hline' },
            },
          ],
          paperNumberLeft: 262.5,
          paperNumberTop: 258,
          paperNumberDisabled: true,
        },
      ],
    },
  },
}

export default {
  // 采购入库单 -- 不同配送中心打印模板名
  reviewPrintTempOptions,
  // 采购入库单 -- 不同分公司打印模板名json
  reviewPrintTempJsonOptions,
}
