import request from '@/utils/request'
// const domain = process.env.NODE_ENV === 'development' ? '//10.18.75.23' : '/'
export function receiveOrderPage(data) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrder/page`,
    method: 'post',
    data,
  })
}
export function receiveOrderSave(data) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrder`,
    method: 'put',
    loading: true,
    data,
  })
}
//领用单状态变更
export function receiveOrderStatusChange(data) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrder/statusChange`,
    method: 'post',
    loading: true,
    data,
  })
}

//查询单条领用单
export function getReceiveOrderById(params) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrder/itemList`,
    method: 'get',
    loading: true,
    params,
  })
}

//领用单列表导出
export function materialReceiveOrderExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrder/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//领用单明细列表导出
export function materialReceiveOrderItemExportExcel(data) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrderItem/exportExcel`,
    method: 'post',
    responseType: 'blob',
    data,
  })
}
//领用单删除
export function materialReceiveOrderDelete(data) {
  return request({
    url: `/mg/daohang-order-api/materialReceiveOrder`,
    method: 'delete',
    loading: true,
    data,
  })
}

export function receiveOrderPageSum(data) {
  return request({
    url: '/mg/daohang-order-api/materialReceiveOrder/sum',
    method: 'post',
    data,
  })
}
