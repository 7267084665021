import { getCustomerListAll, getCustomerPage } from '@/api/basic/customerApi'
import { getDictList } from '@/api/goodsInfo/goodsInfoApi'
import { getAreaList } from '@/api/basic/customerApi'

const state = () => ({
  customerName: '',
  customerList: {
    NORMAL: [],
    ALL: [],
  },
  dictList: [],
  areaList: [],
})

const mutations = {
  SET_CUSTOMER_LIST(state, { list, prop }) {
    state.customerList[prop] = list
  },
  // 设置库存单位
  SET_DICT_LIST(state, list) {
    state.dictList = list
  },
  // 省市区
  SET_AREA_LIST(state, list) {
    state.areaList = list
  },
}

const actions = {
  /**
   * 获取客户资料中的所有客户列表
   * customerStatus = NORMAL 查询启用状态的
   * 默认不传參查询启用和禁用的
   * @param {*} param0
   * @returns
   */
  async ACT_FETCH_CUSTOMER_LIST({ commit, state, rootGetters }, payload = '') {
    const prop = payload?.params?.customerStatus ? payload.params.customerStatus : 'ALL'
    if (state.customerList[prop].length > 0) return { data: state.customerList[prop] }
    const { data: list } = await getCustomerListAll(payload?.params)
    // 是否包含当前登录人所属的往来单位
    if (payload.includeSelf) {
      list.unshift({
        customerInfoId: rootGetters['user/belongId'],
        customerCode: rootGetters['user/belongCode'],
        customerName: rootGetters['user/belongName'],
      })
    }
    commit('SET_CUSTOMER_LIST', { list, prop })
    return { data: state.customerList[prop] }
  },
  /**
   * 分页（查询）获取客户资料数据
   */
  async ACT_FETCH_CUSTOMER_PAGE(_, payload) {
    return await getCustomerPage(payload)
  },
  /**
   * 获取库存单位
   * @params: {code: 'B1001'}
   * */
  async ACT_FETCH_DICT_LIST({ commit, state }, payload) {
    if (state.dictList.length > 0) return
    const { data } = await getDictList(payload.params)
    commit('SET_DICT_LIST', data)
  },
  /**
   * 获取中国省市区
   * */
  async ACT_FETCH_AREA_LIST({ commit, state }) {
    if (state.areaList.length > 0) return
    const { data } = await getAreaList()
    commit('SET_AREA_LIST', data)
  },
}

export default { state, mutations, actions }
