import request from '@/utils/request'

export function page(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/page',
    method: 'post',
    data,
  })
}

export function pageExport(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
export function downloadDetailDataFile(id) {
  return request({
    url: `/mg/daohang-order-api/billReconOrder/downloadDetailDataFile/${id}`,
    method: 'get',
    responseType: 'blob',
  })
}
export function itemExport(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrderItem/exportExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
/**
 * 应收对账单生成
 * @param {Object} data
 * @returns
 */
export function pageGenerate(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/generate',
    method: 'post',
    loading: true,
    data,
  })
}
/**
 * 应收批量对账单生成
 * @param {Object} data
 * @returns
 */
export function pageGenerates(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/batch/generate',
    method: 'post',
    loading: true,
    data,
  })
}
/**
 * 应收对账单保存
 * @param {Object} data
 * @returns
 */
export function batchSave(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/batch/save',
    method: 'post',
    loading: true,
    data,
  })
}
/**
 * 应收对账单保存
 * @param {Object} data
 * @returns
 */
export function statusChange(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/batch/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}
/**
 * 应收对账单保存
 * @param {Object} data
 * @returns
 */
export function saveGenerate(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder',
    method: 'put',
    loading: true,
    data,
  })
}
/**
 * 应收对账单查看单条
 * @param {Object} data
 * @returns
 */
export function getItemGenerate(id) {
  return request({
    url: `/mg/daohang-order-api/billReconOrder/${id}`,
    loading: true,
    method: 'get',
  })
}
/**
 * 应收对账单保存
 * @param {Object} data
 * @returns
 */
export function postStatusGenerate(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/statusChange',
    method: 'post',
    loading: true,
    data,
  })
}
/**
 * 应收对账单删除
 * @param {Object} data
 * @returns
 */
export function deleteGenerate(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder',
    method: 'delete',
    loading: true,
    data,
  })
}
/**
 * 应收对账单应收对账组
 * @param {Object} data
 * @returns
 */
export function quickSearch(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/pageByQuery',
    method: 'post',
    data,
  })
}
/**
 * 应收对账单应收对账组
 * @param {Object} data
 * @returns
 */
export function quickListSearch(data) {
  return request({
    url: '/mg/daohang-basic-api/jcCustomerInfo/listByQuery',
    method: 'post',
    data,
  })
}

/**
 * 导出应收对账单 压缩文件
 * @param {*} data
 * @returns
 */
export function exportStatementZipFile(data) {
  return request({
    url: '/mg/daohang-order-api/billReconOrder/batch/downloadDetailDataFile',
    method: 'post',
    data,
    responseType: 'blob',
  })
}
