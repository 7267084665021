import request from '@/utils/request'

// 模板列表查询
export function getPrintTemplateList(data) {
  return request({
    url: '/mg/daohang-basic-api/printTemplate/page',
    method: 'post',
    data,
    allowRepeat: true
  })
}

// 保存
export function savePrintTemplate(data) {
  return request({
    url: '/mg/daohang-basic-api/printTemplate',
    method: 'post',
    loading: true,
    data,
  })
}

// 删除
export function removePrintTemplate(data) {
  return request({
    url: '/mg/daohang-basic-api/printTemplate',
    method: 'delete',
    loading: true,
    data,
  })
}

// 启用禁用
export function updateStatusPrintTemplate(data) {
  return request({
    url: '/mg/daohang-basic-api/printTemplate/updateStatus',
    method: 'post',
    loading: true,
    data,
  })
}

// 详情
export function getPrintTemplateDetail(id) {
  return request({
    url: `/mg/daohang-basic-api/printTemplate/${id}`,
    method: 'get',
  })
}

// 获取单据打印数据
export function handlePrintData(data) {
  return request({
    url: `/mg/daohang-order-api/print/getPrintData`,
    method: 'post',
    data,
  })
}

// 更新打印次数
export function handleUpdataPrintData(data) {
  return request({
    url: `/mg/daohang-order-api/print/println`,
    method: 'post',
    data,
  })
}

// 获取模板字段
export function handleQueryPrintFields(data) {
  return request({
    url: `/mg/daohang-basic-api/printTemplate/printData`,
    method: 'post',
    data,
  })
}

// 复制模板
export function handleCopyTemp(data) {
  return request({
    url: `/mg/daohang-basic-api/printTemplate/copyTemplate`,
    method: 'post',
    data,
  })
}

// 单据类型
export function handlePrintBillTypes() {
  return request({
    url: `/mg/daohang-basic-api/printTemplate/getBillType`,
    method: 'get'
  })
}

// 获取打印箱单列表
export function handlePrintGetBoxNos(data) {
  return request({
    url: `/mg/daohang-order-api/recheckOrder/page`,
    method: 'post',
    data
  })
}

