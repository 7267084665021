/**
 * 退货申请单相关打印变量
 */
const returnApplicationPrintTempOptions = [
  {
    label: '退货申请单',
    value: 'all',
    children: [{ label: '退货申请单', value: 1 }],
  },
]
const returnApplicationPrintTempJsonOptions = {
  // 郑州管城区配送中心
  all: {
    1: {
      panels: [
        {
          index: 0,
          name: 1,
          height: 280,
          width: 210,
          paperHeader: 64.5,
          paperFooter: 769.5,
          printElements: [
            {
              options: {
                left: 241.5,
                top: 6,
                height: 17,
                width: 120,
                right: 361.5,
                title: '退货申请单',
                bottom: 23,
                hCenter: 14.5,
                vCenter: 301.5,
                fontSize: 16.5,
                testData: '',
                hideTitle: true,
                textAlign: 'center',
                fontWeight: '700',
              },
              printElementType: { title: '标题', type: 'text' },
            },
            {
              options: {
                left: 189,
                top: 27,
                height: 15,
                width: 183,
                field: 'supplierName',
                right: 371.25,
                title: '供应商',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 279.75,
                testData: 'JHC11001202303270004',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 27,
                height: 15,
                width: 169.5,
                field: 'billNo',
                right: 183,
                title: '单据号',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 98.25,
                testData: 'JHC11001202303270004',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 376.5,
                top: 27,
                height: 15,
                width: 190.5,
                field: 'createTime',
                right: 569.25,
                title: '开单日期',
                bottom: 42,
                hCenter: 34.5,
                vCenter: 473.25,
                testData: '2023-04-06',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 48,
                height: 15,
                width: 555,
                field: 'remark',
                right: 569.25,
                title: '退货说明',
                bottom: 62.25,
                hCenter: 54.75,
                vCenter: 291.75,
                testData: '01-义务晨兴公司',
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 12,
                top: 69,
                height: 45,
                width: 553.5,
                field: 'printLists',
                right: 565.5,
                bottom: 113.25,
                hCenter: 90.75,
                vCenter: 288.75,
                coordinateSync: false,
                widthHeightSync: false,
                groupFooterFormatter: 'function(t,e){return"这里自定义统计脚信息";}',
                tableHeaderBackground: '#ffffff',
                columns: [
                  [
                    {
                      width: 104.531275,
                      title: '商品编码',
                      field: 'skuCode',
                      checked: true,
                      columnId: 'skuCode',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      align: 'left',
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 91.906225,
                      title: '货号',
                      field: 'articleNo',
                      checked: true,
                      columnId: 'articleNo',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 194.17225,
                      title: '商品名称',
                      field: 'skuName',
                      checked: true,
                      columnId: 'skuName',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 60.144249999999985,
                      title: '单位',
                      field: 'stockUnit',
                      checked: true,
                      columnId: 'stockUnit',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 73.24975000000002,
                      title: '单价',
                      field: 'costPrice',
                      checked: true,
                      columnId: 'costPrice',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 86.28099999999993,
                      title: '申请数量',
                      field: 'returnQty',
                      checked: true,
                      columnId: 'returnQty',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 73.71925000000005,
                      title: '申请金额',
                      field: 'costAmt',
                      checked: true,
                      columnId: 'costAmt',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                    {
                      width: 115.99599999999998,
                      title: '备注',
                      field: 'remark',
                      checked: true,
                      columnId: 'remark',
                      fixed: false,
                      rowspan: 1,
                      colspan: 1,
                      tableColumnHeight: 30,
                      tableTextType: 'text',
                      tableBarcodeMode: 'CODE128A',
                      tableQRCodeLevel: 0,
                    },
                  ],
                ],
              },
              printElementType: {
                title: '表格',
                type: 'table',
                editable: true,
                columnDisplayEditable: true,
                columnDisplayIndexEditable: true,
                columnTitleEditable: true,
                columnResizable: true,
                columnAlignEditable: true,
                isEnableEditField: true,
                isEnableContextMenu: true,
                isEnableInsertRow: true,
                isEnableDeleteRow: true,
                isEnableInsertColumn: true,
                isEnableDeleteColumn: true,
                isEnableMergeCell: true,
              },
            },
            {
              options: {
                left: 145.5,
                top: 117,
                height: 15,
                width: 127.5,
                right: 273,
                title: '出库:',
                bottom: 169.4999885559082,
                hCenter: 161.9999885559082,
                vCenter: 215.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 294,
                top: 117,
                height: 15,
                width: 120,
                right: 409.5,
                title: '复核：',
                bottom: 169.4999885559082,
                hCenter: 161.9999885559082,
                vCenter: 350.25,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 432,
                top: 117,
                height: 15,
                width: 138,
                field: 'totalReturnQty',
                right: 544.7499847412109,
                title: '本单数量',
                bottom: 169.7499961853027,
                hCenter: 162.2499961853027,
                vCenter: 486.99998474121094,
                formatter: "function(value, options){return (options == 0 ? '0' : options)}",
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 117,
                height: 15,
                width: 114,
                field: 'createUserName',
                right: 130.5,
                title: '开单人',
                bottom: 132,
                hCenter: 124.5,
                vCenter: 73.5,
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
            {
              options: {
                left: 13.5,
                top: 138,
                height: 15,
                width: 138,
                field: 'totalCostAmt',
                right: 150.99609375,
                title: '本单金额',
                bottom: 152.49609375,
                hCenter: 144.99609375,
                vCenter: 81.99609375,
                formatter: "function(value, options){return (options == 0 ? '0' : options)}",
                qrCodeLevel: 0,
                coordinateSync: false,
                widthHeightSync: false,
                textContentVerticalAlign: 'middle',
              },
              printElementType: { title: '文本', type: 'text' },
            },
          ],
          paperNumberLeft: 553.5,
          paperNumberTop: 18,
        },
      ],
    },
  },
}

export default {
  // 退货申请单 -- 不同配送中心打印模板名
  returnApplicationPrintTempOptions,
  // 退货申请单 -- 不同分公司打印模板名json
  returnApplicationPrintTempJsonOptions,
}
